import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from '../../app-config.service';
import {Set} from '../models/set';
import {Observable} from 'rxjs';
import {SetUI} from '../models/set.ui';
import {PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup, UrlTree} from '@angular/router';
import {AuthService} from './auth.service';
import {ProductCodeEnum} from '../utils/data/product-code-enum';
import {SET_UI_CONFIGURATION} from '../configuration/set';
import {productsConfiguration} from '../configuration/product';
import {ProductUI} from '../models/product.ui';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {ApiResponseObject} from '../../shared/model/api-response-object';

@Injectable({
  providedIn: 'root'
})
export class SetService {

  constructor(private environment: AppConfigService,
              private router: Router,
              private httpClient: HttpClient,
              private authService: AuthService) {
  }

  currentSetUI: SetUI;

  get baseEndPoint() {
    return this.environment.config.apiBaseEndpoint + '/' +
           this.authService.getActiveCarrierCode() + '/sets/' as String;
  }

  getSets(setUI: string): Observable<ApiResponseObject> {
    return this.httpClient.get<ApiResponseObject>(
      this.baseEndPoint + setUI);
  }

  selectSet(set: SetUI, baseUrl?: string) {
    if (!isNotNullOrUndefined(baseUrl)) {
      this.router.navigate([this.router.url + '/' + set.path]);
    } else {
      this.router.navigate([baseUrl + '/' + set.path]);
    }
  }

  getCurrentSetUIFromProductAndURL(productCode: ProductCodeEnum, path: string): SetUI {
    let setUi = productCode.toLowerCase() + '-';
    switch (path) {
      case 'name':
      case 'deposit':
      case 'agreement':
        setUi += path + '-compliance';
        break;
      case 'final-payment':
        setUi += 'finalPayment-compliance';
        break;
      case 'allowlist-name':
        setUi += 'white-name';
        break;
      case 'blocklist-name':
        setUi += 'black-name';
        break;
      case 'fictitious-name':
        setUi += 'fic-name';
        break;
      default:
        setUi += path;
    }

    return this.getCurrentSetUIFromURL(setUi);
  }

  getCurrentSetUIFromURL(path: string): SetUI {
    return SET_UI_CONFIGURATION.filter(s => s.setUI === path).shift();
  }

  /**
   * Re-constructs the active [[SetUI]] from current URL segments
   * @param url    current URL path
   * @returns identified [[SetUI]] or `undefined`
   */
  extractSetUIFromURL(url: string): SetUI {
    const tree: UrlTree = this.router.parseUrl(url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g.segments;
    let product: ProductUI;

    // Case when we are navigating through products
    for (let i = 0; i < s.length; i++) {
      if (s[i].path === 'products') {
        if (isNotNullOrUndefined(s[i + 1])) {
          product = productsConfiguration.filter(p => p.path === s[i + 1].path).shift();
          if (isNotNullOrUndefined(s[i + 2])) {
            return this.getCurrentSetUIFromProductAndURL(product.code, s[i + 2].path);
          }
        }
      }
    }

    // Case when we are navigating through General Settings
    for (let i = 0; i < s.length; i++) {
      if (s[i].path === 'general-settings') {
        if (isNotNullOrUndefined(s[i + 1])) {
          return this.getCurrentSetUIFromURL(s[i + 1].path);
        }
      }
    }

    return undefined;
  }
}
