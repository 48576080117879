import { CacheContent } from '../models/cachecontent';
import { Injectable } from '@angular/core';
import { Subject, Observable, of, throwError} from 'rxjs';
import { tap } from 'rxjs/operators';
import {LogUtils} from '../utils/logging/log-utils';


@Injectable({
  providedIn: 'root'
})
export class CacheService {

  // Max Age in milliseconds
  readonly DEFAULT_MAX_AGE: number = 36000000; // 1 hour
  private inProgressObservables: Map<string, Subject<any>> = new Map<string, Subject<any>>();

  get(key: string, fallback?: any, maxAge?: number): Observable<any> {

    if (this.hasValidCachedValue(key)) {
      // LogUtils.info(`%cGetting from cache ${key}`, 'color: green');
      return of(this.getItem(key).value);
    }

    if (!maxAge) {
      maxAge = this.DEFAULT_MAX_AGE;
    }

    if (this.inProgressObservables.has(key)) {
      return this.inProgressObservables.get(key);
    } else if (fallback && fallback instanceof Observable) {
      this.inProgressObservables.set(key, new Subject());
      // LogUtils.info(`%c Calling api for ${key}`, 'color: purple');
      return fallback.pipe(tap((value) => { this.set(key, value, maxAge); }));
    } else {
      return throwError(`Requested ${key} is not available in Cache`);
    }
  }

  set(key: string, value: any, maxAge: number = this.DEFAULT_MAX_AGE): void {
    const cacheContent: CacheContent = { value: value, expiry: Date.now() + maxAge };
    sessionStorage.setItem(key, JSON.stringify(cacheContent));
    this.notifyInProgressObservers(key, value);
  }

  private notifyInProgressObservers(key: string, value: any): void {
    if (this.inProgressObservables.has(key)) {
      const inProgress = this.inProgressObservables.get(key);
      const observersCount = inProgress.observers.length;
      if (observersCount) {
        // LogUtils.info(`%cNotifying ${inProgress.observers.length} progress subscribers for ${key}`, 'color: blue');
        inProgress.next(value);
      }
      inProgress.complete();
      this.inProgressObservables.delete(key);
    }
  }

  private getItem(key: string): CacheContent {
    return JSON.parse(sessionStorage.getItem(key));
  }

  has(key: string): boolean {
    return !(sessionStorage.getItem(key) === null);
  }

  private hasValidCachedValue(key: string): boolean {
    if (sessionStorage.length > 0 && this.has(key)) {
      if ( this.getItem(key).expiry < Date.now()) {
        sessionStorage.removeItem(key);
        return false;
      }
      return true;
    } else {
      return false;
    }
  }



}
