import {Field} from '../models/field';
import {Component, OnInit, Output, EventEmitter, AfterViewChecked, ChangeDetectorRef} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-textarea',
  template: `
    <ng-container *ngIf="this.state === 'info'; else editTemplate">
        <span class="info white-space-pre">{{ this.textareaArray.value.toString().trim() }}</span>
    </ng-container>
    <ng-template #editTemplate>
        <div class="inline" [formGroup]="group">
    <textarea rows="2" class="input-text-custom textarea2"
              #textareaArray
              name="{{ field.name }}"
              (focus)="onFocus(textareaArray)"
              (focusout)="parseInput(textareaArray.value)"
              (input)="parseInput(textareaArray.value)"
              [class.isInvalid]="this.isInvalid === true"
              [class.required]="this.isRequired === true"
    >{{ this.text }}</textarea>
            <span *ngIf="this.isRequired" class="text-danger asterix">*</span>
        </div>
    </ng-template>
  `,
  styles: [
    'textarea { text-transform: uppercase; }'
  ]
})
export class TextareaComponent implements OnInit, AfterViewChecked {

  field: Field;
  group: FormGroup;
  state: string;
  value: any[] = [];
  text: string;
  isRequired = false;
  messageRequired = '';
  isInvalid = false;
  isPassengerName = false;
  otherPassengerNameOption = '';
  @Output() invalid = new EventEmitter<any>();

  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
    this.text = this.group.get(this.field.name).value.toString().split(/ *,+ */g).join('\n').trim();
    this.parseInput(this.text);
    const index = this.field.validations.findIndex(fv => fv.name === 'required');
    if (index > -1) {
      this.isRequired = true;
      this.messageRequired = this.field.validations[index].message;
    }
    if (this.field.label === 'Passenger Full Name' && ['inclusions', 'exclusions'].indexOf(this.field.name) > -1) {
      this.isPassengerName = true;
      this.otherPassengerNameOption = this.field.name === 'inclusions' ? 'exclusions' : 'inclusions';
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
    if (this.isPassengerName && (this.group.get(this.otherPassengerNameOption).value.length > 0 || this.value.length > 0 )) {
      this.parseValidation();
    }
  }

  parseInput(input: any, validate: boolean = true) {
    this.value = input.toString().split(/[\r\n]+/g);
    const varr = [];
    for (let x = 0; x < this.value.length; x++) {
      const value = this.value[x].trim().toUpperCase();
      if (value && value !== '' && varr.indexOf(value) < 0) {
        varr.push(value);
      }
    }
    this.value = varr;
    this.textareaArray.setValue(this.value);
    this.text = input.trim();
    if (validate) {
      this.parseValidation();
    }
  }

  parseValidation() {
    this.isInvalid = false;
    const trim = this.text.trim();
    const name = this.isPassengerName ? 'paxFullName' : this.field.name;
    const pattern = this.field.validations.find(val => val.name === 'pattern');
    const values = this.value;

    const error = { field: name , message : '' };

    if (values.length > 0 && trim !== '') {
      if (pattern) {
        values.forEach(v => {
          if (!v.trim().match(pattern.validator)) {
            this.isInvalid = true;
          }
        });
        if (this.isInvalid) {
          error.message = pattern.message;
          error.message += this.field.multiple === true && !/separated by( a)? commas?/.test(error.message) ? ', separated by commas' : '';
        } else if (this.isPassengerName) {
          const otherPax: string[] = this.group.get(this.otherPassengerNameOption).value.toString().split(/ *,+ */g);
          if (values.some((r: string) => otherPax.indexOf(r.trim()) > -1)) {
            this.isInvalid = true;
            error.message = 'The same lastName/firstName cannot be included and excluded at the same time';
          }
        }
      }
    } else if (this.isPassengerName && this.group.get(this.otherPassengerNameOption).value.length <= 0) {
      this.isInvalid = true;
      this.isRequired = true;
      error.field = error.field + 'Required';
      error.message = 'Please enter a lastName/firstName in one of these fields';
    } else if (this.isPassengerName && this.group.get(this.otherPassengerNameOption).value.length > 0) {
      error.field = error.field + 'Required';
      this.isRequired = false;
    } else {
      this.isInvalid = this.isRequired === true;
      if (this.isInvalid) {
        error.message = this.messageRequired;
      }
    }
    this.invalid.emit(error);
  }

  get textareaArray() {
    return this.group.get(this.field.name) as FormArray;
  }

  get textareaControl() {
    return this.group.get(this.field.name) as FormControl;
  }

  onFocus(input: HTMLTextAreaElement) {
    input.classList.remove('isInvalid');
    this.isInvalid = false;
    this.invalid.emit({ field: this.field.name , message : '' });
  }
}
