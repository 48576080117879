import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import {FeatureFlagService} from '../../core/services/feature-flag.service';

@Directive({
  selector: '[appRemoveIfProductFeatureOff]'
})
export class ProductFeatureOffDirective implements OnInit {
  @Input('appRemoveIfProductFeatureOff') featureNameWithProduct: string;

  constructor(private el: ElementRef,
              private featureFlagService: FeatureFlagService) {
  }

  ngOnInit() {
      const featureNameProduct = this.featureNameWithProduct.split(',');
      if (featureNameProduct.length < 2) {
        if (this.featureFlagService.featureOff(featureNameProduct[0])) {
            this.el.nativeElement.parentNode.removeChild(this.el.nativeElement);
          }
      } else {
        if (this.featureFlagService.productFeatureOff(featureNameProduct[1], featureNameProduct[0])) {
            this.el.nativeElement.parentNode.removeChild(this.el.nativeElement);
          }
      }

  }
}
