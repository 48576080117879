export enum ProductCodeEnum {
  ATL = 'ATL',
  DPP = 'DPP',
  DPS = 'DPS',
  FSN = 'FSN',
  GTL = 'GTL',
  HDG = 'HDG',
  NSM = 'NSM',
  TTT = 'TTT',
  WLT = 'WLT',
  CHR = 'CHR'
}
