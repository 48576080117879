import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Set } from '../../../core/models/set';
import { SetUI } from '../../../core/models/set.ui';

@Component({
  selector: 'app-card-parameter',
  template: `
    <div class="card-box padding_30 custom-shadow color-blue cursor-pointer" (click)="onClick()">
      <h3 class="m-b-10"><i class="{{ this.set.icon }}"></i></h3>
      <p class="text-uppercase m-b-5 font-13 font-600 ">{{ this.set.title }}</p>
    </div>
  `,
  styles: []
})
export class CardParameterComponent implements OnInit {

  @Input() set: SetUI;

  @Output() selectSet = new EventEmitter<SetUI>();

  constructor() { }

  ngOnInit() {
  }

  onClick(): void {
    this.selectSet.emit(this.set);
  }

}
