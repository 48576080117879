import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppConfigService} from '../../../../app-config.service';
import {AuthService} from '../../../../core/services/auth.service';
import {ApiResponseObject} from '../../../../shared/model/api-response-object';

@Injectable({
  providedIn: 'root'
})
export class BusinessDataService {
  constructor(private httpClient: HttpClient,
              private environment: AppConfigService,
              private authService: AuthService) {
  }

  get baseEndPoint() {
    return this.environment.config.apiBaseEndpoint + '/' + this.authService.getActiveCarrierCode() as String;
  }

  public getAllBusinessData(page = 0, size = 10, filter = '', sort = []): Observable<ApiResponseObject> {
    const httpParams = new HttpParams()
          .set('page', page.toString())
          .set('size', size.toString())
          .set('sort', sort.join(';'))
          .set('filter', filter);
    return this.httpClient.get<ApiResponseObject>(
      this.baseEndPoint + '/businessdata', { params: httpParams});
  }

  public getBusinessDataList(businessType: string = ''): Observable<ApiResponseObject> {
    let httpParams = new HttpParams();
    if (businessType !== '') {
      httpParams = httpParams.set('businessType', businessType);
    }
    return this.httpClient.get<ApiResponseObject>(
      this.baseEndPoint + '/businessdata/type', {params: httpParams});
  }
  

  public getBusinessData(businessDataId: number): Observable<ApiResponseObject> {
    return this.httpClient.get<ApiResponseObject>(
      this.baseEndPoint + '/businessdata/' + businessDataId);
  }

  public getBusinessDataByName(businessDataName: string): Observable<ApiResponseObject> {
    return this.httpClient.get<ApiResponseObject>(
      this.baseEndPoint + '/businessdata/' + businessDataName + '/name');
  }

  public createOrUpdateBusinessData(businessData: string, businessDataId: number): Observable<ApiResponseObject> {
    if (businessDataId === 0) {
      return this.postBusinessData(businessData);
    } else {
      return this.putBusinessData(businessData, businessDataId);
    }
  }

  public postBusinessData(businessData: string): Observable<ApiResponseObject> {
    return this.httpClient.post<ApiResponseObject>(
      this.baseEndPoint + '/businessdata', businessData);
  }

  public putBusinessData(businessData: string, businessDataId: number): Observable<ApiResponseObject> {
    return this.httpClient.put<ApiResponseObject>(
      this.baseEndPoint + '/businessdata/' + businessDataId, businessData);
  }

  public deleteBusinessData(businessDataId: number): Observable<ApiResponseObject> {
    return this.httpClient.delete<ApiResponseObject>(
      this.baseEndPoint + '/businessdata/' + businessDataId);
  }

  public getCountBusinessDataList(): Observable<ApiResponseObject> {
    return this.httpClient.get<ApiResponseObject>(
      this.baseEndPoint + '/businessdata/' + 'count');
  }

  getBusinessDataListOfConfiguration(setUI: string, parameter: string): Observable<ApiResponseObject> {
    return this.httpClient.get<ApiResponseObject>(
      this.baseEndPoint + '/businessdata/' + setUI + '/configuration/' + parameter);
  }

  public getAllBusinessDataHistory(businessDataId: number): Observable<ApiResponseObject> {
    return this.httpClient.get<ApiResponseObject>(
      this.baseEndPoint + '/businessdata/' + businessDataId + '/history');
  }

  public getBusinessDataHistory(businessDataId: number, version: number): Observable<ApiResponseObject> {
    return this.httpClient.get<ApiResponseObject>(
      this.baseEndPoint + '/businessdata/' + businessDataId + '/history/' + version);
  }

  public getCountVersion(businessDataId: number): Observable<ApiResponseObject> {
    return this.httpClient.get<ApiResponseObject>(
      this.baseEndPoint + '/businessdata/' + businessDataId + '/history/count');
  }

  public deployBusinessDataVersion(businessDataId: number, version: number): Observable<ApiResponseObject> {
    return this.httpClient.post<ApiResponseObject>(
      this.baseEndPoint + '/businessdata/' + businessDataId + '/history/' + version, {});
  }
}
