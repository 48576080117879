import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {ModalItems} from '../../../core/models/modalitems';

@Component({
  selector: 'app-confirm-modal',
  template: `
      <div *ngIf="this.items === undefined; else elseDetailedDesc">
      <div class="modal-header">
          <button type="button" class="close" (click)="dismissAction()" aria-hidden="true">×</button>
          <h4 class="modal-title text-primary">
              <img src="./assets/favicon/favicon-32x32.png" alt="" height="26">{{ componentType }}
          </h4>
      </div>
      <div *ngIf="!specializedText; else elseTemplateBody" class="modal-body">
          <p><strong>{{ displayMessage }}</strong></p>
          <p><strong>Would you like to continue ?</strong></p>
      </div>
      <ng-template #elseTemplateBody>
          <div class="modal-body">
              <p><strong>{{ displayMessage }}</strong></p>
          </div>
      </ng-template>
      <div *ngIf="!isErrorText; else elseTemplateFooter" class="modal-footer" id="modal-footer1">
          <button type="button" class="btn btn-light waves-effect" (click)="declineAction()">Cancel</button>
          <button (click)="confirmAction()" type="button" class="btn btn-primary waves-effect waves-light">Confirm</button>
      </div>
      <ng-template #elseTemplateFooter>
          <div class="modal-footer" id="modal-footer1">
              <button (click)="confirmAction()" type="button" class="btn btn-primary waves-effect waves-light">OK</button>
          </div>
      </ng-template>
      </div>
      <ng-template #elseDetailedDesc>
          <div class="modal-header">
              <button type="button" class="close" (click)="this.dismissAction()" aria-hidden="true">×</button>
              <h4 class="modal-title text-primary">
                  <img src="./assets/favicon/favicon-32x32.png" alt="" height="26">
                  {{ this.componentType }}
              </h4>
          </div>
          <div class="modal-body">
              <table class="table">
                  <thead class="sticky-table-header">
                  <th *ngFor="let header of headers" scope="col">{{header}}</th>
                  </thead>
                  <tbody>
                  <tr *ngFor="let item of items">
                      <td>{{item.value1}}</td>
                      <td>{{item.value2}}</td>
                      <td>{{item.value3}}</td>
                  </tr>
                  </tbody>
              </table>
          </div>
          <div class="modal-footer" id="modal-footer1">
              <button (click)="this.dismissAction()" type="button" class="btn btn-primary waves-effect waves-light">OK</button>
          </div>
      </ng-template>
  `,
  styles: []
})
export class ConfirmModalComponent implements OnInit {

  @Input() displayMessage: string;
  @Input() componentType: string;
  @Input() specializedText?: boolean;
  @Input() isErrorText?: boolean;
  @Input() headers?: string[];
  @Input() items?: ModalItems[];
  @Output() confirm = new EventEmitter<boolean>();

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  confirmAction() {
    this.activeModal.close(true);
  }

  declineAction() {
    this.activeModal.close(false);
  }

  dismissAction() {
    this.activeModal.dismiss();
  }

}
