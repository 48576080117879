import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import { CacheService } from './services/cache.service';

@NgModule({
  declarations: [],
  imports: [ CommonModule ],
  providers : [ CacheService ]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {}

 }
