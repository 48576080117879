import { Field } from './../models/field';
import { FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flow',
  template: `
    <span [ngClass]="{'align-top': notMultiPnrMode}" [innerHTML]="this.getFlow(field.label)"></span>
  `,
  styles: [
    '.bold { font-weight: bold; }'
  ]
})
export class FlowComponent implements OnInit {

  field: Field;
  group: FormGroup;
  state: string;
  notMultiPnrMode = true;

  constructor() { }

  ngOnInit() {
    // flow shouldn't be aligned to the top of the div for multiPnrMode field
    if (this.field.label.indexOf('and should apply to') > -1) {
      this.notMultiPnrMode = false;
    }
  }

  getFlow(value: string) {
    return value.replace(/[*][*]([^*]+)[*][*]/ig, '<b>$1</b>');
  }
}
