import {ApiLogLevelEnum} from './api-log-level.enum';

export class ApiMessage {
  logLevel: ApiLogLevelEnum;
  message: string;

  constructor(logLevel: ApiLogLevelEnum, message?: string) {
    this.logLevel = logLevel;
    this.message = message;
  }
}
