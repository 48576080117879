import {FormArray, ValidationErrors, ValidatorFn} from '@angular/forms';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';

export const ptdRangeValidator: ValidatorFn = (control: FormArray): ValidationErrors | null => {
  if (control.controls.length > 0) {
    let controlError = null;
    control.controls.reduce((prev, cur, index) => {
      if (index > 0) {
        const prevFrom = prev.get('from').value;
        const prevTo = prev.get('to').value;
        if (cur.get('from').value <= prevTo || cur.get('to').value < cur.get('from').value) {
          controlError = {
            index: index,
            previous: {
              from: prevFrom,
              to: prevTo
            },
            actual: {
              from: cur.get('from').value,
              to: cur.get('to').value
            }
          };
        }
        return cur;
      }
    });

    return isNotNullOrUndefined(controlError) ? {ptdRange: controlError} : null;
  } else {
    return {ptdRange: true};
  }
};
