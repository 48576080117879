import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppConfigService } from '../../app-config.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MOCK_PERMISSIONS } from '../configuration/permissions';
import { AuthUtils } from '../utils/auth.utils';
import { AuthenticUser } from '../models/authentic-user';
import { LogUtils } from '../utils/logging/log-utils';
import { CrudEnum } from '../utils/data/crud.enum';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { ProductCodeEnum } from '../utils/data/product-code-enum';
import { Permissions } from '../models/permissions';
import { TokenValidationService } from 'angular-auth-oidc-client';
import { DataStorageService } from './data-storage.service';
import { RegexUtils } from '../utils/regex-utils';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authenticUser: AuthenticUser;
  private redirectUrl = '/';
  private nonce = '';

  public isUserLoggedInEventEmitter = new EventEmitter<boolean>();
  public authenticUserLoggedInEventEmitter = new EventEmitter<AuthenticUser>();

  constructor(private dataStorage: DataStorageService,
    private httpClient: HttpClient,
    private router: Router,
    private appConfig: AppConfigService,
    private jwtHelper: JwtHelperService,
    private oidcSecurityValidation: TokenValidationService) {
  }

  // region Authentication methods
  private extractAuthInformation() {
    const paramsString = window.location.hash.replace('#', '');

    const paramsObject = {};
    const paramsArray = paramsString.length > 0 ? paramsString.split('&') : [];
    for (const paramString of paramsArray) {
      const paramTuple = paramString.split('=');
      paramsObject[paramTuple[0]] = typeof (paramTuple[1]) === 'undefined' ? true : paramTuple[1];
    }

    return paramsObject;
  }

  private validateAccessToken(accessToken: string, token: string) {
    LogUtils.info(['JWT Token', token]);
    LogUtils.info(['Access Token', accessToken]);
    const jwtObject = this.jwtHelper.decodeToken(token);
    LogUtils.info(['JWT Object', jwtObject]);
    if (jwtObject.hasOwnProperty('at_hash') && jwtObject.hasOwnProperty('nonce')) {
      return this.oidcSecurityValidation
        .validateIdTokenAtHash(accessToken, jwtObject.at_hash, 'HS256') === true && this.validateNonce(jwtObject.nonce) === true;
    }
  }

  private validateNonce(nonce: string): boolean {
    return this.dataStorage.getNonce() === nonce;
  }

  public extractPermissions(permissions = {}): string[] {
    const extractedPermissions = [];
    for (const role of Object.keys(permissions)) {
      extractedPermissions.push((AuthUtils.ROLE_PREFIX + role).toUpperCase());
      for (const permission of permissions[role]) {
        extractedPermissions.push((role + AuthUtils.SEPARATOR + permission).toUpperCase());
      }
    }

    return extractedPermissions;
  }

  private getRedirectUrl(): string {
    return this.redirectUrl;
  }

  public setRedirectUrl(url: string) {
    this.redirectUrl = url;
  }

  private getAuthenticationRequest(): string {
    const redirectParams = this.getRedirectParams();
    return this.appConfig.config.authenticationPoint + '?' + redirectParams;
  }

  private getNonce(): string {
    return this.nonce;
  }

  private setNonce() {
    this.nonce = this.generateNonce();
    // persisting the nonce in the session storage to validate it in the id_token response from the server
    this.dataStorage.setNonce(this.nonce);
  }

  // new method of CLP Integration with all the mandatory parameters
  /**
   * Constructs the redirect parameters for the authentication request.
   * 
   * This method first sets a nonce by calling the `setNonce` method. Then, it constructs an array of redirect parameters 
   * including the service name, scope, client ID, redirect URI, nonce and the response type. These parameters are then joined into a 
   * single string with each parameter separated by an '&' character.
   * 
   * @returns {string} The constructed redirect parameters.
   * 
   * @example
   * 
   * const redirectParams = this.getRedirectParams();
   * console.log(redirectParams);  // Outputs a string like "service=ARI&scope=openid&client_id=123&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Fauth&nonce=3Gh9sG4kI7jQ6P8k&response_type=id_token"
   */
  private getRedirectParams() : string {
      this.setNonce();

      const redirectParams = [
        'service=ARI',
        'scope=openid',
        'client_id=' + this.appConfig.config.client_id,
        'redirect_uri=' + encodeURIComponent(this.appConfig.config.authRedirectUrl + this.getRedirectUrl()),
        'nonce=' + this.getNonce(),
        'response_type=id_token'
      ];
      return redirectParams.join('&');
  }

  /**
   * Generates a nonce which is a random string of 16 characters.
   * 
   * A nonce is a random string, uniquely generated for each request. 
   * This method generates a nonce using characters from the English alphabet (both lower and upper case) and digits from 0 to 9.
   * 
   * @returns {string} The generated nonce.
   * 
   * @example
   * 
   * const nonce = this.generateNonce();
   * console.log(nonce);  // Outputs a string like "3Gh9sG4kI7jQ6P8k"
   */
  private generateNonce(): string {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let nonce = '';
      for (let i = 0; i < 16; i++) {
        nonce += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return nonce;
  }

  private saveAuthenticUser(token: string, authenticUser: AuthenticUser): void {
    this.dataStorage.setToken(token);
    this.isUserLoggedInEventEmitter.emit(true);
    this.authenticUserLoggedInEventEmitter.emit(authenticUser);
    this.authenticUser = authenticUser;
  }

  public authenticateUser() {
    if (AuthUtils.isTestOrDev()) {
      return true;
    } else {
      const authenticationRequest = this.getAuthenticationRequest();
      LogUtils.info(['Authentication Request', authenticationRequest]);
      this.deleteAllStoredInformation();

      if (!window.location.search.match(/[?]DEBUG=1/)) {
        window.location.href = authenticationRequest;
      }
      return false;
    }
  }

  public isUserLoggedIn() {
    const token = this.dataStorage.getToken();

    if (token !== null && token.length > 0) {
      LogUtils.info(['Token Expiration Date', this.jwtHelper.getTokenExpirationDate(token)]);
      if (this.jwtHelper.isTokenExpired(token)) {
        return false;
      }

      const authenticUser = this.extractAuthenticUser(token);
      LogUtils.info(['AuthenticUser', authenticUser]);
      if (!this.isAuthenticUserValid(authenticUser)) {
        return false;
      }

      // TODO: refresh token
      this.saveAuthenticUser(token, authenticUser);
      return true;
    }

    return false;
  }

  public isUserAuthenticated() {
    const params = this.extractAuthInformation();
    
    if (Object.keys(params).length > 0 && params.hasOwnProperty('id_token')) {
      LogUtils.info(['Authentication Params', params]);
      const authenticUser = this.extractAuthenticUser(params['id_token']);
      if (!this.isAuthenticUserValid(authenticUser)) {
        console.error('Token content is invalid, logging out');
        this.logoutUser();
        return false;
      }
      if (Object.keys(params).length > 0 && params.hasOwnProperty('access_token')) {
        if (!this.validateAccessToken(params['access_token'], params['id_token'])) {
          this.logoutUser();
          return false;
        }
      }
      this.saveAuthenticUser(params['id_token'], authenticUser);
      window.location.href = window.location.href.split('#')[0];
      return true;
    }

    return true;
  }

  public logoutUser(forceDelete?: boolean) {
    this.deleteAllStoredInformation(forceDelete);
    this.isUserLoggedInEventEmitter.emit(false);
    this.authenticUserLoggedInEventEmitter.emit(null);

    window.location.href = window.location.protocol + '//' + window.location.host;
  }

  private deleteAllStoredInformation(forceDelete?: boolean): void {
    if (this.jwtHelper.isTokenExpired(this.dataStorage.getToken())
      || !this.isAuthenticUserValid(this.authenticUser)
      || forceDelete === true) {

      this.dataStorage.removeToken();
      if (isNotNullOrUndefined(this.authenticUser) && !this.isAmadeusOrganization()) {
        this.dataStorage.removeActiveCarrier();
        this.dataStorage.removeMockPermissions();
      }
    }
    if(forceDelete === true) {
      this.dataStorage.removeNonce();
    }
  }
  // endregion

  // region AuthenticUser methods
  public isAuthenticUserValid(authenticUser: AuthenticUser): boolean {
    return isNotNullOrUndefined(authenticUser)
      && authenticUser.getUsername().length > 0
      && authenticUser.getEmail().match(RegexUtils.VALIDATOR_EMAIL)
      && authenticUser.getOrganization().length > 0
      && ((AuthUtils.isTestOrDev() && authenticUser.getOrganization() === AuthUtils.AMADEUS_CARRIER_CODE && authenticUser.getPermissions().length >= 0)
        || authenticUser.getPermissions().length > 0);
  }

  public isDevelopmentUser(): boolean {
    return AuthUtils.isTestOrDev()
      && this.isAuthenticUserValid(this.authenticUser)
      && this.authenticUser.getOrganization() === AuthUtils.AMADEUS_CARRIER_CODE
      && this.authenticUser.getPermissions().length <= 0;
  }

  public getAuthenticatedUser(): AuthenticUser {
    return this.authenticUser;
  }

  private extractAuthenticUser(token: string): AuthenticUser {
    LogUtils.info(['JWT Token', token]);
    const jwtObject = this.jwtHelper.decodeToken(token);
    LogUtils.info(['JWT Object', jwtObject]);

    let mockedPermissions = {};
    if (jwtObject.organization === AuthUtils.AMADEUS_CARRIER_CODE) {
      mockedPermissions = this.extractMockedPermissions();
    }

    /* Host/CoHost carrier management
    If a carrier is considered co-hosted (per app property map),
    we override its organization with its host carrier
     */
    /*modify co-host for 6J carrier to 6J in UAT - temp fix to unblock BPT*/
    if(this.appConfig.config.environment.toLowerCase() === 'uat' && 
      this.appConfig.config.hostCoHost.hasOwnProperty('6J'))
    {
      this.appConfig.config.hostCoHost['6J'] = '6J';
    }
    const organization = this.appConfig.config.hostCoHost.hasOwnProperty(jwtObject.organization)
      ? this.appConfig.config.hostCoHost[jwtObject.organization]
      : jwtObject.organization;
    


    return new AuthenticUser(
      jwtObject.login,
      jwtObject.email,
      organization,
      this.extractPermissions(Object.keys(mockedPermissions).length > 0 ? mockedPermissions : jwtObject.permissions_v2),
      jwtObject.iat * 1000,
      jwtObject.exp * 1000
    );
  }
  // endregion

  // region ActiveCarrierCode methods
  public hasActiveCarrierCode(): boolean {
    if (this.isAmadeusOrganization()) {
      return isNotNullOrUndefined(this.dataStorage.getActiveCarrier());
    }

    return false;
  }

  public getActiveCarrierCode() {
    if (this.hasActiveCarrierCode()) {
      return this.dataStorage.getActiveCarrier().toUpperCase();
    }

    return this.authenticUser.getOrganization().toUpperCase();
  }

  public setActiveCarrierCode(carrierCode: string) {
    if (this.isAmadeusOrganization()) {
      this.dataStorage.setActiveCarrier(carrierCode);
    }
  }
  // endregion

  // region MockPermission methods
  public extractMockedPermissions(): {} {
    let permissions = {};
    if (this.hasMockUserPermissions()) {
      const mockedPermissions = this.getMockUserPermissions().split(',');
      if (mockedPermissions.length > 0) {
        permissions = {};
        MOCK_PERMISSIONS.forEach(rp => {
          rp.permissions
            .filter(p => mockedPermissions.indexOf(rp.role + '_' + this.buildMockPermissionText(rp.type, p)) > -1)
            .reduce((obj, cur) => {
              const pName = this.buildMockPermissionText(rp.type, cur);
              if (!obj.hasOwnProperty(rp.role)) {
                obj[rp.role] = [pName];
              } else if (obj[rp.role].indexOf(pName) < 0) {
                obj[rp.role].push(pName);
              }
              return obj;
            }, permissions);
        });
      }
    }

    return permissions;
  }

  public buildMockPermissionText(type: string, permission: string) {
    return type.length > 0 ? type + AuthUtils.SEPARATOR + permission : permission;
  }

  public hasMockUserPermissions(): boolean {
    if (this.isAmadeusOrganization()) {
      return isNotNullOrUndefined(this.dataStorage.getMockPermissions());
    }

    return false;
  }

  public getMockUserPermissions(): string {
    if (this.hasMockUserPermissions()) {
      return this.dataStorage.getMockPermissions().toUpperCase();
    }

    return '';
  }

  public setMockUserPermissions(permissions: string) {
    if (this.isAmadeusOrganization()) {
      this.dataStorage.setMockPermissions(permissions);
    }
  }

  public deleteMockUserPermissions(): boolean {
    this.dataStorage.removeMockPermissions();
    return !this.hasMockUserPermissions();
  }
  // endregion

  // region Authorization methods
  public isMemberOf(organization: string): boolean {
    if (this.hasMockUserPermissions() && this.hasActiveCarrierCode()) {
      return this.getActiveCarrierCode() === organization;
    } else {
      return this.isDevelopmentUser() ? true : this.authenticUser.getOrganization() === organization;
    }
  }

  public isMemberOfAny(organizations: string[]): boolean {
    return organizations.some(org => this.isMemberOf(org));
  }

  public isAmadeus(): boolean {
    return this.isMemberOf(AuthUtils.AMADEUS_CARRIER_CODE);
  }

  public isAmadeusOrganization(): boolean {
    return isNotNullOrUndefined(this.authenticUser) && this.authenticUser.getOrganization() === AuthUtils.AMADEUS_CARRIER_CODE;
  }

  public canLoad(roles: string[], commonRoleTypes?: string[]): boolean {
    return this.canActivate(roles, commonRoleTypes, AuthUtils.ALL);
  }

  public canActivate(roles: string[], commonRoleTypes?: string[], crudSet?: CrudEnum[]): boolean {
    if (roles.indexOf(AuthUtils.REPORT) > -1 || roles.indexOf(AuthUtils.SEARCH) > -1) {
      return roles.some(r => this.isActive(r));
    }
    if (roles.indexOf(AuthUtils.AUTOMATION_SERVICES) > -1) {
      return roles.some(r => this.isActive(r));
    }

    if (Object.values(crudSet).indexOf(CrudEnum.R) > -1) {
      return roles.some(r => {
        return this.isActive(r) && (this.isReadOnly(r) || commonRoleTypes.some(p => {
          if (this.filterPrefixByProduct(r, p)) {
            const permissions = new Permissions(AuthUtils.COMMON, p, crudSet);
            return this.hasAnyAuthority(AuthUtils.computeCrudPermissions(permissions));
          }
        }));
      });
    } else {
      return roles.some(r => {
        return this.isActive(r) && !this.isReadOnly(r) && commonRoleTypes.some(p => {
          if (this.filterPrefixByProduct(r, p)) {
            const permissions = new Permissions(AuthUtils.COMMON, p, crudSet);
            return this.hasAnyAuthority(AuthUtils.computeCrudPermissions(permissions));
          }
        });
      });
    }
  }

  public canCreate(setUI: string): boolean {
    const role = this.extractRole(setUI);
    return this.isActive(role) && !this.isReadOnly(role) && this.checkPermissions(setUI, AuthUtils.C);
  }

  public canRead(setUI: string): boolean {
    const role = this.extractRole(setUI);
    return this.isActive(role) && (this.isReadOnly(role) || this.checkPermissions(setUI, AuthUtils.ALL));
  }

  public canUpdate(setUI: string): boolean {
    const role = this.extractRole(setUI);
    return this.isActive(role) && !this.isReadOnly(role) && this.checkPermissions(setUI, AuthUtils.U);
  }

  public canDelete(setUI: string): boolean {
    const role = this.extractRole(setUI);
    return this.isActive(role) && !this.isReadOnly(role) && this.checkPermissions(setUI, AuthUtils.D);
  }

  public isProductManagerOf(productCode: ProductCodeEnum): boolean {
    const role = productCode;
    return this.isActive(role) && !this.isReadOnly(role) && this.hasAnyProductAuthority(AuthUtils.MANAGE);
  }

  public isProductViewerOf(productCode: ProductCodeEnum): boolean {
    const role = productCode;
    return this.isActive(role) && (this.isReadOnly(role) || this.hasAnyProductAuthority(AuthUtils.ALL));
  }

  private isActive(role: string): boolean {
    if (this.isDevelopmentUser()) {
      return true;
    }

    return AuthUtils.COMMON === role
      || this.hasAuthority(role + AuthUtils.SEPARATOR + AuthUtils.ACTIVE + AuthUtils.SEPARATOR + role);
  }

  private isReadOnly(role: string): boolean {
    if (this.isDevelopmentUser()) {
      return false;
    }

    return AuthUtils.COMMON !== role
      && this.hasAuthority(role + AuthUtils.SEPARATOR + AuthUtils.READ_ONLY + AuthUtils.SEPARATOR + role);
  }

  private extractRole(setUI: string): string {
    if (setUI.indexOf('-') > -1) {
      const typeSplit = setUI.split('-');
      if (typeSplit[0].length === 3) {
        return ProductCodeEnum[typeSplit.shift().toUpperCase()];
      }
    }

    if ('search' === setUI) {
      return AuthUtils.SEARCH;
    }

    if ('analytics' === setUI) {
      return AuthUtils.REPORT;
    }

    return AuthUtils.COMMON;
  }

  private checkPermissions(setUI: string, crudSet: CrudEnum[]): boolean {
    let dataSet = setUI;
    if (setUI.indexOf('-') > -1) {
      const typeSplit = setUI.split('-');
      if (typeSplit[0].length === 3) {
        dataSet = typeSplit.slice(1).join('-').toLowerCase();
      }
    }

    let permissions: Permissions;
    const role = AuthUtils.COMMON;
    switch (dataSet) {
      case 'analytics':
        return this.hasRole(AuthUtils.REPORT);
      case 'search':
        return this.hasRole(AuthUtils.SEARCH);
      case 'business-data':
        permissions = new Permissions(role, AuthUtils.BUSINESS_DATA, crudSet);
        break;
      case 'ticket-trust':
      case 'computation':
      case 'exemption':
      case 'extension':
      case 'white-name':
      case 'black-name':
      case 'fic-name':
      case 'pnr-exemption':
      case 'segment-exemption':
      case 'error-handling':
      case 'pnr-exclusion':
      case 'segment-exclusion':
      case 'secondary-action':
      case 'action':
      case 'eticket-action':
      case 'eticket-exclusion':
      case 'eticket-refund':
      case 'eticket-refund-exclusion':
      case 'agreement-compliance':
      case 'deposit-compliance':
      case 'finalpayment-compliance':
      case 'name-compliance':
      case 'passenger-exemption':
      case 'detection':
        permissions = new Permissions(role, AuthUtils.RULE_EDITOR, crudSet);
        break;
      case 'compliance':
      case 'agreement-compliance-configuration':
      case 'deposit-compliance-configuration':
      case 'finalpayment-compliance-configuration':
      case 'name-compliance-configuration':
      case 'ticketing-compliance-configuration':
        permissions = new Permissions(role, AuthUtils.COMPLIANCE, crudSet);
        break;
      case 'recalculation':
        permissions = new Permissions(role, AuthUtils.RECALCULATION, crudSet);
        break;
      case 'configuration':
      case 'eticket-configuration':
      case 'product-configuration':
        permissions = new Permissions(role, AuthUtils.PRODUCT_CONFIG, crudSet);
        break;
      case 'payment-configuration':
      case 'flight-movement-configuration':
        permissions = new Permissions(role, AuthUtils.AUTOMATION_SERVICES, crudSet);
        break;
      case 'general-settings':
      case 'invalid-ticket':
        permissions = new Permissions(role, AuthUtils.SHARED_CONFIG, crudSet);
        break;
      case 'internal-control':
        permissions = new Permissions(role, AuthUtils.PRODUCT_CONFIG, crudSet);
        return this.hasAnyAuthority(AuthUtils.computeCrudPermissions(permissions)) && this.isAmadeus();
      default:
        return this.isDevelopmentUser();
    }

    return this.hasAnyAuthority(AuthUtils.computeCrudPermissions(permissions));
  }

  private hasAnyAuthority(authorities: string[]): boolean {
    return this.hasAnyAuthorityName(null, authorities);
  }

  private hasAuthority(authority: string): boolean {
    return this.hasAnyAuthority([authority]);
  }

  private hasAnyRole(roles: string[]): boolean {
    return this.hasAnyAuthorityName(AuthUtils.ROLE_PREFIX, roles);
  }

  private hasRole(role: string): boolean {
    return this.hasAnyRole([role]);
  }

  private hasAnyAuthorityName(prefix: string, roles: string[]): boolean {
    if (this.isDevelopmentUser()) {
      return true;
    }

    for (const role of roles) {
      const defaultedRole = this.getRoleWithDefaultPrefix(prefix, role);
      if (this.authenticUser.getPermissions().indexOf(defaultedRole) > -1) {
        return true;
      }
    }

    return false;
  }

  private hasAnyProductAuthority(crudSet: CrudEnum[]): boolean {
    for (const prefix of AuthUtils.ALL_PERMISSIONS_TYPES) {
      const permissions = new Permissions(AuthUtils.COMMON, prefix, crudSet);
      if (this.hasAnyAuthority(AuthUtils.computeCrudPermissions(permissions))) {
        return true;
      }
    }

    return false;
  }

  private getRoleWithDefaultPrefix(defaultRolePrefix: string, role: string): string {
    if (!isNotNullOrUndefined(role)) {
      return null;
    }
    if (!isNotNullOrUndefined(defaultRolePrefix) || defaultRolePrefix.length <= 0) {
      return AuthUtils.LSS_APP_PREFIX + role;
    }
    if (role.startsWith(defaultRolePrefix)) {
      return role;
    }
    return defaultRolePrefix + AuthUtils.LSS_APP_PREFIX + role;
  }
  // endregion

  private filterPrefixByProduct(role: string, prefix: string) {
    switch (prefix) {
      case AuthUtils.RECALCULATION:
        return [ProductCodeEnum.ATL, ProductCodeEnum.GTL].indexOf(ProductCodeEnum[role]) > -1;
      case AuthUtils.COMPLIANCE:
        return [ProductCodeEnum.ATL, ProductCodeEnum.GTL].indexOf(ProductCodeEnum[role]) > -1;
      case AuthUtils.SHARED_CONFIG:
      case AuthUtils.BUSINESS_DATA:
        return AuthUtils.COMMON === role;
      case AuthUtils.RULE_EDITOR:
        return AuthUtils.ALL_PRODUCT_ROLES.indexOf(ProductCodeEnum[role]) > -1 || AuthUtils.COMMON === role;
      default:
        return true;
    }
  }
}
