import { Router } from '@angular/router';
import {Component, Input, OnInit} from '@angular/core';
import { ProductUI } from '../../../core/models/product.ui';
import { SetUI } from '../../../core/models/set.ui';
import { wording } from '../../../core/configuration/wording';
import { productsConfiguration } from '../../../core/configuration/product';
import { SET_UI_CONFIGURATION } from '../../../core/configuration/set';
import { ProductCodeEnum } from '../../../core/utils/data/product-code-enum';

@Component({
  selector: 'app-header-product',
  template: `
      <div *ngIf="this.productCode" class="row profile-user-box bg-grey">
          <div class="col-8 no-padding">
              <div class="title-product">
                  <span class="pull-left mr-3"><img src="{{product.url_image}}" alt="{{product.name}}"></span>
              </div>
              <div class="padding-title-product media-body text-white">
                  <div class="product-title color-blue"><span [hidden]="this.stickyOn">{{product.name}}</span> &nbsp;</div>
                  <h4 class="page-title color-blue-light">
                      <span [hidden]="!this.stickyOn" class="product-title-small color-blue">{{product.code}} -</span>
                      <ng-container *ngIf="set; else sTemplate">
                          {{set.title}} {{ action }}
                      </ng-container>
                      <ng-template #sTemplate>
                          {{product.short_description}}
                      </ng-template>
                  </h4>
              </div>
          </div>
          <div class="col-4 padding-title-product">
              <div id="nav-submenu" class=" pull-right">
                  <ol class="breadcrumb hide-phone p-0 m-0">
                      <li class="breadcrumb-item"><a (click)="clearSticky()" routerLink="/products">My Products</a></li>
                      <ng-container *ngIf="set; else elseTemplate">
                          <li class="breadcrumb-item"><a (click)="this.clearSession()" routerLink="{{ '/products/' + product.path }}">{{product.name}}</a></li>
                          <ng-container *ngIf="action; else aTemplate">
                              <li class="breadcrumb-item active">{{set.title + ' ' + action}}</li>
                          </ng-container>
                          <ng-template #aTemplate>
                              <li class="breadcrumb-item active">{{set.title}}</li>
                          </ng-template>
                      </ng-container>
                      <ng-template #elseTemplate>
                          <li class="breadcrumb-item active">{{product.name}}</li>
                      </ng-template>
                  </ol>
              </div>
              <a class="sticky-mr35" (click)="this.clickSticky()">
                  <i [hidden]="this.stickyOn" class="mdi mdi-arrow-collapse-up" title="Hide the header"></i>
                  <i [hidden]="!this.stickyOn" class="mdi mdi-arrow-collapse-down" title="Show the header"></i>
              </a>
              <ng-container *ngIf="product.helpLink">
                  <a class="sticky-mr60" href="{{ product.helpLink }}" target="_blank" disabled>
                      <i class="mdi mdi-help-rhombus-outline" title="Help"></i>
                  </a>
              </ng-container>
          </div>
      </div>
  `,
  styles: []
})
export class HeaderProductComponent implements OnInit {

  @Input() productCode: ProductCodeEnum;
  @Input() setUI: string;
  product: ProductUI;
  set: SetUI;
  action: string;
  stickyKey = 'sticky-product';
  stickyOn: boolean;

  constructor(private router: Router) { }

  ngOnInit() {
    this.compInit();
    this.router.events.subscribe(() => this.compInit());
  }

  compInit() {
    this.product = productsConfiguration.filter(p => p.code === this.productCode).shift();
    this.set = SET_UI_CONFIGURATION.filter(s => s.setUI === this.setUI).shift();
    this.getAction();
    this.manageSticky();
  }

  getAction() {
    this.action = null;
    const routes = this.router.url.split('/');
    if (routes.length > 4) {
      const text = wording[routes[4]];
      this.action =  text ? text : null;
    }
  }

  manageSticky() {
    if (!this.stickyOn && sessionStorage.getItem(this.stickyKey) !== null) {
      this.stickyOn = JSON.parse(sessionStorage.getItem(this.stickyKey));
    } else {
      this.stickyOn = false;
    }

    if (!this.stickyOn) {
      this.stickyOn = false;
      sessionStorage.setItem(this.stickyKey, 'false');
      $('#topnav').removeClass('stickyOn');
    } else {
      this.stickyOn = true;
      sessionStorage.setItem(this.stickyKey, 'true');
      $('#topnav').addClass('stickyOn');
    }
  }

  clickSticky() {
    if (!this.stickyOn) {
      this.stickyOn = true;
      sessionStorage.setItem(this.stickyKey, 'true');
      $('#topnav').addClass('stickyOn');
    } else {
      this.stickyOn = false;
      sessionStorage.setItem(this.stickyKey, 'false');
      $('#topnav').removeClass('stickyOn');
    }
  }

  clearSticky() {
    $('#topnav').removeClass('stickyOn');
    sessionStorage.removeItem(this.stickyKey);
    this.clearSession();
  }

  clearSession() {
    sessionStorage.removeItem('previous-route');
    sessionStorage.removeItem('datatable-rule-page');
    sessionStorage.removeItem('datatable-rule-filter');
  }

}
