import { Field } from '../models/field';
import {FormGroup, FormArray, FormBuilder, AbstractControl} from '@angular/forms';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-date-range',
  template: `
    <div [formGroup]="group" class="inline">
      <ng-container *ngIf="this.state=='info'; else editTemplate">
        <div [ngClass]="{'inline': i === 0}" *ngFor="let item of daterange.controls; let i = index;">
          <div class="inline padding_right_5" [formGroup]="item | asFormGroup">
            <ng-container *ngIf="this.getDate(item, 'from') === ''; else fromTemplate">
              <span class="info">any date</span>
            </ng-container>
            <ng-template #fromTemplate>
              <span class="info">{{ this.getDate(item, 'from') | date: 'yyyy-MM-dd'}}</span>
            </ng-template>
            <span> to </span>
            <ng-container *ngIf="this.getDate(item, 'to') === ''; else toTemplate">
              <span class="info">any date</span>
            </ng-container>
            <ng-template #toTemplate>
              <span class="info">{{ this.getDate(item, 'to') | date: 'yyyy-MM-dd'}}</span>
            </ng-template>
            <span [hidden]="i === daterange.controls.length - 1" class="info-green"> or </span>
          </div>
        </div>
      </ng-container>
      <ng-template #editTemplate>
        <div [ngClass]="{'inline': i === 0}" *ngFor="let item of daterange.controls; let i = index;">
          <div class="inline" [formGroup]="item | asFormGroup">
            <div class="d-inline-block" (click)="enableAndFocusDate(i, 'from')">
              <input class="input-text-custom custom-date"
                     type="text"
                     formControlName="from"
                     placeholder="YYYY-MM-DD"
                     [id]="from + i"
                     (input)="parseValidation()"
                     (focusout)="checkDateFormat(i, 'from')"
                     (focus)="onFocusDate(i, 'from')"
                     autocomplete="off"/>
            </div>
            <span class="text-primary px-1 cursor-pointer"
                  *ngIf="getDate(item, 'from') !== ''"
                  (click)="clearDate(i, 'from')"><i class="fa fa-times"></i>
            </span>

            <span class="info"> to </span>
            <div class="d-inline-block" (click)="enableAndFocusDate(i, 'to')">
              <input class="input-text-custom custom-date"
                     type="text"
                     formControlName="to"
                     placeholder="YYYY-MM-DD"
                     [id]="to + i"
                     (input)="parseValidation()"
                     (focusout)="checkDateFormat(i, 'to')"
                     (focus)="onFocusDate(i, 'to')"
                     autocomplete="off"/>
            </div>
            <span class="text-primary px-1 cursor-pointer"
                  *ngIf="getDate(item, 'to') !== ''"
                  (click)="clearDate(i, 'to')"><i class="fa fa-times"></i>
            </span>
          </div>

          <span [hidden]="i === daterange.controls.length - 1" class="info-green"> or </span>
          <a [hidden]="i === 0" class="menu-criteria padding_right_5" href="javascript:void(0)" (click)="deleteDaterange(i)">
            <i class="fa fa-trash" title="Delete this condition"></i>
          </a>
          <a [hidden]="i !== daterange.controls.length - 1" class="menu-criteria padding_right_5" href="javascript:void(0)" (click)="addDateRange()">
            <i class="fa fa-plus-square" title="Add a date range"></i>
          </a>
        </div>
      </ng-template>
    </div>
  `,
  styles: ['.input-text-custom.custom-date {width: 130px; pointer-events: none;}']
})
export class DateRangeComponent implements OnInit {

  field: Field;
  group: FormGroup;
  state: string;
  value: any[] = [];
  @Output() invalid = new EventEmitter<any>();
  uniqId = '';
  from = 'from';
  to = 'to';

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.uniqId = uuid();
    this.from = this.uniqId + this.from;
    this.to = this.uniqId + this.to;
    this.initDaterange();
  }

  initDaterange() {
    const value = this.group.get(this.field.name).value;
    this.group.setControl(this.field.name, this.fb.array([]));
    if (value && value.length > 0) {
      this.value = value;
      this.value.forEach(dr => {
        const from = dr['from'] ? dr['from'] : '';
        const to = dr['to'] ? dr['to'] : '';
        this.addDateRange(from, to);
      });
    } else {
      this.addDateRange();
    }
  }

  addDateRange(from: string = '', to: string = '') {
    this.daterange.push(this.fb.group({['from']: this.fb.control(from), ['to']: this.fb.control(to)}));
    const newIndex = this.daterange.length - 1;

    this.checkDateFormat(newIndex, 'from');
    this.checkDateFormat(newIndex, 'to');

    setTimeout(() => {
      this.setDatePicker(newIndex, 'from');
      this.setDatePicker(newIndex, 'to');
      this.parseValidation();
    }, 100);

  }

  setDatePicker(index: number, control: string) {
    const dateRange = this.daterange.controls[index];
    const initDate = this.getInitDate(dateRange.get(control).value);
    const dateRangeElement = (<any>$('#' + this.uniqId + control + index));

    dateRangeElement.daterangepicker({
      startDate: initDate,
      endDate: initDate,
      singleDatePicker: true,
      autoUpdateInput: false,
      autoApply: false,
      showDropdowns: true
    });

    const that = this;
    dateRangeElement.on('apply.daterangepicker', function(ev, picker) {
      const dateValue = picker.startDate.format('YYYY-MM-DD');
      if (moment(dateValue, ['YYYY-MM-DD'], true).isValid()) {
        dateRange.get(control).enable();
      }

      dateRange.get(control).setValue(dateValue);
      that.parseValidation();
    });
  }

  getInitDate(value: string) {
    if (!value || value === '') {
      const date = new Date();
      return ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2) + '/' + date.getFullYear();
    } else {
      const tb = value.split('-');
      return tb[1] + '/' + tb[2] + '/' + tb[0];
    }
  }

  deleteDaterange(index: number) {
    this.daterange.removeAt(index);
  }

  clearDate(index: number, control: string) {
    this.daterange.controls[index].get(control).setValue('');
    this.onFocusDate(index, control);
    this.daterange.controls[index].get(control).disable();
    this.parseValidation();
  }

  parseValidation() {
    let required = false;
    let invalid = false;
    let invalid_period = false;
    const controls = this.daterange.controls;
    const error = { field: this.field.name , message : '' };

    for (let x = 0; x < controls.length; x++) {
      const from = controls[x].get('from').value.trim();
      const to = controls[x].get('to').value.trim();

      if (from === '' && to === '') {
        required = true;
        $('#' + this.from + x).addClass('isInvalid');
        $('#' + this.to + x).addClass('isInvalid');
      }

      if (from !== '' && !moment(from, ['YYYY-MM-DD'], true).isValid()) {
        invalid = true;
        $('#' + this.from + x).addClass('isInvalid');
      }

      if (to !== '' && !moment(to, ['YYYY-MM-DD'], true).isValid()) {
        invalid = true;
        $('#' + this.from + x).addClass('isInvalid');
      } else if (!invalid && moment(to, ['YYYY-MM-DD'], true).valueOf() < moment(from, ['YYYY-MM-DD'], true).valueOf()) {
        invalid_period = true;
        $('#' + this.from + x).addClass('isInvalid');
        $('#' + this.to + x).addClass('isInvalid');
      }
    }

    if (required === true) {
      error.message = 'Please enter either a "from" or a "to" date';
    }
    if (invalid === true) {
      error.message = 'Please enter a valid date';
    }
    if (invalid_period === true) {
      error.message = 'Please enter a valid period, "from" date cannot be after "to" date';
    }
    this.invalid.emit(error);
  }

  onFocusDate(index: number, control: string) {
    const reverse = control === 'from' ? 'to' : 'from';
    $('#' + this.uniqId + control + index).removeClass('isInvalid');
    $('#' + this.uniqId + reverse + index).removeClass('isInvalid');
    this.invalid.emit({ field: this.field.name, message : '' });
  }

  get daterange() {
    return this.group.get(this.field.name) as FormArray;
  }

  getDate(group: AbstractControl, field: string): string {
    return group.get(field).value;
  }

  enableAndFocusDate(index: number, control: string) {
    this.daterange.controls[index].get(control).enable();
    $('#' + this.uniqId + control + index).trigger('focus');
  }

  checkDateFormat(index: number, control: string) {
    if (!moment(this.daterange.controls[index].get(control).value, ['YYYY-MM-DD'], true).isValid()) {
      this.daterange.controls[index].get(control).disable();
    }
  }
}
