import {FormArray, ValidationErrors} from '@angular/forms';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';

export function PaxNameMatchingValidator(formArray: FormArray): ValidationErrors | null {
  if (formArray.controls.length > 0) {
    const controlDuplicates = {
      inclusive: {},
      exclusive: {}
    };
    let controlError = null;

    const controlValues = formArray.controls
      .map(methodControl => methodControl.value);

    for (let i = 0; i < controlValues.length; i++) {
      const inclusiveText = controlValues[i].inclusive === true ? 'inclusive' : 'exclusive';
      const jsonValue = JSON.stringify(controlValues[i]).replace(/ +/g, '');
      if (controlDuplicates[inclusiveText].hasOwnProperty(jsonValue)) {
        controlError = {
          index: [controlDuplicates[inclusiveText][jsonValue], i],
          inclusive: controlValues[i].inclusive
        };
        break;
      } else {
        controlDuplicates[inclusiveText][jsonValue] = i;
      }
    }

    return isNotNullOrUndefined(controlError) ? {paxNameMatching: controlError} : null;
  } else {
    return {paxNameMatching: true};
  }
}
