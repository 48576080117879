import {FormGroup} from '@angular/forms';
import {FormUtils} from '../../../core/utils/form/form-utils';
import {GTLComplianceForm} from '../../../modules/product/group-time-limit/components/form/gtl-compliance.form';
import {ConfigurationService} from '../../../core/services/configuration.service';
import {LogUtils} from '../../../core/utils/logging/log-utils';
import {map, take, takeUntil} from 'rxjs/operators';
import {ProductService} from '../../../core/services/product.service';
import {AuthService} from '../../../core/services/auth.service';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {ProductUI} from '../../../core/models/product.ui';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SetUI} from '../../../core/models/set.ui';
import {SetService} from '../../../core/services/set.service';
import {SET_UI_CONFIGURATION} from '../../../core/configuration/set';
import {ProductCodeEnum} from '../../../core/utils/data/product-code-enum';
import {DocumentTypeEnum} from '../../../core/utils/data/document-type-enum';
import {CarrierUtils} from '../../../core/utils/carrier.utils';
import {NSMEticketConfigurationForm} from '../../../modules/product/no-show-manager/components/form/nsm-eticket-configuration.form';
import {FeatureFlagService} from '../../../core/services/feature-flag.service';

@Component({
  selector: 'app-index-product',
  template: `
    <app-header-product [productCode]="this.productCode"></app-header-product>
    <ng-template [ngIf]="canAccessRules()">
      <div class="row">
        <div class="col-sm-12">
          <div class="page-title-box product">
            <h4 class="page-title color-blue">Rules Management</h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="row text-center">
            <ng-container *ngFor="let set of ruleSets">
              <div *ngIf="this.canAccessRuleSet(this.set) && !this.set.path.includes('exemption') && !this.set.path.includes('exclusion')" class="col-sm-6 col-lg-6 col-xl-4">
                <app-card-rule [product]="this.productUi"
                               [set]="set"
                               (selectSet)="this.selectSet($event)"></app-card-rule>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="page-title-box product">
            <h4 class="page-title color-blue">Exemption Rules Management</h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="row text-center">
            <ng-container *ngFor="let set of ruleSets">
              <div *ngIf="this.canAccessRuleSet(this.set) && this.set.path.includes('exemption') || this.set.path.includes('exclusion')" class="col-sm-6 col-lg-6 col-xl-4">
                <app-card-rule [product]="this.productUi"
                               [set]="set"
                               (selectSet)="this.selectSet($event)"></app-card-rule>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template [ngIf]="canAccessConfigurations()">
    <div class="row">
      <div class="col-sm-12">
        <div class="page-title-box">
          <h4 class="page-title color-blue">Configurations</h4>
        </div>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-lg-12">
        <!-- Simple card -->
        <div class="row text-center">
          <ng-template ngFor let-set [ngForOf]="configs" let-i="index">
            <div class="col-sm-6 col-lg-6 col-xl-4" *ngIf="authService.canRead(set.setUI)">
              <app-card-parameter [set]="set"
                                  (selectSet)="this.selectSet($event)"></app-card-parameter>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    </ng-template>
  `,
  styles: []
})
export class IndexProductComponent implements OnInit, OnDestroy {
  private static readonly GTL_COMPLIANCE_SETUI = 'gtl-compliance';
  private static readonly NSM_ETICKET_SETUI = 'nsm-eticket-configuration';

  @Input() productCode: ProductCodeEnum;
  public productUi: ProductUI;
  public ruleSets: SetUI[];
  public configs: SetUI[];
  private _destroyed$ = new Subject();

  constructor(private router: Router,
              public authService: AuthService,
              private setService: SetService,
              private productService: ProductService,
              private featureFlagService: FeatureFlagService,
              private configurationService: ConfigurationService) { }

  ngOnInit() {
    this.getProduct();
    this.getSets();
  }

  private getProduct() {
    if (!this.productService.hasProducts()) {
      this.productService.findProducts()
        .pipe(map(value => value), take(1), takeUntil(this._destroyed$))
        .subscribe(response => {
          const productsUi = this.productService.mergeProducts(response.returnObject);
          this.productUi = productsUi.filter(p => p.code === this.productCode && p.active).shift();
          if (!this.productUi) {
            this.router.navigate(['/products']);
            LogUtils.throw('Product ' + this.productCode + ' inactive');
          }
        });
    } else {
      this.productUi = this.productService.getProducts().filter(p => p.code === this.productCode && p.active).shift();
      if (!this.productUi) {
        this.router.navigate(['/products']);
        LogUtils.throw('Product ' + this.productCode + ' inactive');
      }
    }
  }

  private getSets() {
    this.ruleSets = SET_UI_CONFIGURATION.filter(s => (s.type === DocumentTypeEnum.RULE) && (s.productCode === this.productCode) && s.load);
    this.configs = SET_UI_CONFIGURATION.filter(s => (s.type === DocumentTypeEnum.CONFIGURATION) && (s.productCode === this.productCode) && s.load);
    if (this.productCode === ProductCodeEnum.GTL) {
      this.getComplianceSets();
    } else if (this.productCode === ProductCodeEnum.NSM) {
      this.getETicketSets();
    }
  }

  canAccessConfigurations(): boolean {
    return this.configs.some(c => this.authService.canRead(c.setUI));
  }

  canAccessRules(): boolean {
    return this.ruleSets.some(r => this.authService.canRead(r.setUI));
  }

  canAccessRuleSet(dataSet: SetUI): boolean {
    return CarrierUtils.canAccessRuleSet(this.authService.getActiveCarrierCode(), dataSet.setUI);
  }

  selectSet(set: SetUI) {
    return this.setService.selectSet(set);
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  getComplianceSets() {
    const gtlForm = new GTLComplianceForm();
    gtlForm.setCurrentCarrier(this.authService.getActiveCarrierCode());
    const gtlFormGroup = gtlForm.buildForm();
    this.configurationService.getConfigurations(IndexProductComponent.GTL_COMPLIANCE_SETUI).subscribe(response => {
      const formData = JSON.parse(response.returnObject.value);
      FormUtils.setFormData(gtlFormGroup, formData);
      const compliance = gtlFormGroup.get('compliance') as FormGroup;
      Object.keys(compliance.controls).forEach(k => {
        if (compliance.get(k).get('enableCompliance').value === true) {
          const set = SET_UI_CONFIGURATION.filter(S => S.name === k + 'RuleSet')[0];
          if (set) {
            this.ruleSets.push(set);
          }
        }
      });
    });
  }

  getETicketSets() {
    const productConfigurationForm = new NSMEticketConfigurationForm(this.featureFlagService);
    productConfigurationForm.setCurrentCarrier(this.authService.getActiveCarrierCode());
    productConfigurationForm.setProductCode(this.productCode);
    const nsmFormGroup = productConfigurationForm.buildForm();
    this.configurationService.getConfigurations(IndexProductComponent.NSM_ETICKET_SETUI).subscribe(response => {
      const formData = JSON.parse(response.returnObject.value);
      FormUtils.setFormData(nsmFormGroup, formData);
      const eTicket = nsmFormGroup.get('eticketConfigs') as FormGroup;
      Object.keys(eTicket.controls).forEach(k => {
        if (k === 'eticketRevokeActive' && eTicket.get(k).value === true) {
          SET_UI_CONFIGURATION.filter(s => s.type === DocumentTypeEnum.RULE && s.setUI.startsWith('nsm-eticket')).forEach(s => this.ruleSets.push(s));
          if(this.authService.getActiveCarrierCode() !== 'WN') {
            const x = SET_UI_CONFIGURATION.filter(s => (s.setUI === 'nsm-eticket-refund-exclusion'));
            if(this.productCode === ProductCodeEnum.NSM) {
              this.ruleSets.splice(this.ruleSets.indexOf(x[0]),1);
            }
          }
        }
      });
    });
  }
}