import { Field } from '../models/field';
import {Injectable} from '@angular/core';
import { of, Observable } from 'rxjs';
import fields from '../configuration/field.json';
import { FieldConfig } from '../models/field-config';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';

@Injectable({
  providedIn: 'root'
})
export class FieldService {

  constructor() {}

  countPosOptions(name: string) {
    const field = (<Field[]> <any>fields).filter(f => f.name === name).shift();
    return field.options.length;
  }

  getField(name: string): Observable<Field> {
    const field = (<Field[]> <any>fields).filter(f => f.name === name).shift();
    return of(Object.assign({}, field));
  }

  getFieldByConfig(fieldConfig: FieldConfig): Observable<Field> {
    const field = Object.assign({}, (<Field[]> <any>fields).filter(f => f.name === fieldConfig.field)[0]);
    if (Object.keys(field).length > 0 && isNotNullOrUndefined(fieldConfig.rename)) {
      field.name = fieldConfig.rename;
    }
    if (Object.keys(field).length > 0 && isNotNullOrUndefined(fieldConfig.multiple)) {
      field.multiple = fieldConfig.multiple;
    }
    return of(field);
  }


}
