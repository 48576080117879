import {AfterViewInit, ChangeDetectorRef, Component, forwardRef, Input} from '@angular/core';
import {AriInputDirective} from '../ari-input.directive';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {DataUtils} from '../../../../../core/utils/data/data-utils';


@Component({
  selector: 'app-reorder-input-component',
  templateUrl: './reorder-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReorderInputComponent),
      multi: true
    }
  ]
})
export class ReorderInputComponent extends AriInputDirective implements AfterViewInit {

  @Input()
  public state: string;

  @Input()
  public options: [];

  @Input()
  public description: string;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  public writeValue(value: any): void {
    if (value == null) {
      this.value = [];
    } else {
      this.value = value;
    }
  }

  public getValueLabel(value: string): string {
    let valueLabel = '';
    for (const singleOption of this.options) {
      if (singleOption['value'] === value) {
        valueLabel = singleOption['text'];
        break;
      }
    }
    return valueLabel;
  }

  public getValueLength(): number {
    return (<[]>this.value).length;
  }

  public moveValueUp(value: any): void {
    const valueCurrentIndex = this.getValueIndex(value);
    const valueMoveUpIndex = valueCurrentIndex - 1;
    DataUtils.moveElementInArray(this.getValue(), valueCurrentIndex, valueMoveUpIndex, value);
    this.updateParentComponentValue(this.getValue());
  }

  public moveValueDown(value: any): void {
    const valueCurrentIndex = this.getValueIndex(value);
    const valueMoveDownIndex = valueCurrentIndex + 1;
    DataUtils.moveElementInArray(this.getValue(), valueCurrentIndex, valueMoveDownIndex, value);
    this.updateParentComponentValue(this.getValue());
  }

  private getValueIndex(value: any): number {
    const valueIndex = (<any[]>this.getValue()).indexOf(value);
    return valueIndex;
  }

}
