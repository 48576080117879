export class AuthenticUser {
  private readonly username: string;
  private readonly email: string;
  private readonly organization: string;
  private readonly permissions: string[];
  private readonly authenticStartTime: number;
  private readonly authenticTimeLimit: number;

  constructor(username: string,
              email: string,
              organization: string,
              permissions: string[],
              authenticStartTime: number,
              authenticTimeLimit: number) {
    this.username = username;
    this.email = email;
    this.organization = organization;
    this.permissions = permissions;
    this.authenticTimeLimit = authenticTimeLimit;
    this.authenticStartTime = authenticStartTime;
  }

  getUsername(): string {
    return this.username;
  }

  getEmail(): string {
    return this.email;
  }

  getOrganization(): string {
    return this.organization;
  }

  getPermissions(): string[] {
    return this.permissions;
  }

  getAuthenticTimeLimit(): number {
    return this.authenticTimeLimit;
  }

  getAuthenticStartTime(): number {
    return this.authenticStartTime;
  }
}
