import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import {CarrierUtils} from '../../../../../core/utils/carrier.utils';
import {TimeLimitsEnum} from '../../../../../core/utils/data/time-limits-enum';
import {ProductCodeEnum} from '../../../../../core/utils/data/product-code-enum';
import {FormUtils} from '../../../../../core/utils/form/form-utils';
import {ConfirmationDialogService} from '../../../../../shared/components/modal/service/confirm-modal.service';
import {pairwise} from 'rxjs/operators';
import {modalsConfiguration} from '../../../../../core/configuration/modal';
import {ModalCodeEnum} from '../../../../../core/utils/data/modal-code-enum';
import {ModalUI} from '../../../../../core/models/modal.ui';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {RegexUtils} from '../../../../../core/utils/regex-utils';


export class GTLComplianceForm {

  public static readonly AGREEMENT_DEFAULT_WAIVER_CODE = 'AGREEMENT';
  public static readonly DEPOSIT_DEFAULT_WAIVER_CODE = 'DEPOSIT';
  public static readonly FINAL_PAYMENT_DEFAULT_WAIVER_CODE = 'FINAL PAYMENT';
  public static readonly NAME_DEFAULT_WAIVER_CODE = 'NAME';
  public static readonly TICKETING_DEFAULT_WAIVER_CODE = 'TTL';
  public readonly modal = modalsConfiguration;

  private productCode: ProductCodeEnum;
  private currentCarrier = '';
  private isDisabled = true;
  private prevForm: {};
  private isImplemented = false;
  private settingsNotInitialized: string[] = [];
  private confirmationDialogService: ConfirmationDialogService;

  private fb: FormBuilder = new FormBuilder();
  private formGroup: FormGroup;

  public setCurrentCarrier(carrierCode: string) {
    this.currentCarrier = carrierCode;
  }

  public setProductCode(productCode: ProductCodeEnum) {
    this.productCode = productCode;
  }

  public setCurrentState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  public setPreviousForm(form: {}) {
    this.prevForm = form;
  }

  public isFormImplemented() {
    return this.isImplemented;
  }

  public setFormImplemented(isImplemented: boolean) {
    this.isImplemented = isImplemented;
  }

  public getSettingsNotInitialized() {
    return this.settingsNotInitialized;
  }

  public setSettingsNotInitialized(settingsNotInitialized: string[]) {
    this.settingsNotInitialized = settingsNotInitialized;
  }

  public getConfirmationDialogService(): ConfirmationDialogService {
    return this.confirmationDialogService;
  }

  public setConfirmationDialogService(value: ConfirmationDialogService) {
    this.confirmationDialogService = value;
  }

  buildForm(): FormGroup {
    this.formGroup = this.fb.group({
      compliance: this.fb.group({
        agreementCompliance:  this.fb.group({
          timeLimitType: ['Agreement', [Validators.pattern(RegexUtils.VALIDATOR_GTL_COMPLIANCE_TIME_LIMIT_TYPE)]],
          timeLimitCode: [TimeLimitsEnum.AGT, [Validators.pattern(RegexUtils.VALIDATOR_GTL_COMPLIANCE_TIME_LIMIT_CODE)]],
          enableCompliance: [false],
          defaultWaiver: [this.currentCarrier + ' ' + GTLComplianceForm.AGREEMENT_DEFAULT_WAIVER_CODE + ' WAIVED',
            [Validators.pattern(RegexUtils.VALIDATOR_GTL_COMPLIANCE_WAIVER)]],
          defaultWaiverCode: [GTLComplianceForm.AGREEMENT_DEFAULT_WAIVER_CODE,
            [Validators.pattern(RegexUtils.VALIDATOR_GTL_COMPLIANCE_WAIVER_CODE)]],
        }),
        depositCompliance:  this.fb.group({
          timeLimitType: ['Deposit', [Validators.pattern(RegexUtils.VALIDATOR_GTL_COMPLIANCE_TIME_LIMIT_TYPE)]],
          timeLimitCode: [TimeLimitsEnum.DEP, [Validators.pattern(RegexUtils.VALIDATOR_GTL_COMPLIANCE_TIME_LIMIT_CODE)]],
          enableCompliance: [true],
          defaultWaiver: [this.currentCarrier + ' ' + GTLComplianceForm.DEPOSIT_DEFAULT_WAIVER_CODE + ' WAIVED',
            [Validators.pattern(RegexUtils.VALIDATOR_GTL_COMPLIANCE_WAIVER)]],
          defaultWaiverCode: [GTLComplianceForm.DEPOSIT_DEFAULT_WAIVER_CODE,
            [Validators.pattern(RegexUtils.VALIDATOR_GTL_COMPLIANCE_WAIVER_CODE)]]
        }),
        finalPaymentCompliance: this.fb.group({
          timeLimitType: ['Final Payment', [Validators.pattern(RegexUtils.VALIDATOR_GTL_COMPLIANCE_TIME_LIMIT_TYPE)]],
          timeLimitCode: [TimeLimitsEnum.PAY, [Validators.pattern(RegexUtils.VALIDATOR_GTL_COMPLIANCE_TIME_LIMIT_CODE)]],
          enableCompliance: [true],
          defaultWaiver: [this.currentCarrier + ' ' + GTLComplianceForm.FINAL_PAYMENT_DEFAULT_WAIVER_CODE + ' WAIVED',
            [Validators.pattern(RegexUtils.VALIDATOR_GTL_COMPLIANCE_WAIVER)]],
          defaultWaiverCode: [GTLComplianceForm.FINAL_PAYMENT_DEFAULT_WAIVER_CODE,
            [Validators.pattern(RegexUtils.VALIDATOR_GTL_COMPLIANCE_WAIVER_CODE)]]
        }),
        nameCompliance: this.fb.group({
          timeLimitType: ['Name', [Validators.pattern(RegexUtils.VALIDATOR_GTL_COMPLIANCE_TIME_LIMIT_TYPE)]],
          timeLimitCode: [TimeLimitsEnum.NME, [Validators.pattern(RegexUtils.VALIDATOR_GTL_COMPLIANCE_TIME_LIMIT_CODE)]],
          enableCompliance: [true],
          defaultWaiver: [this.currentCarrier + ' ' + GTLComplianceForm.NAME_DEFAULT_WAIVER_CODE + ' WAIVED',
            [Validators.pattern(RegexUtils.VALIDATOR_GTL_COMPLIANCE_WAIVER)]],
          defaultWaiverCode: [GTLComplianceForm.NAME_DEFAULT_WAIVER_CODE,
            [Validators.pattern(RegexUtils.VALIDATOR_GTL_COMPLIANCE_WAIVER_CODE)]]
        }),
        ticketingCompliance : this.fb.group({
          timeLimitType: ['Ticketing', [Validators.pattern(RegexUtils.VALIDATOR_GTL_COMPLIANCE_TIME_LIMIT_TYPE)]],
          timeLimitCode: [TimeLimitsEnum.TKT, [Validators.pattern(RegexUtils.VALIDATOR_GTL_COMPLIANCE_TIME_LIMIT_CODE)]],
          enableCompliance: [true],
          defaultWaiver: [this.currentCarrier + ' ' + GTLComplianceForm.TICKETING_DEFAULT_WAIVER_CODE + ' WAIVED',
            [Validators.pattern(RegexUtils.VALIDATOR_GTL_COMPLIANCE_WAIVER)]],
          defaultWaiverCode: [GTLComplianceForm.TICKETING_DEFAULT_WAIVER_CODE,
            [Validators.pattern(RegexUtils.VALIDATOR_GTL_COMPLIANCE_WAIVER_CODE)]]
        })
      }),
    });

    if (CarrierUtils.canAccessSetting(this.currentCarrier, 'ttlAdjustmentOrderDefinition')) {
      this.ticketingCompliance.addControl('ticketTLProcessOptions', this.fb.group({
        ttlAdjustmentOrderDefinition: [['NBZ', 'FXT', 'LTD', 'MIN', 'MAX']]
      }));
    }

    if (this.isDisabled) {
      this.formGroup.disable();
    }

    FormUtils.validateAllFormFields(this.formGroup);

    this.setValueChangesForDifferentComplianceTimeLimits('agreementCompliance',
      modalsConfiguration.filter(m => m.code === ModalCodeEnum.GTLAGGR).shift());

    this.setValueChangesForDifferentComplianceTimeLimits('ticketingCompliance',
      modalsConfiguration.filter(m => m.code === ModalCodeEnum.GTLTKTG).shift());

    this.setValueChangesForDifferentComplianceTimeLimits('depositCompliance',
      modalsConfiguration.filter(m => m.code === ModalCodeEnum.GTLDEPO).shift());

    this.setValueChangesForDifferentComplianceTimeLimits('finalPaymentCompliance',
      modalsConfiguration.filter(m => m.code === ModalCodeEnum.GTLFPAY).shift());

    this.setValueChangesForDifferentComplianceTimeLimits('nameCompliance',
      modalsConfiguration.filter(m => m.code === ModalCodeEnum.GTLNAME).shift());

    return this.formGroup;
  }

  get ticketingCompliance() {
    return this.formGroup.get('compliance').get('ticketingCompliance') as FormGroup;
  }

  getGtlEnableCompliance(complianceType: string) {
    return this.formGroup.get('compliance').get(complianceType).get('enableCompliance') as FormControl;
  }

  setValueChangesForDifferentComplianceTimeLimits(complianceType: string, modalUI: ModalUI) {
    const compliance = this.getGtlEnableCompliance(complianceType);
    compliance.valueChanges
      .pipe(pairwise()).subscribe(([prev, next]) => {
      if ((isNotNullOrUndefined(prev) || prev) && !next) {
        this.confirmationDialogService.confirm(modalUI.componentType
          , modalUI.displayMessage, true).then((confirmed) => {
          if (!confirmed) {
            compliance.setValue(true);
          }
        }).catch(() => compliance.setValue(true));
      }
    });
  }
}
