import {FieldConfig} from '../models/field-config';
import {BusinessType} from '../models/business-type';
import {BusinessTypeService} from '../services/business-type.service';
import {FieldService} from '../services/field.service';
import {Field} from '../models/field';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SetUI} from '../../../../core/models/set.ui';
import {Router} from '@angular/router';
import {SetService} from '../../../../core/services/set.service';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';

@Component({
  selector: 'app-business-type',
  template: `
    <ng-container *ngIf="this.businessType && this.businessType.isArrayObject === true && this.isMultiModeEnabled; else simpleObject">
      <app-complex-object
        [name]="controlName"
        [value]="value"
        [state]="state"
        [fields]="fields"
        [multiPnrFields]="multiPnrFields"
        [group]="group"
        [showFullSize]="showFullSize"
        (invalid)="validateData($event)">
      </app-complex-object>
    </ng-container>
    <ng-template #simpleObject>
      <ng-container *ngIf="this.businessType && this.businessType.isArrayObject === true; else elseTemplate">
          <app-object
                  [name]="controlName"
                  [value]="value"
                  [state]="state"
                  [fields]="fields"
                  [group]="group"
                  [showFullSize]="showFullSize"
                  (invalid)="validateData($event)">
          </app-object>
      </ng-container>
      <ng-template #elseTemplate>
        <ng-container *ngFor="let field of fields;"
                      appDynamicField
                      [field]="field"
                      [group]="group"
                      [state]="state"
                      [showFullSize]="showFullSize"
                      (invalid)="validateData($event)">
        </ng-container>
      </ng-template>
    </ng-template >
    <div [hidden]="this.state==='info'"
         *ngIf="errors.length > 0"
         class="text-danger">
      Error :
      <div *ngFor="let error of errors"
           class="inline padding_right_5">{{ error.message }}</div>
    </div>
  `,
  styles: []
})
export class BusinessTypeComponent implements OnInit {
  private static readonly MULTI_PNR_MODE = 'multiPnrMode';
  @Input() name: string;
  @Input() criterion: string;
  @Input() state: string;
  @Input() data: any;
  @Input() flow: string;
  @Input() fieldConfigs: FieldConfig[];
  @Input() isObject: boolean;
  @Input() showFullSize: boolean;
  @Output() form = new EventEmitter<FormGroup>();

  controlName: string;
  businessType: BusinessType;
  value: any;
  fields: Field[] = [];
  multiPnrFields: Field[] = [];
  group: FormGroup = this.fb.group({});
  parent: FormGroup = this.fb.group({});
  errors: any[] = [];
  set: SetUI;
  setFromField: string[];
  isObjectFromField: boolean;
  isMultiModeEnabled = false;

  constructor(private fb: FormBuilder,
              private fieldService: FieldService,
              private businessTypeService: BusinessTypeService,
              private router: Router,
              private setService: SetService) {
  }

  ngOnInit() {
    this.renderBusinessTypeView();
  }

  initControls() {
    this.fields = [];
    this.multiPnrFields = [];
    this.errors = [];
    this.group = this.fb.group({});
  }

  renderBusinessTypeView() {
    if (this.name) {
      this.businessTypeService.getBusinessType(this.name)
        .subscribe(businessType => this.businessType = businessType);
    }
    this.initControls();
    this.set = this.setService.extractSetUIFromURL(this.router.url);
    const flow = this.flow ? this.flow : this.businessType.flow;
    let fieldConfigs: FieldConfig[] = [];
    if (this.fieldConfigs && this.fieldConfigs.length > 0) {
      fieldConfigs = this.fieldConfigs;
    } else if (this.businessType && this.businessType.fieldConfigs.length > 0) {
      fieldConfigs = this.businessType.fieldConfigs;
    }
    const tab = flow.split(/{\d}/);

    // Field length changes dynamically depending on the Set type.
    // Ex: DPP has an additional field added for almost all criterias to consider both or either pnrs to evaluate action rules.
    const fieldLength: number = fieldConfigs.filter(f => isNotNullOrUndefined(f.setUI)).length > 0 ?
      fieldConfigs.filter(fc => !isNotNullOrUndefined(fc.setUI) || (fc.setUI.indexOf(this.set.setUI) > -1)).length :
      fieldConfigs.length;

    // This flag is used to build complex form object structure
    this.isMultiModeEnabled = fieldConfigs.filter(fc => fc.setUI &&
                                                        fc.setUI.indexOf(this.set.setUI) > -1 &&
                                                        fc.field === BusinessTypeComponent.MULTI_PNR_MODE).length > 0;

    // Depending on number of fields, few criterion type changes from regular datatype to object.
    // This info is passed in the field itself and is optional.
    this.isObjectFromField = fieldConfigs.filter(fc => fc.setUI &&
                                                       fc.setUI.indexOf(this.set.setUI) > -1 &&
                                                       fc.isObject &&
                                                       fc.isObject === true).length > 0;

    // If the criteria structure was modified to be an object (used by DPP only as of now), then rename the criteria by suffixing it with 'MultiPnr'
    if (this.isObjectFromField || (this.isMultiModeEnabled && this.businessType.isArrayObject)) {
      this.criterion = this.criterion + 'MultiPnr';
    }
    if (this.criterion) {
      this.controlName = this.criterion;
    } else {
      this.controlName = 'data';
    }
    this.parent = this.fb.group({[this.controlName]: this.group});

    for (let x = 0; x < tab.length; x++) {
      if (x <= fieldConfigs.length - 1) {
        this.fieldService.getFieldByConfig(fieldConfigs[x]).subscribe(data => {
          const field = data;
          field.name = (fieldLength === 1) && !this.isObject ? this.controlName : field.name;
          // Parse the Set info from field config and add it to the criteria if it matches with the current Set
          if (fieldConfigs[x].setUI) {
            this.setFromField = fieldConfigs[x].setUI;
          } else {
            this.setFromField = [this.set.setUI];
          }
          if (this.setFromField.indexOf(this.set.setUI) > -1) {
            // If process is dealing with multiple pnrs and the object type is ArrayObject, then split the fields.
            if (field.name === BusinessTypeComponent.MULTI_PNR_MODE && this.businessType.isArrayObject) {
              this.multiPnrFields.push({label: tab[x], type: 'flow'});
              this.addControl(field, this.multiPnrFields);
            } else {
              this.fields.push({label: tab[x], type: 'flow'});
              this.addControl(field, this.fields);
            }
          }
        });
      } else if (fieldConfigs.filter(f => f.setUI).length < 1) {
          // If there are no fields, just add the label info.
          this.fields.push({label: tab[x], type: 'flow'});
      }
    }
    if (fieldConfigs.length > tab.length) {
      for (let x = tab.length; x < fieldConfigs.length; x++) {
        this.fieldService.getFieldByConfig(fieldConfigs[x]).subscribe(data => {
          const field = data;
          field.name = (fieldLength === 1) && !this.isObject ? this.controlName : field.name;
          this.addControl(field, this.fields);
        });
      }
    }

    if ((this.businessType && this.businessType.isObject) || this.isObject || this.isObjectFromField) {
      this.form.emit(this.parent);
    } else if (Object.keys(this.group.controls).length > 0) {
      this.form.emit(this.group);
    }
  }

  addControl(field: Field, fields: Field[]) {
    fields.push(field);
    let value = field.value;
    if (this.businessType && this.businessType.isArrayObject) {
      if (this.data) {
        this.value = JSON.parse(this.data);
      }
      // If process is dealing with multiple pnrs and the object type is ArrayObject, then construct the nexted form structure.
      if (this.isMultiModeEnabled) {
        if (field.name === BusinessTypeComponent.MULTI_PNR_MODE) {
          this.group.addControl(this.controlName, this.fb.group({ 'multiModePnr' : this.fb.control(value[0])}));
        } else {
          this.group.addControl(this.controlName, this.fb.group({'values': this.fb.array([])}));
        }
      } else {
        this.group.addControl(this.controlName, this.fb.array([]));
      }
    } else if (this.businessType && this.businessType.isArray) {
      const arrayValue = this.data ? JSON.parse(this.data) : value;
      this.group.addControl(this.controlName, this.fb.array(arrayValue));
    } else if (this.data && ((this.businessType && this.businessType.isObject) || this.isObject || this.isObjectFromField)) {
      const obj = JSON.parse(this.data);
      if (isNotNullOrUndefined(obj) && obj.hasOwnProperty(field.name)) {
        value = obj[field.name];
      } else {
        value = field.value;
      }
      this.group.addControl(field.name, this.fb.control(value));
      if (obj && obj['couponMatchingStatus']) {
        this.group.addControl('couponMatchingStatus', this.fb.control(obj['couponMatchingStatus']));
      }
    } else if (field.name === 'paxNamePercentage') {
      value = this.data ? JSON.parse(this.data) : value;
      this.group.addControl(field.name, this.fb.group(value));
    } else {
      value = this.data ? JSON.parse(this.data) : value;
      if (field.name === BusinessTypeComponent.MULTI_PNR_MODE && Array.isArray(value)) {
        value = value[0];
      }
      this.group.addControl(field.name, this.fb.control(value));
    }
  }

  validateData(error: any) {
    const index = this.errors.findIndex(e => e.field === error.field);
    if (index > -1) {
      this.errors.splice(index, 1);
    }
    if (error.message !== '') {
      this.errors.push(error);
    }
  }

}
