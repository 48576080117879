<div [formGroup]="group" class="d-block">
  <span class="d-block">Passenger's <strong>name matching method</strong> </span>
  <ul class="d-inline-block">
    <ng-container *ngFor="let formControl of inputArray.controls; let idx = index;">
      <li *ngIf="formControl.get('inclusive').value === true" [formGroup]="getAsGroup(formControl)">
            <span [style.visibility]="idx === getFirstControlIndex(formControl.get('inclusive').value) ? 'hidden' : 'visible'"
                  class="info-green pr-1 d-inline-block pax-name-matching-method-prefix"><i>or</i>&nbsp;</span>

        <ng-container *ngIf="disabled; else editTemplate_nameType">
          <span class="info p-2">{{ _formConfigOptions('nameType')[formControl.get('nameType').value] }}</span>
        </ng-container>
        <ng-template #editTemplate_nameType>
          <select formControlName="nameType"
                  [id]="'nameType-' + idx"
                  class="select-custom"
                  [class.isInvalid]="methodIsDuplicated(idx)">
            <option *ngFor="let item of _formConfigOptionKeys('nameType')"
                    [value]="item">{{ _formConfigOptions('nameType')[item] }}</option>
          </select>
        </ng-template>

        <ng-container *ngIf="disabled; else editTemplate_matchingMethod">
          <span class="info p-2">{{ _formConfigOptions('matchingMethod')[formControl.get('matchingMethod').value] }}</span>
        </ng-container>
        <ng-template #editTemplate_matchingMethod>
          <select formControlName="matchingMethod"
                  [id]="'matchingMethod-' + idx"
                  class="select-custom"
                  [class.isInvalid]="methodIsDuplicated(idx)">
            <option *ngFor="let item of _formConfigOptionKeys('matchingMethod')"
                    [value]="item">{{ _formConfigOptions('matchingMethod')[item] }}</option>
          </select>
        </ng-template>

        <ng-container *ngIf="disabled; else editTemplate_matchingPattern">
          <span class="info p-2">{{ getMatchingPatternOptions(formControl.get('nameType').value,
            formControl.get('matchingMethod').value)[formControl.get('matchingPattern').value] }}</span>
        </ng-container>
        <ng-template #editTemplate_matchingPattern>
          <select formControlName="matchingPattern"
                  [id]="'matchingPattern-' + idx"
                  class="select-custom"
                  [class.isInvalid]="methodIsDuplicated(idx)">
            <option *ngFor="let item of getMatchingPatternKeys(formControl.get('nameType').value, formControl.get('matchingMethod').value)"
                    [value]="item">{{ getMatchingPatternOptions(formControl.get('nameType').value, formControl.get('matchingMethod').value)[item] }}</option>
          </select>
        </ng-template>

        <ul *ngIf="formControl.get('matchingPattern').value === 'list'"
            [class.mb-4]="idx === getFirstControlIndex(formControl.get('inclusive').value) || disabled">
          <li>in the following <strong>list</strong>
            <ul>
              <li formArrayName="list" *ngFor="let patternControl of getAsFormArray(formControl.get('list')).controls; let jdx = index;">
                <ng-container *ngIf="disabled; else editTemplate_list">
                  <span class="info p-2">{{ patternControl.value }}</span>
                </ng-container>
                <ng-template #editTemplate_list>
                  <input [id]="'list-item-' + idx + '-' + jdx"
                         class="input-text-custom d-inline-block"
                         [formControl]="getAsFormControl(patternControl)"
                         [class.read-only]="disabled"
                         [class.isInvalid]="patternControl.errors || methodIsDuplicated(idx)"/>
                </ng-template>

                <a *ngIf="!disabled"
                   [hidden]="jdx <= 0"
                   class="menu-criteria pl-1"
                   href="javascript:void(0)"
                   (click)="deletePredefinedName(idx, jdx, 'list')">
                  <i class="fa fa-trash" title="Delete this condition"></i>
                </a>
                <a *ngIf="!disabled"
                   [hidden]="jdx < getAsFormArray(formControl.get('list')).controls.length - 1"
                   class="menu-criteria pl-1"
                   href="javascript:void(0)"
                   (click)="addPredefinedName(idx, jdx + 1, 'list')">
                  <i class="fa fa-plus-square"></i>
                </a>

                <app-control-messages [control]="patternControl"></app-control-messages>
              </li>
            </ul>
          </li>
        </ul>

        <ul *ngIf="formControl.get('matchingPattern').value === 'customPattern'"
            [class.mb-4]="idx === getFirstControlIndex(formControl.get('inclusive').value) || disabled">
          <ng-template [ngIf]="formControl.get('nameType').value === 'fullName'" [ngIfThen]="fullNamePattern" [ngIfElse]="singleNamePattern"></ng-template>
          <ng-template #fullNamePattern>
            <li [class.mb-2]="formControl.get('lastNameIsEmpty').value === false">where <strong>Last Name </strong>
              <ng-container *ngIf="disabled; else editTemplate_lastNameIsEmpty">
                <span class="info p-2">{{ formControl.get('lastNameIsEmpty').value === true ? 'is empty' : 'is not empty' }}</span>
              </ng-container>
              <ng-template #editTemplate_lastNameIsEmpty>
                <select formControlName="lastNameIsEmpty"
                        [id]="'lastNamePattern-lastNameIsEmpty-' + idx"
                        class="select-custom d-inline-block"
                        [class.isInvalid]="formControl.hasError('matchingFullName') || methodIsDuplicated(idx)">
                  <option *ngFor="let item of _formConfigOptions('lastNameIsEmpty')" [ngValue]="item === 'is empty'">{{ item }}</option>
                </select>
              </ng-template>
              <span *ngIf="formControl.get('lastNameIsEmpty').value === false"> and <strong>has</strong> </span>

              <ul *ngIf="formControl.get('lastNameIsEmpty').value === false">
                <ng-container *ngFor="let patternControl of getAsFormArray(formControl.get('lastNamePattern')).controls; let jdx = index;">
                  <li [formGroup]="getAsGroup(patternControl)"
                      [class.mb-4]="(patternControl.get('text') && patternControl.get('text').errors) || patternControl.get('numberOfInstances').errors">
                        <span [style.visibility]="jdx <= 0 ? 'hidden' : 'visible'"
                              class="info-green pr-1">followed by </span>

                    <ng-container *ngIf="disabled; else editTemplate_atLeast">
                      <span class="info p-2">{{ patternControl.get('atLeast').value === true ? 'at least' : 'exactly' }}</span>
                    </ng-container>
                    <ng-template #editTemplate_atLeast>
                      <select formControlName="atLeast"
                              [id]="'lastNamePattern-atLeast-' + idx + '-' + jdx"
                              class="select-custom d-inline-block"
                              [class.isInvalid]="methodIsDuplicated(idx)">
                        <option *ngFor="let item of _formConfigOptions('atLeast')" [ngValue]="item === 'at least'">{{ item }}</option>
                      </select>
                    </ng-template>

                    <ng-container *ngIf="disabled; else editTemplate_numberOfInstances">
                      <span class="info p-2">{{ patternControl.get('numberOfInstances').value }}</span>
                    </ng-container>
                    <ng-template #editTemplate_numberOfInstances>
                      <span class="d-inline-block position-relative">
                        <app-control-messages class="position-absolute relative-control-message"
                                              [control]="patternControl.get('numberOfInstances')"></app-control-messages>
                        <input class="input-text-custom number d-inline-block"
                               [id]="'lastNamePattern-numberOfInstances-' + idx + '-' + jdx"
                               type="number"
                               formControlName="numberOfInstances"
                               [min]="_formConfig('numberOfInstances').min"
                               [max]="_formConfig('numberOfInstances').max"
                               [class.read-only]="disabled"
                               [class.isInvalid]="patternControl.get('numberOfInstances').errors || methodIsDuplicated(idx)"/>
                      </span>
                    </ng-template>
                    <span> instance(s) of </span>

                    <ng-container *ngIf="disabled; else editTemplate_instanceOptions">
                      <span *ngIf="patternControl.get('instanceOptions').value !== 'string'"
                            class="info p-2">{{ patternControl.get('instanceOptions').value }}</span>
                    </ng-container>
                    <ng-template #editTemplate_instanceOptions>
                      <select formControlName="instanceOptions"
                              [id]="'lastNamePattern-instanceOptions-' + idx + '-' + jdx"
                              class="select-custom d-inline-block"
                              [class.isInvalid]="methodIsDuplicated(idx)">
                        <option *ngFor="let item of _formConfigOptions('instanceOptions')" [value]="item">{{ item }}</option>
                      </select>
                    </ng-template>

                    <ng-container *ngIf="disabled; else editTemplate_text">
                      <span *ngIf="patternControl.get('text') && patternControl.get('instanceOptions').value === 'string'"
                            class="info p-2">{{ patternControl.get('text').value }}</span>
                    </ng-container>
                    <ng-template #editTemplate_text>
                      <span *ngIf="patternControl.get('text') && patternControl.get('instanceOptions').value === 'string'"
                            class="d-inline-block position-relative">
                        <app-control-messages class="position-absolute relative-control-message"
                                              [control]="patternControl.get('text')"></app-control-messages>

                        <input [id]="'lastNamePattern-text-' + idx + '-' + jdx"
                               class="input-text-custom d-inline-block"
                               formControlName="text"
                               [class.read-only]="disabled"
                               [class.isInvalid]="patternControl.get('text').errors || methodIsDuplicated(idx)"/>
                      </span>
                    </ng-template>

                    <span> in a row </span>

                    <a *ngIf="!disabled"
                       [hidden]="jdx <= 0"
                       class="menu-criteria pl-1"
                       href="javascript:void(0)"
                       (click)="deleteCustomPattern(idx, jdx, 'lastNamePattern')">
                      <i class="fa fa-trash" title="Delete this condition"></i>
                    </a>
                    <a *ngIf="!disabled"
                       [hidden]="jdx < getAsFormArray(formControl.get('lastNamePattern')).controls.length - 1"
                       class="menu-criteria pl-1"
                       href="javascript:void(0)"
                       (click)="addCustomPattern(idx, jdx + 1, 'lastNamePattern')">
                      <i class="fa fa-plus-square"></i>
                    </a>
                  </li>
                </ng-container>
              </ul>
            </li>

            <li>and <strong>First Name </strong>
              <ng-container *ngIf="disabled; else editTemplate_firstNameIsEmpty">
                <span class="info p-2">{{ formControl.get('firstNameIsEmpty').value === true ? 'is empty' : 'is not empty' }}</span>
              </ng-container>
              <ng-template #editTemplate_firstNameIsEmpty>
                <select formControlName="firstNameIsEmpty"
                        [id]="'firstNamePattern-firstNameIsEmpty-' + idx"
                        class="select-custom d-inline-block"
                        [class.isInvalid]="formControl.hasError('matchingFullName') || methodIsDuplicated(idx)">
                  <option *ngFor="let item of _formConfigOptions('firstNameIsEmpty')" [ngValue]="item === 'is empty'">{{ item }}</option>
                </select>
              </ng-template>
              <span *ngIf="formControl.get('firstNameIsEmpty').value === false"> and <strong>has</strong> </span>

              <ul *ngIf="formControl.get('firstNameIsEmpty').value === false">
                <ng-container *ngFor="let patternControl of getAsFormArray(formControl.get('firstNamePattern')).controls; let jdx = index;">
                  <li [formGroup]="getAsGroup(patternControl)"
                      [class.mb-4]="(patternControl.get('text') && patternControl.get('text').errors) || patternControl.get('numberOfInstances').errors">
                        <span [style.visibility]="jdx <= 0 ? 'hidden' : 'visible'"
                              class="info-green pr-1">followed by </span>

                    <ng-container *ngIf="disabled; else editTemplate_atLeast">
                      <span class="info p-2">{{ patternControl.get('atLeast').value === true ? 'at least' : 'exactly' }}</span>
                    </ng-container>
                    <ng-template #editTemplate_atLeast>
                      <select formControlName="atLeast"
                              [id]="'firstNamePattern-atLeast-' + idx + '-' + jdx"
                              class="select-custom d-inline-block"
                              [class.isInvalid]="methodIsDuplicated(idx)">
                        <option *ngFor="let item of _formConfigOptions('atLeast')" [ngValue]="item === 'at least'">{{ item }}</option>
                      </select>
                    </ng-template>

                    <ng-container *ngIf="disabled; else editTemplate_numberOfInstances">
                      <span class="info p-2">{{ patternControl.get('numberOfInstances').value }}</span>
                    </ng-container>
                    <ng-template #editTemplate_numberOfInstances>
                      <span class="d-inline-block position-relative">
                        <app-control-messages class="position-absolute relative-control-message"
                                              [control]="patternControl.get('numberOfInstances')"></app-control-messages>
                        <input class="input-text-custom number d-inline-block"
                               [id]="'firstNamePattern-numberOfInstances-' + idx + '-' + jdx"
                               type="number"
                               formControlName="numberOfInstances"
                               [min]="_formConfig('numberOfInstances').min"
                               [max]="_formConfig('numberOfInstances').max"
                               [class.read-only]="disabled"
                               [class.isInvalid]="patternControl.get('numberOfInstances').errors || methodIsDuplicated(idx)"/>
                      </span>
                    </ng-template>
                    <span> instance(s) of </span>

                    <ng-container *ngIf="disabled; else editTemplate_instanceOptions">
                      <span *ngIf="patternControl.get('instanceOptions').value !== 'string'"
                            class="info p-2">{{ patternControl.get('instanceOptions').value }}</span>
                    </ng-container>
                    <ng-template #editTemplate_instanceOptions>
                      <select formControlName="instanceOptions"
                              [id]="'firstNamePattern-instanceOptions-' + idx + '-' + jdx"
                              class="select-custom d-inline-block"
                              [class.isInvalid]="methodIsDuplicated(idx)">
                        <option *ngFor="let item of _formConfigOptions('instanceOptions')" [value]="item">{{ item }}</option>
                      </select>
                    </ng-template>

                    <ng-container *ngIf="disabled; else editTemplate_text">
                      <span *ngIf="patternControl.get('text') && patternControl.get('instanceOptions').value === 'string'"
                            class="info p-2">{{ patternControl.get('text').value }}</span>
                    </ng-container>
                    <ng-template #editTemplate_text>
                      <span *ngIf="patternControl.get('text') && patternControl.get('instanceOptions').value === 'string'"
                            class="d-inline-block position-relative">
                        <app-control-messages class="position-absolute relative-control-message"
                                              [control]="patternControl.get('text')"></app-control-messages>
                        <input [id]="'firstNamePattern-text-' + idx + '-' + jdx"
                               class="input-text-custom d-inline-block"
                               formControlName="text"
                               [class.read-only]="disabled"
                               [class.isInvalid]="patternControl.get('text').errors || methodIsDuplicated(idx)"/>
                      </span>
                    </ng-template>

                    <span> in a row </span>

                    <a *ngIf="!disabled"
                       [hidden]="jdx <= 0"
                       class="menu-criteria pl-1"
                       href="javascript:void(0)"
                       (click)="deleteCustomPattern(idx, jdx, 'firstNamePattern')">
                      <i class="fa fa-trash" title="Delete this condition"></i>
                    </a>
                    <a *ngIf="!disabled"
                       [hidden]="jdx < getAsFormArray(formControl.get('firstNamePattern')).controls.length - 1"
                       class="menu-criteria pl-1"
                       href="javascript:void(0)"
                       (click)="addCustomPattern(idx, jdx + 1, 'firstNamePattern')">
                      <i class="fa fa-plus-square"></i>
                    </a>
                  </li>
                </ng-container>
              </ul>
            </li>

            <app-control-messages [control]="formControl"></app-control-messages>
          </ng-template>

          <ng-template #singleNamePattern>
            <li><span>and <strong>has </strong></span>
              <ul>
                <ng-container *ngFor="let patternControl of getAsFormArray(formControl.get('singleNamePattern')).controls; let jdx = index;">
                  <li [formGroup]="getAsGroup(patternControl)"
                      [class.mb-4]="(patternControl.get('text') && patternControl.get('text').errors) || patternControl.get('numberOfInstances').errors">
                        <span [style.visibility]="jdx <= 0 ? 'hidden' : 'visible'"
                              class="info-green pr-1">followed by </span>

                    <ng-container *ngIf="disabled; else editTemplate_atLeast">
                      <span class="info p-2">{{ patternControl.get('atLeast').value === true ? 'at least' : 'exactly' }}</span>
                    </ng-container>
                    <ng-template #editTemplate_atLeast>
                      <select formControlName="atLeast"
                              [id]="'singleNamePattern-atLeast-' + idx + '-' + jdx"
                              class="select-custom d-inline-block"
                              [class.isInvalid]="methodIsDuplicated(idx)">
                        <option *ngFor="let item of _formConfigOptions('atLeast')" [ngValue]="item === 'at least'">{{ item }}</option>
                      </select>
                    </ng-template>

                    <ng-container *ngIf="disabled; else editTemplate_numberOfInstances">
                      <span class="info p-2">{{ patternControl.get('numberOfInstances').value }}</span>
                    </ng-container>
                    <ng-template #editTemplate_numberOfInstances>
                      <span class="d-inline-block position-relative">
                        <app-control-messages class="position-absolute relative-control-message"
                                              [control]="patternControl.get('numberOfInstances')"></app-control-messages>
                        <input class="input-text-custom number d-inline-block"
                               [id]="'singleNamePattern-numberOfInstances-' + idx + '-' + jdx"
                               type="number"
                               formControlName="numberOfInstances"
                               [min]="_formConfig('numberOfInstances').min"
                               [max]="_formConfig('numberOfInstances').max"
                               [class.read-only]="disabled"
                               [class.isInvalid]="patternControl.get('numberOfInstances').errors || methodIsDuplicated(idx)"/>
                      </span>
                    </ng-template>
                    <span> instance(s) of </span>

                    <ng-container *ngIf="disabled; else editTemplate_instanceOptions">
                      <span *ngIf="patternControl.get('instanceOptions').value !== 'string'"
                            class="info p-2">{{ patternControl.get('instanceOptions').value }}</span>
                    </ng-container>
                    <ng-template #editTemplate_instanceOptions>
                      <select formControlName="instanceOptions"
                              [id]="'singleNamePattern-instanceOptions-' + idx + '-' + jdx"
                              class="select-custom d-inline-block"
                              [class.isInvalid]="methodIsDuplicated(idx)">
                        <option *ngFor="let item of _formConfigOptions('instanceOptions')" [value]="item">{{ item }}</option>
                      </select>
                    </ng-template>

                    <ng-container *ngIf="disabled; else editTemplate_text">
                      <span *ngIf="patternControl.get('text') && patternControl.get('instanceOptions').value === 'string'"
                            class="info p-2">{{ patternControl.get('text').value }}</span>
                    </ng-container>
                    <ng-template #editTemplate_text>
                      <span *ngIf="patternControl.get('text') && patternControl.get('instanceOptions').value === 'string'"
                            class="d-inline-block position-relative">
                        <app-control-messages class="position-absolute relative-control-message"
                                              [control]="patternControl.get('text')"></app-control-messages>
                        <input [id]="'singleNamePattern-text-' + idx + '-' + jdx"
                               class="input-text-custom d-inline-block"
                               formControlName="text"
                               [class.read-only]="disabled"
                               [class.isInvalid]="patternControl.get('text').errors || methodIsDuplicated(idx)"/>
                      </span>
                    </ng-template>

                    <span> in a row </span>

                    <a *ngIf="!disabled"
                       [hidden]="jdx <= 0"
                       class="menu-criteria pl-1"
                       href="javascript:void(0)"
                       (click)="deleteCustomPattern(idx, jdx, 'singleNamePattern')">
                      <i class="fa fa-trash" title="Delete this condition"></i>
                    </a>
                    <a *ngIf="!disabled"
                       [hidden]="jdx < getAsFormArray(formControl.get('singleNamePattern')).controls.length - 1"
                       class="menu-criteria pl-1"
                       href="javascript:void(0)"
                       (click)="addCustomPattern(idx, jdx + 1, 'singleNamePattern')">
                      <i class="fa fa-plus-square"></i>
                    </a>
                  </li>
                </ng-container>
              </ul>
            </li>
          </ng-template>
        </ul>

        <ul *ngIf="inputArray.hasError('paxNameMatching')
                  && inputArray.errors['paxNameMatching']['inclusive'] === formControl.get('inclusive').value
                  && inputArray.errors['paxNameMatching']['index'].length > 0
                  && inputArray.errors['paxNameMatching']['index'].indexOf(idx) === inputArray.errors['paxNameMatching']['index'].length - 1
                  && ['list', 'customPattern'].indexOf(formControl.get('matchingPattern').value) > -1">
          <li>
            <app-control-messages *ngIf="inputArray.errors['paxNameMatching']['index'].length > 0"
                                  [control]="inputArray"></app-control-messages>
          </li>
        </ul>

        <a *ngIf="!disabled"
           [hidden]="idx === getFirstControlIndex(formControl.get('inclusive').value)
                  && countPaxNameMatchingMethods(formControl.get('inclusive').value) <= 1"
           class="menu-criteria pl-1"
           href="javascript:void(0)"
           (click)="deletePaxNameMatching(idx)">
          <i class="fa fa-trash" title="Delete this condition"></i>
        </a>
        <a *ngIf="!disabled"
           [hidden]="idx !== getLastControlIndex(formControl.get('inclusive').value) || inclusiveLimitReached"
           class="menu-criteria pl-1"
           href="javascript:void(0)"
           (click)="addPaxNameMatching(inputArray.controls.length, formControl.get('inclusive').value)">
          <i class="fa fa-plus-square" title="Add a date range"></i>
        </a>

        <ul *ngIf="inputArray.hasError('paxNameMatching')
                  && inputArray.errors['paxNameMatching']['inclusive'] === formControl.get('inclusive').value
                  && inputArray.errors['paxNameMatching']['index'].length > 0
                  && inputArray.errors['paxNameMatching']['index'].indexOf(idx) === inputArray.errors['paxNameMatching']['index'].length - 1
                  && ['list', 'customPattern'].indexOf(formControl.get('matchingPattern').value) < 0">
          <li>
            <app-control-messages [control]="inputArray"></app-control-messages>
          </li>
        </ul>
      </li>
    </ng-container>
  </ul>

  <input type="hidden" class="input-text-custom" [class.isInvalid]="!disabled
                            && inputArray.hasError('maxArrayLength')
                            && inputArray.errors['maxArrayLength']['inclusive'] === true" />
  <app-control-messages *ngIf="!disabled
                            && inputArray.hasError('maxArrayLength')
                            && inputArray.errors['maxArrayLength']['inclusive'] === true"
                        [override]="overrideMaxArrayLengthError()"
                        [control]="inputArray"></app-control-messages>
</div>

<div [formGroup]="group" class="d-block">
  <span class="d-block" *ngIf="!hasExceptions() && !disabled">with
    <i><a *ngIf="!hasExceptions()"
          href="javascript:void(0)"
          (click)="addPaxNameMatching(inputArray.controls.length, false)">no exceptions...</a></i>
  </span>
  <span class="d-block" *ngIf="hasExceptions()">but <i class="info-red">not</i> </span>

  <ul class="d-inline-block pax-name-matching-exceptions" *ngIf="hasExceptions()">
    <ng-container *ngFor="let formControl of inputArray.controls; let idx = index;">
      <li *ngIf="formControl.get('inclusive').value === false" [formGroup]="getAsGroup(formControl)">
             <span [style.visibility]="idx === getFirstControlIndex(formControl.get('inclusive').value) ? 'hidden' : 'visible'"
                   class="info-red pr-1 d-inline-block pax-name-matching-method-prefix"><i>nor</i>&nbsp;</span>

        <ng-container *ngIf="disabled; else editTemplate_nameType">
          <span class="info p-2">{{ _formConfigOptions('nameType')[formControl.get('nameType').value] }}</span>
        </ng-container>
        <ng-template #editTemplate_nameType>
          <select formControlName="nameType"
                  [id]="'nameType-' + idx"
                  class="select-custom"
                  [class.isInvalid]="methodIsDuplicated(idx)">
            <option *ngFor="let item of _formConfigOptionKeys('nameType')"
                    [value]="item">{{ _formConfigOptions('nameType')[item] }}</option>
          </select>
        </ng-template>

        <ng-container *ngIf="disabled; else editTemplate_matchingMethod">
          <span class="info p-2">{{ _formConfigOptions('matchingMethod')[formControl.get('matchingMethod').value] }}</span>
        </ng-container>
        <ng-template #editTemplate_matchingMethod>
          <select formControlName="matchingMethod"
                  [id]="'matchingMethod-' + idx"
                  class="select-custom"
                  [class.isInvalid]="methodIsDuplicated(idx)">
            <option *ngFor="let item of _formConfigOptionKeys('matchingMethod')"
                    [value]="item">{{ _formConfigOptions('matchingMethod')[item] }}</option>
          </select>
        </ng-template>

        <ng-container *ngIf="disabled; else editTemplate_matchingPattern">
          <span class="info p-2">{{ getMatchingPatternOptions(formControl.get('nameType').value,
            formControl.get('matchingMethod').value)[formControl.get('matchingPattern').value] }}</span>
        </ng-container>
        <ng-template #editTemplate_matchingPattern>
          <select formControlName="matchingPattern"
                  [id]="'matchingPattern-' + idx"
                  class="select-custom"
                  [class.isInvalid]="methodIsDuplicated(idx)">
            <option *ngFor="let item of getMatchingPatternKeys(formControl.get('nameType').value, formControl.get('matchingMethod').value)"
                    [value]="item">{{ getMatchingPatternOptions(formControl.get('nameType').value, formControl.get('matchingMethod').value)[item] }}</option>
          </select>
        </ng-template>

        <ul *ngIf="formControl.get('matchingPattern').value === 'list'"
            [class.mb-4]="idx === getFirstControlIndex(formControl.get('inclusive').value) || disabled">
          <li>in the following <strong>list</strong>
            <ul>
              <li formArrayName="list" *ngFor="let patternControl of getAsFormArray(formControl.get('list')).controls; let jdx = index;">
                <ng-container *ngIf="disabled; else editTemplate_list">
                  <span class="info p-2">{{ patternControl.value }}</span>
                </ng-container>
                <ng-template #editTemplate_list>
                  <input [id]="'list-item-' + idx + '-' + jdx"
                         class="input-text-custom d-inline-block"
                         [formControl]="getAsFormControl(patternControl)"
                         [class.read-only]="disabled"
                         [class.isInvalid]="patternControl.errors || methodIsDuplicated(idx)"/>
                </ng-template>

                <a *ngIf="!disabled"
                   [hidden]="jdx <= 0"
                   class="menu-criteria pl-1"
                   href="javascript:void(0)"
                   (click)="deletePredefinedName(idx, jdx, 'list')">
                  <i class="fa fa-trash" title="Delete this condition"></i>
                </a>
                <a *ngIf="!disabled"
                   [hidden]="jdx < getAsFormArray(formControl.get('list')).controls.length - 1"
                   class="menu-criteria pl-1"
                   href="javascript:void(0)"
                   (click)="addPredefinedName(idx, jdx + 1, 'list')">
                  <i class="fa fa-plus-square"></i>
                </a>

                <app-control-messages [control]="patternControl"></app-control-messages>
              </li>
            </ul>
          </li>
        </ul>

        <ul *ngIf="formControl.get('matchingPattern').value === 'customPattern'"
            [class.mb-4]="idx === getFirstControlIndex(formControl.get('inclusive').value) || disabled">
          <ng-template [ngIf]="formControl.get('nameType').value === 'fullName'" [ngIfThen]="fullNamePattern" [ngIfElse]="singleNamePattern"></ng-template>
          <ng-template #fullNamePattern>
            <li [class.mb-2]="formControl.get('lastNameIsEmpty').value === false">where <strong>Last Name </strong>
              <ng-container *ngIf="disabled; else editTemplate_lastNameIsEmpty">
                <span class="info p-2">{{ formControl.get('lastNameIsEmpty').value === true ? 'is empty' : 'is not empty' }}</span>
              </ng-container>
              <ng-template #editTemplate_lastNameIsEmpty>
                <select formControlName="lastNameIsEmpty"
                        [id]="'lastNamePattern-lastNameIsEmpty-' + idx"
                        class="select-custom d-inline-block"
                        [class.isInvalid]="formControl.hasError('matchingFullName') || methodIsDuplicated(idx)">
                  <option *ngFor="let item of _formConfigOptions('lastNameIsEmpty')" [ngValue]="item === 'is empty'">{{ item }}</option>
                </select>
              </ng-template>
              <span *ngIf="formControl.get('lastNameIsEmpty').value === false"> and <strong>has</strong> </span>

              <ul *ngIf="formControl.get('lastNameIsEmpty').value === false">
                <ng-container *ngFor="let patternControl of getAsFormArray(formControl.get('lastNamePattern')).controls; let jdx = index;">
                  <li [formGroup]="getAsGroup(patternControl)"
                      [class.mb-4]="(patternControl.get('text') && patternControl.get('text').errors) || patternControl.get('numberOfInstances').errors">
                        <span [style.visibility]="jdx <= 0 ? 'hidden' : 'visible'"
                              class="info-green pr-1">followed by </span>

                    <ng-container *ngIf="disabled; else editTemplate_atLeast">
                      <span class="info p-2">{{ patternControl.get('atLeast').value === true ? 'at least' : 'exactly' }}</span>
                    </ng-container>
                    <ng-template #editTemplate_atLeast>
                      <select formControlName="atLeast"
                              [id]="'lastNamePattern-atLeast-' + idx + '-' + jdx"
                              class="select-custom d-inline-block"
                              [class.isInvalid]="methodIsDuplicated(idx)">
                        <option *ngFor="let item of _formConfigOptions('atLeast')" [ngValue]="item === 'at least'">{{ item }}</option>
                      </select>
                    </ng-template>

                    <ng-container *ngIf="disabled; else editTemplate_numberOfInstances">
                      <span class="info p-2">{{ patternControl.get('numberOfInstances').value }}</span>
                    </ng-container>
                    <ng-template #editTemplate_numberOfInstances>
                      <span class="d-inline-block position-relative">
                        <app-control-messages class="position-absolute relative-control-message"
                                              [control]="patternControl.get('numberOfInstances')"></app-control-messages>
                        <input class="input-text-custom number d-inline-block"
                               [id]="'lastNamePattern-numberOfInstances-' + idx + '-' + jdx"
                               type="number"
                               formControlName="numberOfInstances"
                               [min]="_formConfig('numberOfInstances').min"
                               [max]="_formConfig('numberOfInstances').max"
                               [class.read-only]="disabled"
                               [class.isInvalid]="patternControl.get('numberOfInstances').errors || methodIsDuplicated(idx)"/>
                      </span>
                    </ng-template>
                    <span> instance(s) of </span>

                    <ng-container *ngIf="disabled; else editTemplate_instanceOptions">
                      <span *ngIf="patternControl.get('instanceOptions').value !== 'string'"
                            class="info p-2">{{ patternControl.get('instanceOptions').value }}</span>
                    </ng-container>
                    <ng-template #editTemplate_instanceOptions>
                      <select formControlName="instanceOptions"
                              [id]="'lastNamePattern-instanceOptions-' + idx + '-' + jdx"
                              class="select-custom d-inline-block"
                              [class.isInvalid]="methodIsDuplicated(idx)">
                        <option *ngFor="let item of _formConfigOptions('instanceOptions')" [value]="item">{{ item }}</option>
                      </select>
                    </ng-template>


                    <ng-container *ngIf="disabled; else editTemplate_text">
                      <span *ngIf="patternControl.get('text') && patternControl.get('instanceOptions').value === 'string'"
                            class="info p-2">{{ patternControl.get('text').value }}</span>
                    </ng-container>
                    <ng-template #editTemplate_text>
                      <span *ngIf="patternControl.get('text') && patternControl.get('instanceOptions').value === 'string'"
                            class="d-inline-block position-relative">
                        <app-control-messages class="position-absolute relative-control-message"
                                              [control]="patternControl.get('text')"></app-control-messages>

                        <input [id]="'lastNamePattern-text-' + idx + '-' + jdx"
                               class="input-text-custom d-inline-block"
                               formControlName="text"
                               [class.read-only]="disabled"
                               [class.isInvalid]="patternControl.get('text').errors || methodIsDuplicated(idx)"/>
                      </span>
                    </ng-template>

                    <span> in a row </span>

                    <a *ngIf="!disabled"
                       [hidden]="jdx <= 0"
                       class="menu-criteria pl-1"
                       href="javascript:void(0)"
                       (click)="deleteCustomPattern(idx, jdx, 'lastNamePattern')">
                      <i class="fa fa-trash" title="Delete this condition"></i>
                    </a>
                    <a *ngIf="!disabled"
                       [hidden]="jdx < getAsFormArray(formControl.get('lastNamePattern')).controls.length - 1"
                       class="menu-criteria pl-1"
                       href="javascript:void(0)"
                       (click)="addCustomPattern(idx, jdx + 1, 'lastNamePattern')">
                      <i class="fa fa-plus-square"></i>
                    </a>
                  </li>
                </ng-container>
              </ul>
            </li>

            <li>and <strong>First Name </strong>
              <ng-container *ngIf="disabled; else editTemplate_firstNameIsEmpty">
                <span class="info p-2">{{ formControl.get('firstNameIsEmpty').value === true ? 'is empty' : 'is not empty' }}</span>
              </ng-container>
              <ng-template #editTemplate_firstNameIsEmpty>
                <select formControlName="firstNameIsEmpty"
                        [id]="'firstNamePattern-firstNameIsEmpty-' + idx"
                        class="select-custom d-inline-block"
                        [class.isInvalid]="formControl.hasError('matchingFullName') || methodIsDuplicated(idx)">
                  <option *ngFor="let item of _formConfigOptions('firstNameIsEmpty')" [ngValue]="item === 'is empty'">{{ item }}</option>
                </select>
              </ng-template>
              <span *ngIf="formControl.get('firstNameIsEmpty').value === false"> and <strong>has</strong> </span>

              <ul *ngIf="formControl.get('firstNameIsEmpty').value === false">
                <ng-container *ngFor="let patternControl of getAsFormArray(formControl.get('firstNamePattern')).controls; let jdx = index;">
                  <li [formGroup]="getAsGroup(patternControl)"
                      [class.mb-4]="(patternControl.get('text') && patternControl.get('text').errors) || patternControl.get('numberOfInstances').errors">
                        <span [style.visibility]="jdx <= 0 ? 'hidden' : 'visible'"
                              class="info-green pr-1">followed by </span>

                    <ng-container *ngIf="disabled; else editTemplate_atLeast">
                      <span class="info p-2">{{ patternControl.get('atLeast').value === true ? 'at least' : 'exactly' }}</span>
                    </ng-container>
                    <ng-template #editTemplate_atLeast>
                      <select formControlName="atLeast"
                              [id]="'firstNamePattern-atLeast-' + idx + '-' + jdx"
                              class="select-custom d-inline-block"
                              [class.isInvalid]="methodIsDuplicated(idx)">
                        <option *ngFor="let item of _formConfigOptions('atLeast')" [ngValue]="item === 'at least'">{{ item }}</option>
                      </select>
                    </ng-template>

                    <ng-container *ngIf="disabled; else editTemplate_numberOfInstances">
                      <span class="info p-2">{{ patternControl.get('numberOfInstances').value }}</span>
                    </ng-container>
                    <ng-template #editTemplate_numberOfInstances>
                      <span class="d-inline-block position-relative">
                        <app-control-messages class="position-absolute relative-control-message"
                                              [control]="patternControl.get('numberOfInstances')"></app-control-messages>
                        <input class="input-text-custom number d-inline-block"
                               [id]="'firstNamePattern-numberOfInstances-' + idx + '-' + jdx"
                               type="number"
                               formControlName="numberOfInstances"
                               [min]="_formConfig('numberOfInstances').min"
                               [max]="_formConfig('numberOfInstances').max"
                               [class.read-only]="disabled"
                               [class.isInvalid]="patternControl.get('numberOfInstances').errors || methodIsDuplicated(idx)"/>
                      </span>
                    </ng-template>
                    <span> instance(s) of </span>

                    <ng-container *ngIf="disabled; else editTemplate_instanceOptions">
                      <span *ngIf="patternControl.get('instanceOptions').value !== 'string'"
                            class="info p-2">{{ patternControl.get('instanceOptions').value }}</span>
                    </ng-container>
                    <ng-template #editTemplate_instanceOptions>
                      <select formControlName="instanceOptions"
                              [id]="'firstNamePattern-instanceOptions-' + idx + '-' + jdx"
                              class="select-custom d-inline-block"
                              [class.isInvalid]="methodIsDuplicated(idx)">
                        <option *ngFor="let item of _formConfigOptions('instanceOptions')" [value]="item">{{ item }}</option>
                      </select>
                    </ng-template>

                    <ng-container *ngIf="disabled; else editTemplate_text">
                      <span *ngIf="patternControl.get('text') && patternControl.get('instanceOptions').value === 'string'"
                            class="info p-2">{{ patternControl.get('text').value }}</span>
                    </ng-container>
                    <ng-template #editTemplate_text>
                      <span *ngIf="patternControl.get('text') && patternControl.get('instanceOptions').value === 'string'"
                            class="d-inline-block position-relative">
                        <app-control-messages class="position-absolute relative-control-message"
                                              [control]="patternControl.get('text')"></app-control-messages>
                        <input [id]="'firstNamePattern-text-' + idx + '-' + jdx"
                               class="input-text-custom d-inline-block"
                               formControlName="text"
                               [class.read-only]="disabled"
                               [class.isInvalid]="patternControl.get('text').errors || methodIsDuplicated(idx)"/>
                      </span>
                    </ng-template>

                    <span> in a row </span>

                    <a *ngIf="!disabled"
                       [hidden]="jdx <= 0"
                       class="menu-criteria pl-1"
                       href="javascript:void(0)"
                       (click)="deleteCustomPattern(idx, jdx, 'firstNamePattern')">
                      <i class="fa fa-trash" title="Delete this condition"></i>
                    </a>
                    <a *ngIf="!disabled"
                       [hidden]="jdx < getAsFormArray(formControl.get('firstNamePattern')).controls.length - 1"
                       class="menu-criteria pl-1"
                       href="javascript:void(0)"
                       (click)="addCustomPattern(idx, jdx + 1, 'firstNamePattern')">
                      <i class="fa fa-plus-square"></i>
                    </a>
                  </li>
                </ng-container>
              </ul>
            </li>

            <app-control-messages [control]="formControl"></app-control-messages>
          </ng-template>

          <ng-template #singleNamePattern>
            <li><span>and <strong>has </strong></span>
              <ul>
                <ng-container *ngFor="let patternControl of getAsFormArray(formControl.get('singleNamePattern')).controls; let jdx = index;">
                  <li [formGroup]="getAsGroup(patternControl)"
                      [class.mb-4]="(patternControl.get('text') && patternControl.get('text').errors) || patternControl.get('numberOfInstances').errors">
                        <span [style.visibility]="jdx <= 0 ? 'hidden' : 'visible'"
                              class="info-green pr-1">followed by </span>

                    <ng-container *ngIf="disabled; else editTemplate_atLeast">
                      <span class="info p-2">{{ patternControl.get('atLeast').value === true ? 'at least' : 'exactly' }}</span>
                    </ng-container>
                    <ng-template #editTemplate_atLeast>
                      <select formControlName="atLeast"
                              [id]="'singleNamePattern-atLeast-' + idx + '-' + jdx"
                              class="select-custom d-inline-block"
                              [class.isInvalid]="methodIsDuplicated(idx)">
                        <option *ngFor="let item of _formConfigOptions('atLeast')" [ngValue]="item === 'at least'">{{ item }}</option>
                      </select>
                    </ng-template>

                    <ng-container *ngIf="disabled; else editTemplate_numberOfInstances">
                      <span class="info p-2">{{ patternControl.get('numberOfInstances').value }}</span>
                    </ng-container>
                    <ng-template #editTemplate_numberOfInstances>
                      <span class="d-inline-block position-relative">
                        <app-control-messages class="position-absolute relative-control-message"
                                              [control]="patternControl.get('numberOfInstances')"></app-control-messages>
                        <input class="input-text-custom number d-inline-block"
                               [id]="'singleNamePattern-numberOfInstances-' + idx + '-' + jdx"
                               type="number"
                               formControlName="numberOfInstances"
                               [min]="_formConfig('numberOfInstances').min"
                               [max]="_formConfig('numberOfInstances').max"
                               [class.read-only]="disabled"
                               [class.isInvalid]="patternControl.get('numberOfInstances').errors || methodIsDuplicated(idx)"/>
                      </span>
                    </ng-template>
                    <span> instance(s) of </span>

                    <ng-container *ngIf="disabled; else editTemplate_instanceOptions">
                      <span *ngIf="patternControl.get('instanceOptions').value !== 'string'"
                            class="info p-2">{{ patternControl.get('instanceOptions').value }}</span>
                    </ng-container>
                    <ng-template #editTemplate_instanceOptions>
                      <select formControlName="instanceOptions"
                              [id]="'singleNamePattern-instanceOptions-' + idx + '-' + jdx"
                              class="select-custom d-inline-block"
                              [class.isInvalid]="methodIsDuplicated(idx)">
                        <option *ngFor="let item of _formConfigOptions('instanceOptions')" [value]="item">{{ item }}</option>
                      </select>
                    </ng-template>

                    <ng-container *ngIf="disabled; else editTemplate_text">
                      <span *ngIf="patternControl.get('text') && patternControl.get('instanceOptions').value === 'string'"
                            class="info p-2">{{ patternControl.get('text').value }}</span>
                    </ng-container>
                    <ng-template #editTemplate_text>
                      <span *ngIf="patternControl.get('text') && patternControl.get('instanceOptions').value === 'string'"
                            class="d-inline-block position-relative">
                        <app-control-messages class="position-absolute relative-control-message"
                                              [control]="patternControl.get('text')"></app-control-messages>
                        <input [id]="'singleNamePattern-text-' + idx + '-' + jdx"
                               class="input-text-custom d-inline-block"
                               formControlName="text"
                               [class.read-only]="disabled"
                               [class.isInvalid]="patternControl.get('text').errors || methodIsDuplicated(idx)"/>
                      </span>
                    </ng-template>

                    <span> in a row </span>

                    <a *ngIf="!disabled"
                       [hidden]="jdx <= 0"
                       class="menu-criteria pl-1"
                       href="javascript:void(0)"
                       (click)="deleteCustomPattern(idx, jdx, 'singleNamePattern')">
                      <i class="fa fa-trash" title="Delete this condition"></i>
                    </a>
                    <a *ngIf="!disabled"
                       [hidden]="jdx < getAsFormArray(formControl.get('singleNamePattern')).controls.length - 1"
                       class="menu-criteria pl-1"
                       href="javascript:void(0)"
                       (click)="addCustomPattern(idx, jdx + 1, 'singleNamePattern')">
                      <i class="fa fa-plus-square"></i>
                    </a>
                  </li>
                </ng-container>
              </ul>
            </li>
          </ng-template>
        </ul>

        <ul *ngIf="inputArray.hasError('paxNameMatching')
                  && inputArray.errors['paxNameMatching']['inclusive'] === formControl.get('inclusive').value
                  && inputArray.errors['paxNameMatching']['index'].length > 0
                  && inputArray.errors['paxNameMatching']['index'].indexOf(idx) === inputArray.errors['paxNameMatching']['index'].length - 1
                  && ['list', 'customPattern'].indexOf(formControl.get('matchingPattern').value) > -1">
          <li>
            <app-control-messages [control]="inputArray"></app-control-messages>
          </li>
        </ul>

        <a *ngIf="!disabled"
           class="menu-criteria pl-1"
           href="javascript:void(0)"
           (click)="deletePaxNameMatching(idx)">
          <i class="fa fa-trash" title="Delete this condition"></i>
        </a>
        <a *ngIf="!disabled"
           [hidden]="idx !== getLastControlIndex(formControl.get('inclusive').value) || exclusiveLimitReached"
           class="menu-criteria pl-1"
           href="javascript:void(0)"
           (click)="addPaxNameMatching(inputArray.controls.length, formControl.get('inclusive').value)">
          <i class="fa fa-plus-square" title="Add a date range"></i>
        </a>

        <ul *ngIf="inputArray.hasError('paxNameMatching')
                  && inputArray.errors['paxNameMatching']['inclusive'] === formControl.get('inclusive').value
                  && inputArray.errors['paxNameMatching']['index'].length > 0
                  && inputArray.errors['paxNameMatching']['index'].indexOf(idx) === inputArray.errors['paxNameMatching']['index'].length - 1
                  && ['list', 'customPattern'].indexOf(formControl.get('matchingPattern').value) < 0">
          <li>
            <app-control-messages [control]="inputArray"></app-control-messages>
          </li>
        </ul>
      </li>
    </ng-container>
  </ul>

  <input type="hidden" class="input-text-custom" [class.isInvalid]="!disabled
                            && inputArray.hasError('maxArrayLength')
                            && inputArray.errors['maxArrayLength']['inclusive'] === false" />
  <app-control-messages *ngIf="!disabled
                            && inputArray.hasError('maxArrayLength')
                            && inputArray.errors['maxArrayLength']['inclusive'] === false"
                        [override]="overrideMaxArrayLengthError()"
                        [control]="inputArray"></app-control-messages>
</div>
