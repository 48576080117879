import {FormGroup, ValidationErrors} from '@angular/forms';

export function MatchingFullNameValidator(control: FormGroup): ValidationErrors | null {
  if (control.get('nameType').value === 'fullName' && control.get('matchingPattern').value === 'customPattern') {
    if ((!control.get('firstNameIsEmpty') || control.get('firstNameIsEmpty').value === true)
      && (!control.get('lastNameIsEmpty') || control.get('lastNameIsEmpty').value === true)) {
      return {matchingFullName: true};
    }
  }
  return null;
}
