import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../services/auth.service';
import {IApiError} from '../../shared/model/api-error';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {ApiLogLevelEnum} from '../../shared/model/api-log-level.enum';
import {ApiMessage} from '../../shared/model/api-message';
import {ApiLog} from '../../shared/model/api-log';
import {JwtHelperService} from '@auth0/angular-jwt';
import uaParser from 'ua-parser-js';
import {ApiLogService} from '../services/api-log.service';
import {LogUtils} from '../utils/logging/log-utils';
import {DataStorageService} from '../services/data-storage.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private readonly options = {
    closeButton: true,
    progressBar: true,
    positionClass: 'toast-bottom-right'
  };

  constructor(private router: Router,
              private authService: AuthService,
              private dataStorage: DataStorageService,
              private notificationService: ToastrService,
              private jwtHelper: JwtHelperService,
              private apiLogService: ApiLogService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body && event.body.hasOwnProperty('apiMessages')) {
            const apiMessages: ApiMessage[] = event.body.apiMessages;
            this.notifyAll(apiMessages);
          }
        }

        return event;
      }),
      catchError(err => {
        let notifyUser = true;
        const token = this.dataStorage.getToken();
        const apiError: IApiError = this.parseApiError(err);
        let errorMessage = apiError.message;
        switch (err.status) {
          case 0:
            errorMessage = 'Service unavailable';
            break;
          case 401:
            this.authService.logoutUser();
            break;
          case 400:
            if (err.url.indexOf('/search') > -1
              && isNotNullOrUndefined(apiError)
              && apiError.message.indexOf('Elastic Search could not parse input') > -1) {
              (<any>$('#searchTipsModal')).modal('show');
              notifyUser = false;
            }
            break;
          case 404:
            const errorState = Object.assign({url: this.router.url}, err.error);
            this.router.navigate(['/404'], {state: errorState});
            notifyUser = false;
            break;
          case 408:
            if (isNotNullOrUndefined(token)) {
              this.postApiLog(this.buildApiLog(err.status, token));
            }
            break;
        }

        if (notifyUser) {
          this.notify(new ApiMessage(ApiLogLevelEnum.ERROR, errorMessage));
        }

        return throwError(apiError);
      })
    );
  }

  notifyAll(apiMessages: ApiMessage[]) {
    for (const apiMessage of apiMessages) {
      switch (apiMessage.logLevel) {
        case ApiLogLevelEnum.INFO:
          this.notificationService.info(apiMessage.message, apiMessage.logLevel, this.options);
          break;
        case ApiLogLevelEnum.WARN:
          this.notificationService.warning(apiMessage.message, apiMessage.logLevel, this.options);
          break;
        case ApiLogLevelEnum.SUCCESS:
          this.notificationService.success(apiMessage.message, apiMessage.logLevel, this.options);
          break;
        case ApiLogLevelEnum.ERROR:
          this.notificationService.error(apiMessage.message, apiMessage.logLevel,
            Object.assign({timeOut: 30000}, this.options));
          break;
      }
    }
  }

  notify(apiMessage: ApiMessage) {
    this.notifyAll([apiMessage]);
  }

  instanceOfApiError(object: any): boolean {
    return 'status' in object &&
      'error' in object &&
      'message' in object &&
      'path' in object &&
      'timestamp' in object;
  }

  private parseApiError(err: any) {
    if (this.instanceOfApiError(err.error)) {
      return err.error as IApiError;
    }

    return {
      status: err.status,
      error: err.status === 0 ? 'Service unavailable' : 'Error ' + err.status,
      message: err.error.message || err.statusText || 'Unknown error',
      path: err.url,
      timestamp: (new Date()).toISOString()
    } as IApiError;
  }

  private postApiLog(apiLog: ApiLog) {
    LogUtils.info(apiLog);
    this.apiLogService.postApiLog(apiLog).subscribe();
  }

  private buildApiLog(errorStatus: number, token: string) {
    const userAgentParser = uaParser(window.navigator.userAgent);
    return new ApiLog(errorStatus,
      this.jwtHelper.getTokenExpirationDate(token).toISOString(),
      new Date().toISOString(),
      userAgentParser.browser.name,
      userAgentParser.browser.version,
      userAgentParser.engine.name,
      userAgentParser.engine.version,
      userAgentParser.os.name,
      userAgentParser.os.version,
      userAgentParser.device.vendor,
      userAgentParser.device.model,
      userAgentParser.device.type,
      this.authService.getActiveCarrierCode());
  }

}
