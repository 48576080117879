import {SetUI} from '../models/set.ui';
import {DocumentTypeEnum} from '../utils/data/document-type-enum';
import {ProductCodeEnum} from '../utils/data/product-code-enum';

export const SET_UI_CONFIGURATION: SetUI[] = [
  {
    productCode: ProductCodeEnum.ATL,
    name: 'computationRuleConfiguration',
    title: 'Computation Rules',
    path: 'computation',
    setUI: 'atl-computation',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-help',
    load: true
  },
  {
    productCode: ProductCodeEnum.ATL,
    name: 'exepmtionRuleConfiguration',
    title: 'Exemption Rules',
    path: 'exemption',
    setUI: 'atl-exemption',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-ribbon',
    load: true
  },
  {
    productCode: ProductCodeEnum.ATL,
    name: 'extensionRuleConfiguration',
    title: 'Extension Rules',
    path: 'extension',
    setUI: 'atl-extension',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-stack',
    load: true
  },
  {
    productCode: ProductCodeEnum.ATL,
    name: 'ATLConfig',
    title: 'Product Configuration',
    path: 'configuration',
    setUI: 'atl-configuration',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'mdi mdi-wrench-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.ATL,
    name: 'compliance',
    title: 'Compliance Configuration',
    path: 'compliance',
    setUI: 'atl-compliance',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'mdi mdi-check-box-multiple-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.ATL,
    name: 'recalculation',
    title: 'Recalculation Configuration',
    path: 'recalculation',
    setUI: 'atl-recalculation',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'mdi mdi-atom',
    load: true
  },
  {
    productCode: ProductCodeEnum.ATL,
    name: 'internalControl',
    title: 'Internal Control Configuration',
    path: 'internal-control',
    setUI: 'atl-internal-control',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'mdi mdi-account-lock-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.DPP,
    name: 'dupePnrConfig',
    title: 'Product Configuration',
    path: 'configuration',
    setUI: 'dpp-configuration',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'mdi mdi-wrench-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.DPP,
    name: 'dupePnrActionRuleSet',
    title: 'Action Rules',
    path: 'action',
    setUI: 'dpp-action',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-help',
    load: true
  },
  {
    productCode: ProductCodeEnum.DPP,
    name: 'PNRExemptionRules',
    title: 'PNR Exemption Rules',
    path: 'pnr-exemption',
    setUI: 'dpp-pnr-exemption',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-ribbon',
    load: true
  },
  {
    productCode: ProductCodeEnum.DPP,
    name: 'passengerExemptionRules',
    title: 'Passenger Exemption Rules',
    path: 'passenger-exemption',
    setUI: 'dpp-passenger-exemption',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-ribbon',
    load: true
  },
  {
    productCode: ProductCodeEnum.DPP,
    name: 'internalControl',
    title: 'Internal Control Configuration',
    path: 'internal-control',
    setUI: 'dpp-internal-control',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'mdi mdi-account-lock-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.CHR,
    name: 'internalControl',
    title: 'Internal Control Configuration',
    path: 'internal-control',
    setUI: 'chr-internal-control',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'mdi mdi-account-lock-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.DPS,
    name: 'dupeSegConfig',
    title: 'Product Configuration',
    path: 'configuration',
    setUI: 'dps-configuration',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'mdi mdi-wrench-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.DPS,
    name: 'dupeSegActionRuleSet',
    title: 'Action Rules',
    path: 'action',
    setUI: 'dps-action',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-help',
    load: true
  },
  {
    productCode: ProductCodeEnum.DPS,
    name: 'PNRExemptionRules',
    title: 'PNR Exemption Rules',
    path: 'pnr-exemption',
    setUI: 'dps-pnr-exemption',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-help',
    load: true
  },
  {
    productCode: ProductCodeEnum.DPS,
    name: 'segmentExemptionRules',
    title: 'Segment Exemption Rules',
    path: 'segment-exemption',
    setUI: 'dps-segment-exemption',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-help',
    load: true
  },
  {
    productCode: ProductCodeEnum.DPS,
    name: 'internalControl',
    title: 'Internal Control Configuration',
    path: 'internal-control',
    setUI: 'dps-internal-control',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'mdi mdi-account-lock-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.FSN,
    name: 'fsnpConfig',
    title: 'Product Configuration',
    path: 'configuration',
    setUI: 'fsn-configuration',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'mdi mdi-wrench-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.FSN,
    name: 'whiteNameConfiguration',
    title: 'Allowlist Names Rules',
    path: 'allowlist-name',
    setUI: 'fsn-white-name',
    type: DocumentTypeEnum.RULE,
    icon: 'mdi mdi-check-circle-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.FSN,
    name: 'blackNameConfiguration',
    title: 'Blocklist Names Rules',
    path: 'blocklist-name',
    setUI: 'fsn-black-name',
    type: DocumentTypeEnum.RULE,
    icon: 'mdi mdi-cancel',
    load: true
  },
  {
    productCode: ProductCodeEnum.FSN,
    name: 'ficNameConfiguration',
    title: 'Fictitious & Suspicious Names Rules',
    path: 'fictitious-name',
    setUI: 'fsn-fic-name',
    type: DocumentTypeEnum.RULE,
    icon: 'mdi mdi-blur-radial',
    load: true
  },
  {
    productCode: ProductCodeEnum.FSN,
    name: 'PNRExemptionRules',
    title: 'PNR Exemption Rules',
    path: 'pnr-exemption',
    setUI: 'fsn-pnr-exemption',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-ribbon',
    load: true
  },
  {
    productCode: ProductCodeEnum.FSN,
    name: 'segmentExemptionRules',
    title: 'Segment Exemption Rules',
    path: 'segment-exemption',
    setUI: 'fsn-segment-exemption',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-ribbon',
    load: true
  },
  {
    productCode: ProductCodeEnum.FSN,
    name: 'errorHandlingRules',
    title: 'Error Handling Rules',
    path: 'error-handling',
    setUI: 'fsn-error-handling',
    type: DocumentTypeEnum.RULE,
    icon: 'mdi mdi-close-circle-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.FSN,
    name: 'internalControl',
    title: 'Internal Control Configuration',
    path: 'internal-control',
    setUI: 'fsn-internal-control',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'mdi mdi-account-lock-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.GTL,
    name: 'computationRuleConfiguration',
    title: 'Computation Rules',
    path: 'computation',
    setUI: 'gtl-computation',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-help',
    load: true
  },
  {
    productCode: ProductCodeEnum.GTL,
    name: 'exepmtionRuleConfiguration',
    title: 'Exemption Rules',
    path: 'exemption',
    setUI: 'gtl-exemption',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-ribbon',
    load: true
  },
  {
    productCode: ProductCodeEnum.GTL,
    name: 'extensionRuleConfiguration',
    title: 'Extension Rules',
    path: 'extension',
    setUI: 'gtl-extension',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-stack',
    load: true
  },
  {
    productCode: ProductCodeEnum.GTL,
    name: 'ATLConfig',
    title: 'Product Configuration',
    path: 'configuration',
    setUI: 'gtl-configuration',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'mdi mdi-wrench-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.GTL,
    name: 'compliance',
    title: 'Compliance Configuration',
    path: 'compliance',
    setUI: 'gtl-compliance',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'mdi mdi-check-box-multiple-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.GTL,
    name: 'recalculation',
    title: 'Recalculation Configuration',
    path: 'recalculation',
    setUI: 'gtl-recalculation',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'mdi mdi-atom',
    load: true
  },
  {
    productCode: ProductCodeEnum.GTL,
    name: 'agreementComplianceRuleSet',
    title: 'Agreement Compliance Rules',
    path: 'agreement',
    setUI: 'gtl-agreement-compliance',
    type: DocumentTypeEnum.RULE,
    icon: 'mdi mdi-check-circle-outline',
    load: false
  },
  {
    productCode: ProductCodeEnum.GTL,
    name: 'depositComplianceRuleSet',
    title: 'Deposit Compliance Rules',
    path: 'deposit',
    setUI: 'gtl-deposit-compliance',
    type: DocumentTypeEnum.RULE,
    icon: 'mdi mdi-check-circle-outline',
    load: false
  },
  {
    productCode: ProductCodeEnum.GTL,
    name: 'finalPaymentComplianceRuleSet',
    title: 'Final Payment Compliance Rules',
    path: 'final-payment',
    setUI: 'gtl-finalPayment-compliance',
    type: DocumentTypeEnum.RULE,
    icon: 'mdi mdi-check-circle-outline',
    load: false
  },
  {
    productCode: ProductCodeEnum.GTL,
    name: 'nameComplianceRuleSet',
    title: 'Name Compliance Rules',
    path: 'name',
    setUI: 'gtl-name-compliance',
    type: DocumentTypeEnum.RULE,
    icon: 'mdi mdi-check-circle-outline',
    load: false
  },
  {
    productCode: ProductCodeEnum.GTL,
    name: 'internalControl',
    title: 'Internal Control Configuration',
    path: 'internal-control',
    setUI: 'gtl-internal-control',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'mdi mdi-account-lock-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.HDG,
    name: 'detectionRules',
    title: 'Detection Rules',
    path: 'detection',
    setUI: 'hdg-detection',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-search',
    load: true
  },
  {
    productCode: ProductCodeEnum.HDG,
    name: 'actionRules',
    title: 'Action Rules',
    path: 'action',
    setUI: 'hdg-action',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-help',
    load: true
  },
  {
    productCode: ProductCodeEnum.HDG,
    name: 'pnrExemptionRules',
    title: 'PNR Exemption Rules',
    path: 'pnr-exemption',
    setUI: 'hdg-pnr-exemption',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-ribbon',
    load: true
  },
  {
    productCode: ProductCodeEnum.HDG,
    name: 'segmentExemptionRules',
    title: 'Segment Exemption Rules',
    path: 'segment-exemption',
    setUI: 'hdg-segment-exemption',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-ribbon',
    load: true
  },
  {
    productCode: ProductCodeEnum.HDG,
    name: 'passengerExemptionRules',
    title: 'Passenger Exemption Rules',
    path: 'passenger-exemption',
    setUI: 'hdg-passenger-exemption',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-ribbon',
    load: true
  },
  {
    productCode: ProductCodeEnum.HDG,
    name: 'productConfig',
    title: 'Product Configuration',
    path: 'configuration',
    setUI: 'hdg-configuration',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'mdi mdi-wrench-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.HDG,
    name: 'internalControl',
    title: 'Internal Control Configuration',
    path: 'internal-control',
    setUI: 'hdg-internal-control',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'mdi mdi-account-lock-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.NSM,
    name: 'messageFormats',
    title: 'Product Configuration',
    path: 'product-configuration',
    setUI: 'nsm-product-configuration',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'mdi mdi-wrench-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.NSM,
    name: 'noshowConfig',
    title: 'Downline Segment Configuration',
    path: 'downline-segment-configuration',
    setUI: 'nsm-configuration',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'mdi mdi-wrench-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.NSM,
    name: 'eticketConfigs',
    title: 'E-Ticket Configuration',
    path: 'eticket-configuration',
    setUI: 'nsm-eticket-configuration',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'mdi mdi-wrench-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.NSM,
    name: 'actionRules',
    title: 'Action Rules',
    path: 'action',
    setUI: 'nsm-action',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-help',
    load: true
  },
  {
    productCode: ProductCodeEnum.NSM,
    name: 'exclusionRules',
    title: 'PNR Exclusion Rules',
    path: 'pnr-exclusion',
    setUI: 'nsm-pnr-exclusion',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-ribbon',
    load: true
  },
  {
    productCode: ProductCodeEnum.NSM,
    name: 'segmentExclusionRules',
    title: 'Segment Exclusion Rules',
    path: 'segment-exclusion',
    setUI: 'nsm-segment-exclusion',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-ribbon',
    load: true
  },
  {
    productCode: ProductCodeEnum.NSM,
    name: 'secondaryActionRules',
    title: 'Secondary Action Rules',
    path: 'secondary-action',
    setUI: 'nsm-secondary-action',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-help',
    load: true
  },
  {
    productCode: ProductCodeEnum.NSM,
    name: 'eTicketActionRules',
    title: 'E-Ticket Coupon Update - Action Rules',
    path: 'eticket-action',
    setUI: 'nsm-eticket-action',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-help',
    load: false
  },
  {
    productCode: ProductCodeEnum.NSM,
    name: 'eTicketExclusionRules',
    title: 'E-Ticket Coupon Update - Exclusion Rules',
    path: 'eticket-exclusion',
    setUI: 'nsm-eticket-exclusion',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-ribbon',
    load: false
  },
  {
    productCode: ProductCodeEnum.NSM,
    name: 'eTicketRefundRules',
    title: 'E-Ticket Refund Rules',
    path: 'eticket-refund',
    setUI: 'nsm-eticket-refund',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-help',
    load: false
  },
  {
    productCode: ProductCodeEnum.NSM,
    name: 'eTicketRefundExclusionRules',
    title: 'E-Ticket Refund Exclusion Rules',
    path: 'eticket-refund-exclusion',
    setUI: 'nsm-eticket-refund-exclusion',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-ribbon',
    load: false
  },
  {
    productCode: ProductCodeEnum.NSM,
    name: 'errorHandlingRules',
    title: 'Error Handling Rules',
    path: 'error-handling',
    setUI: 'nsm-error-handling',
    type: DocumentTypeEnum.RULE,
    icon: 'mdi mdi-close-circle-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.TTT,
    name: 'computationRuleConfiguration',
    title: 'Computation Rules',
    path: 'computation',
    setUI: 'ttt-computation',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-help',
    load: true
  },
  {
    productCode: ProductCodeEnum.TTT,
    name: 'exepmtionRuleConfiguration',
    title: 'Exemption Rules',
    path: 'exemption',
    setUI: 'ttt-exemption',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-ribbon',
    load: true
  },
  {
    productCode: ProductCodeEnum.TTT,
    name: 'extensionRuleConfiguration',
    title: 'Extension Rules',
    path: 'extension',
    setUI: 'ttt-extension',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-stack',
    load: true
  },
  {
    productCode: ProductCodeEnum.TTT,
    name: 'ATLConfig',
    title: 'Product Configuration',
    path: 'configuration',
    setUI: 'ttt-configuration',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'mdi mdi-wrench-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.TTT,
    name: 'internalControl',
    title: 'Internal Control Configuration',
    path: 'internal-control',
    setUI: 'ttt-internal-control',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'mdi mdi-account-lock-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.WLT,
    name: 'waitlistGeneralConfig',
    title: 'Product Configuration',
    path: 'configuration',
    setUI: 'wlt-configuration',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'mdi mdi-wrench-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.WLT,
    name: 'actionRules',
    title: 'Action Rules',
    path: 'action',
    setUI: 'wlt-action',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-help',
    load: true
  },
  {
    productCode: ProductCodeEnum.WLT,
    name: 'pNRExclusionRules',
    title: 'PNR Exemption Rules',
    path: 'pnr-exemption',
    setUI: 'wlt-pnr-exemption',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-ribbon',
    load: true
  },
  {
    productCode: ProductCodeEnum.WLT,
    name: 'segmentExclusionRules',
    title: 'Segment Exemption Rules',
    path: 'segment-exemption',
    setUI: 'wlt-segment-exemption',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-ribbon',
    load: true
  },
  {
    productCode: ProductCodeEnum.CHR,
    name: 'churningGeneralConfig',
    title: 'Product Configuration',
    path: 'configuration',
    setUI: 'chr-configuration',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'mdi mdi-wrench-outline',
    load: true
  },
  {
    productCode: ProductCodeEnum.CHR,
    name: 'actionRules',
    title: 'Action Rules',
    path: 'action',
    setUI: 'chr-action',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-help',
    load: true
  },
  {
    productCode: ProductCodeEnum.CHR,
    name: 'pNRExemptionRules',
    title: 'Exemption Rules',
    path: 'exemption',
    setUI: 'chr-exemption',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-ribbon',
    load: true
  },
  {
    name: 'ticketRule',
    title: 'Ticket Trust Rules',
    path: 'ticket-trust',
    setUI: 'ticket-trust',
    type: DocumentTypeEnum.RULE,
    icon: 'fi-tag',
    load: true
  },
  {
    name: 'customerDetails',
    title: 'General Configuration',
    path: 'general-settings',
    setUI: 'general-settings',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: '',
    load: true
  },
  {
    name: 'odConfiguration',
    title: 'General Configuration',
    path: 'general-settings',
    setUI: 'general-settings',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: '',
    load: true
  },
  {
    name: 'listOfInvalidTickets',
    title: 'Invalid Ticket List',
    path: 'invalid-ticket',
    setUI: 'invalid-ticket',
    type: DocumentTypeEnum.CONFIGURATION,
    icon: 'fi-delete',
    load: true
  },
  {
    name: 'business-data',
    title: 'Business Data List',
    path: 'business-data',
    setUI: 'business-data',
    type: DocumentTypeEnum.BUSINESS_DATA,
    icon: 'fi-paper',
    load: true
  }
];
