import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CheckboxGroupInputComponent} from './components/form/input/checkbox/group/checkbox-group-input.component';
import {RouterModule} from '@angular/router';
import {HeaderProductComponent} from './components/product/header-product.component';
import {CardRuleComponent} from './components/product/card-rule.component';
import {CardParameterComponent} from './components/product/card-parameter.component';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IndexProductComponent} from './components/product/index-product.component';
import {ReorderInputComponent} from './components/form/input/reorder/reorder-input.component';
import {ControlMessagesComponent} from './components/form/control-messages.component';
import {FormConfigurationPipe} from '../core/pipes/form-configuration.pipe';
import {FeatureOnDirective} from './directives/feature-on.directive';
import {FeatureOffDirective} from './directives/feature-off.directive';
import {FilterPipe} from '../core/pipes/filter.pipe';
import {InternalControlComponent} from './components/internal-control/internal-control.component';
import {ConfirmationDialogService} from './components/modal/service/confirm-modal.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmModalComponent} from './components/modal/confirm-modal.component';
import {NotFoundComponent} from './components/not-found.component';
import {AsFormGroupPipe} from '../core/pipes/as-form-group.pipe';
import {ProductFeatureOnDirective} from './directives/product-feature-on.directive';
import {ProductFeatureOffDirective} from './directives/product-feature-off.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule
  ],
  declarations: [
    CardParameterComponent,
    CardRuleComponent,
    HeaderProductComponent,
    CheckboxGroupInputComponent,
    IndexProductComponent,
    ReorderInputComponent,
    ControlMessagesComponent,
    FormConfigurationPipe,
    FilterPipe,
    FeatureOnDirective,
    FeatureOffDirective,
    ProductFeatureOnDirective,
    ProductFeatureOffDirective,
    InternalControlComponent,
    ConfirmModalComponent,
    NotFoundComponent,
    AsFormGroupPipe
  ],
  exports: [
    RouterModule,
    CardParameterComponent,
    CardRuleComponent,
    HeaderProductComponent,
    CheckboxGroupInputComponent,
    IndexProductComponent,
    ReorderInputComponent,
    ControlMessagesComponent,
    FormConfigurationPipe,
    FilterPipe,
    FeatureOnDirective,
    FeatureOffDirective,
    ProductFeatureOnDirective,
    ProductFeatureOffDirective,
    InternalControlComponent,
    ConfirmModalComponent,
    NotFoundComponent,
    AsFormGroupPipe
  ], providers:
    [ConfirmationDialogService],
  entryComponents: [ConfirmModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
