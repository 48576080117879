import {Injectable} from '@angular/core';
import {AppConfigService} from '../../app-config.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {

  constructor(private appConfig: AppConfigService) {}

  featureOff(featureName: string) {
    // Read the value from the config service
    if (this.appConfig.config.features.hasOwnProperty(featureName)) {
      return !this.appConfig.config.features[featureName];
    }
    return true; // if feature not found, default to turned off
  }

  featureOn(featureName: string) {
    return !this.featureOff(featureName);
  }

  productFeatureOff(featureName: string, productCode: string) {
     // Read the value from the config service
     if (this.appConfig.config.productFeatures.hasOwnProperty(productCode)) {
      const productFeature = this.appConfig.config.productFeatures[productCode];
      if (productFeature.hasOwnProperty(featureName)) {
        return ! productFeature[featureName];
      }
    } else {
      return false;
    }
    return true; // if feature not found, default to turned off
  }

  productFeatureOn(featureName: string, productCode: string) {
    return !this.productFeatureOff(featureName, productCode);
  }
}
