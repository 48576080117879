import {Component, Input, OnInit} from '@angular/core';
import {ProductUI} from '../../../core/models/product.ui';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-internal-control',
  template: `
    <div class="row" [formGroup]="internalControl">
      <div class="col-6 card-box">
        <div *ngIf="internalControl.get('shadowModeControl')" class="criterion section_config">
          {{ 'shadowModeControl' | formConfiguration }}
          <span style="padding-left:5px;" class="checkbox checkbox-primary border-primary">
            <input id="shadowModeControl"
                   formControlName="shadowModeControl"
                   type="checkbox"
                   [class.read-only]="disabled"
                   [class.isInvalid]="internalControl.get('shadowModeControl').errors"/>
            <label for="shadowModeControl" class="no-margin">&nbsp;</label>
          </span>
          <app-control-messages [control]="internalControl.get('shadowModeControl')"></app-control-messages>
        </div>

        <div *ngIf="internalControl.get('credentialName')" class="criterion section_config">
          {{ 'credentialName' | formConfiguration }}
          <input class="input-text-custom"
                 formControlName="credentialName"
                 [class.read-only]="disabled"
                 [class.isInvalid]="internalControl.get('credentialName').errors"/>
          <app-control-messages [control]="internalControl.get('credentialName')"></app-control-messages>
        </div>

        <div *ngIf="internalControl.get('environment')" class="criterion section_config">
          {{ 'environment' | formConfiguration }}
          <span class="info"
            [class.isInvalid]="internalControl.get('environment').errors"
          >{{ internalControl.get('environment').value }}</span>
          <app-control-messages [control]="internalControl.get('environment')"></app-control-messages>
        </div>

        <div *ngIf="internalControl.get('fakeCarrier')" class="criterion section_config">
          {{ 'fakeCarrier' | formConfiguration }}
          <input class="input-text-custom"
                 formControlName="fakeCarrier"
                 [class.read-only]="disabled"
                 [class.isInvalid]="internalControl.get('fakeCarrier').errors"/>
          <app-control-messages [control]="internalControl.get('fakeCarrier')"></app-control-messages>
        </div>

        <div *ngIf="internalControl.get('optimizeRules')" class="criterion section_config">
          {{ 'optimizeRules' | formConfiguration }}
          <span style="padding-left:5px;" class="checkbox checkbox-primary border-primary">
            <input id="optimizeRules"
                   formControlName="optimizeRules"
                   type="checkbox"
                   [class.read-only]="disabled"
                   [class.isInvalid]="internalControl.get('optimizeRules').errors"/>
            <label for="optimizeRules" class="no-margin">&nbsp;</label>
          </span>
          <app-control-messages [control]="internalControl.get('optimizeRules')"></app-control-messages>
        </div>

      </div>

      <div class="col-6 card-box">
        <div *ngIf="internalControl.get('opcsExpected')" class="criterion section_config">
          {{ 'opcsExpected' | formConfiguration }}
          <span style="padding-left:5px;" class="checkbox checkbox-primary border-primary">
            <input id="opcsExpected"
                   formControlName="opcsExpected"
                   type="checkbox"
                   [class.read-only]="disabled"
                   [class.isInvalid]="internalControl.get('opcsExpected').errors"/>
            <label for="opcsExpected" class="no-margin">&nbsp;</label>
          </span>
          <app-control-messages [control]="internalControl.get('opcsExpected')"></app-control-messages>
        </div>

        <div *ngIf="internalControl.get('minOpcPercentage')" class="criterion section_config">
          {{ 'minOpcPercentage' | formConfiguration }}
          <input class="input-text-custom number"
                 type="number"
                 formControlName="minOpcPercentage"
                 min="0"
                 max="100"
                 [class.read-only]="disabled"
                 [class.isInvalid]="internalControl.get('minOpcPercentage').errors"/>
          <app-control-messages [control]="internalControl.get('minOpcPercentage')"></app-control-messages>
        </div>

        <div *ngIf="internalControl.get('maxOpcPercentage')" class="criterion section_config">
          {{ 'maxOpcPercentage' | formConfiguration }}
          <input class="input-text-custom number"
                 type="number"
                 formControlName="maxOpcPercentage"
                 min="1"
                 max="100"
                 [class.read-only]="disabled"
                 [class.isInvalid]="internalControl.get('maxOpcPercentage').errors"/>
          <app-control-messages [control]="internalControl.get('maxOpcPercentage')"></app-control-messages>
        </div>

        <div *ngIf="internalControl.get('maxBtfHourlyRate')" class="criterion section_config">
          {{ 'maxBtfHourlyRate' | formConfiguration }}
          <input class="input-text-custom number"
                 type="number"
                 formControlName="maxBtfHourlyRate"
                 [class.read-only]="disabled"
                 [class.isInvalid]="internalControl.get('maxBtfHourlyRate').errors"/>
          <app-control-messages [control]="internalControl.get('maxBtfHourlyRate')"></app-control-messages>
        </div>

        <div *ngIf="internalControl.get('createSdsNamePlusBookingSourceBlockingKey')" class="criterion section_config">
          {{ 'createSdsNamePlusBookingSourceBlockingKey' | formConfiguration }}
          <span style="padding-left:5px;" class="checkbox checkbox-primary border-primary">
            <input id="createSdsNamePlusBookingSourceBlockingKey"
                   formControlName="createSdsNamePlusBookingSourceBlockingKey"
                   type="checkbox"
                   [class.read-only]="disabled"
                   [class.isInvalid]="internalControl.get('createSdsNamePlusBookingSourceBlockingKey').errors"/>
            <label for="createSdsNamePlusBookingSourceBlockingKey" class="no-margin">&nbsp;</label>
          </span>
          <app-control-messages [control]="internalControl.get('createSdsNamePlusBookingSourceBlockingKey')"></app-control-messages>
        </div>

        <div *ngIf="internalControl.get('createSdsNamePlusDobBlockingKey')" class="criterion section_config">
          {{ 'createSdsNamePlusDobBlockingKey' | formConfiguration }}
          <span style="padding-left:5px;" class="checkbox checkbox-primary border-primary">
            <input id="createSdsNamePlusDobBlockingKey"
                   formControlName="createSdsNamePlusDobBlockingKey"
                   type="checkbox"
                   [class.read-only]="disabled"
                   [class.isInvalid]="internalControl.get('createSdsNamePlusDobBlockingKey').errors"/>
            <label for="createSdsNamePlusDobBlockingKey" class="no-margin">&nbsp;</label>
          </span>
          <app-control-messages [control]="internalControl.get('createSdsNamePlusDobBlockingKey')"></app-control-messages>
        </div>

        <div *ngIf="internalControl.get('settlingTime')" class="criterion section_config">
          {{ 'settlingTime' | formConfiguration }}
          <input class="input-text-custom number"
                 type="number"
                 formControlName="settlingTime"
                 min="0"
                 [class.read-only]="disabled"
                 [class.isInvalid]="internalControl.get('settlingTime').errors"/>
          <app-control-messages [control]="internalControl.get('settlingTime')"></app-control-messages>
        </div>
      </div>
    </div>
  `
})
export class InternalControlComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() product: ProductUI;
  @Input() disabled: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

  get internalControl(): FormGroup {
    return this.formGroup.get('internalControl') as FormGroup;
  }
}
