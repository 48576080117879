import {FormGroup, FormBuilder, FormArray, FormControl, AbstractControl, Validators} from '@angular/forms';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Field} from '../models/field';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {ptdRangeValidator} from '../../../../shared/components/form/validators/ptd-range.validator';

@Component({
  selector: 'app-ptd-range-criterion',
  template: `
  <div [formGroup]="group" class="d-inline-block">
    <div [ngClass]="{'d-inline-block': idx === 0}" *ngFor="let formControl of inputArray.controls; let idx = index;">
      <div class="d-inline-block" [formGroup]="getAsGroup(formControl)">
        <ng-container *ngIf="state === 'info'; else editTemplate">
          <span class="fromPtdField info">{{ formControl.get(fromJsonKey).value }}</span>
          <span> days to </span>
          <span class="toPtdField info">{{ formControl.get(toJsonKey).value }}</span>
          <span> days</span>
        </ng-container>

        <ng-template #editTemplate>
          <input class="input-text-custom number"
                 type="number"
                 [formControlName]="fromJsonKey"
                 [id]="fromJsonKey + '-' + idx"
                 (focus)="onFocus(idx, fromJsonKey)"
                 (focusout)="manageRange(idx)"
                 (blur)="manageRange(idx)"
                 [min]="minPtdRanges[idx]"
                 [max]="maxPtdRanges[idx]"
                 autocomplete="off"
                 [class.isInvalid]="formControl.get(fromJsonKey).errors || (inputArray.hasError('ptdRange') && inputArray.errors['ptdRange'].index === idx)" />

          <span class="info"> days to </span>

          <input class="input-text-custom number"
                 type="number"
                 [formControlName]="toJsonKey"
                 [id]="toJsonKey + '-' + idx"
                 (focus)="onFocus(idx, toJsonKey)"
                 (focusout)="manageRange(idx)"
                 (blur)="manageRange(idx)"
                 [min]="minPtdRanges[idx]"
                 [max]="maxPtdRanges[idx]"
                 autocomplete="off"
                 [class.isInvalid]="formControl.get(toJsonKey).errors || (inputArray.hasError('ptdRange') && inputArray.errors['ptdRange'].index === idx)" />

          <span class="info"> days</span>

          <span [hidden]="idx === inputArray.controls.length - 1" class="info-green pl-1"> or</span>
          <a [hidden]="idx === 0" class="menu-criteria pl-1" href="javascript:void(0)" (click)="deletePtdRange(idx)">
            <i class="fa fa-trash" title="Delete this condition"></i>
          </a>
          <a [hidden]="idx !== inputArray.controls.length - 1 || itemsLimitReached" class="menu-criteria pl-1" href="javascript:void(0)" (click)="addPtdRange(idx + 1)">
            <i class="fa fa-plus-square" title="Add a date range"></i>
          </a>
        </ng-template>
      </div>
    </div>
  </div>
  `,
  styles: []
})

export class PtdRangeComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() field: Field;
  @Input() state: string;
  @Output() invalid = new EventEmitter<any>();

  public readonly fromJsonKey = 'from';
  public readonly toJsonKey = 'to';
  minPtdRanges: number[] = [];
  maxPtdRanges: number[] = [];
  minPtdRange = 0;
  maxPtdRange = 999;
  itemsLimitReached = false;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.initPtdRange();

    if (isNotNullOrUndefined(this.field)) {
      this.minPtdRange = this.field.min;
      this.maxPtdRange = this.field.max;

      this.checkPtdRangeLimits();
    }
  }

  initPtdRange() {
    const value = this.group.get(this.field.name).value;
    this.group.setControl(this.field.name, this.fb.array([], [ptdRangeValidator]));
    if (value && value.length > 0) {
      value.forEach((ptd, index) => {
        const from = ptd[this.fromJsonKey] >= 0 ? ptd[this.fromJsonKey] : '';
        const to = ptd[this.toJsonKey] >= 0 ? ptd[this.toJsonKey] : '';
        this.addPtdRange(index, from, to);
      });
    } else {
      this.addPtdRange(0, 0, 0);
    }

    this.parseValidation();
  }

  addPtdRange(index: number, from?: number, to?: number) {
    if (index > 0 && !isNotNullOrUndefined(from)) {
      from = this.getPtdValue(index - 1, this.toJsonKey) + 1;
      to = from;
    }

    this.inputArray.push(
      this.fb.group({
        [this.fromJsonKey]: this.fb.control(from,
          [Validators.required, Validators.min(this.minPtdRange), Validators.max(this.maxPtdRange)]),
        [this.toJsonKey]: this.fb.control(to,
          [Validators.required, Validators.min(this.minPtdRange), Validators.max(this.maxPtdRange)])
      })
    );

    this.manageRange(index);
  }

  deletePtdRange(index: number) {
    this.inputArray.removeAt(index);
    this.checkPtdRangeLimits();
  }

  parseValidation() {
    const error = { field: this.field.name , message : '' };

    if (this.group.invalid) {
      if (this.inputArray.hasError('ptdRange')) {
        error.message = 'Please check for overlapping ranges';
      }

      this.inputArray.controls.forEach(control => {
        if (control.get(this.fromJsonKey).hasError('min') || control.get(this.toJsonKey).hasError('min')) {
          error.message = 'Enter valid start days value for PTD';
        }
        if (control.get(this.fromJsonKey).hasError('max') || control.get(this.toJsonKey).hasError('max')) {
          error.message = 'Each value in range should be less than or equal to ' + this.maxPtdRange;
        }
        if (control.get(this.fromJsonKey).hasError('required') || control.get(this.toJsonKey).hasError('required')) {
          error.message = 'All values are required';
        }
      });
    }

    this.invalid.emit(error);
  }

  get inputArray(): FormArray {
    return this.group.get(this.field.name) as FormArray;
  }

  getAsGroup(control: AbstractControl): FormGroup {
    return control as FormGroup;
  }

  onFocus(index: number, control: string) {
    this.manageRange(index);
  }

  manageRange(index: number) {
    let prevTo = 0;
    let nextFrom = 0;

    if (index > 0) {
      prevTo = this.getPtdValue(index - 1, this.toJsonKey);
      prevTo = prevTo + 1;
    }

    if (index < this.inputArray.controls.length - 1) {
      nextFrom = this.getPtdValue(index + 1, this.fromJsonKey);
      nextFrom = nextFrom - 1;
    }

    if (index === this.inputArray.controls.length - 1) {
      nextFrom = this.field.max;
    }

    this.minPtdRanges[index] = prevTo;
    this.maxPtdRanges[index] = nextFrom;

    this.inputArray.controls[index].get(this.fromJsonKey).setValidators([Validators.required, Validators.min(prevTo), Validators.max(nextFrom)]);
    this.inputArray.controls[index].get(this.toJsonKey).setValidators([Validators.required, Validators.min(prevTo), Validators.max(nextFrom)]);

    let currentFrom = this.getPtdValue(index, this.fromJsonKey);

    if (currentFrom < prevTo || currentFrom > nextFrom) {
      this.setPtdValue(index, this.fromJsonKey, prevTo);
    }

    currentFrom = this.getPtdValue(index, this.fromJsonKey);
    const currentTo = this.getPtdValue(index, this.toJsonKey);

    if (currentTo < currentFrom) {
      this.setPtdValue(index, this.toJsonKey, currentFrom);
    } else if (currentTo > nextFrom || currentTo < prevTo) {
      this.setPtdValue(index, this.toJsonKey, nextFrom);
    }

    this.checkPtdRangeLimits();
    this.parseValidation();
  }

  getPtdValue(index: number, name: string): number {
    return this.inputArray.controls[index].get(name).value;
  }

  getPtdControl(index: number, name: string): number {
    return this.inputArray.controls[index].get(name).value;
  }

  setPtdValue(index: number, name: string, value: number) {
    const ptdRange = this.inputArray.controls[index] as FormGroup;
    if (name === this.toJsonKey && value >= this.maxPtdRange) {
      ptdRange.get(name).setValue(this.maxPtdRange);
    } else {
      ptdRange.get(name).setValue(value);
    }
  }

  checkPtdRangeLimits() {
    let currentMaxPtd = 0;
    this.inputArray.controls
      .filter(control => control.get(this.toJsonKey))
      .map(control => control.get(this.toJsonKey).value)
      .reduce((prev, cur) => currentMaxPtd = prev > cur ? prev : cur, currentMaxPtd);

    this.itemsLimitReached = currentMaxPtd >= this.maxPtdRange;
  }
}
