import {Component, OnInit} from '@angular/core';
import {LogUtils} from './core/utils/logging/log-utils';
import {AuthService} from './core/services/auth.service';
import {IdleTimeoutService} from './core/services/idle-timeout.service';
import {ConfirmationDialogService} from './shared/components/modal/service/confirm-modal.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {DataStorageService} from './core/services/data-storage.service';
import { NavigationEnd, Router } from '@angular/router';
declare const statistic: any;
@Component({
  selector: 'app-root',
  template: `
    <app-loader></app-loader>
    <app-header></app-header>
    <div class="roadrunner-container container-fluid mb-3">
      <router-outlet></router-outlet>
    </div>
    <app-footer class="footer"></app-footer>

    <div *ngIf="timerHasRunOut === true"
         class="text-danger position-absolute font-weight-bold"
         style="top: 0; left: 50%; transform: translate(-50%, 0);"
    >{{ fixedExpirationMessage }}</div>
  `
})
export class AppComponent implements OnInit {
  private static TOKEN_VALIDITY = 60 * 60 * 1000;
  private static MS_BEFORE_SHOWING_ALERT = 5 * 60 * 1000;

  public title = 'roadrunner-ui';
  public fixedExpirationMessage = '';
  public timerHasRunOut = false;

  constructor(private authService: AuthService,
              private dataStorage: DataStorageService,
              private jwtToken: JwtHelperService,
              private router: Router,
              private idleTimeoutService: IdleTimeoutService,
              private dialogSvc: ConfirmationDialogService) {
    try {
      //Adding for Matomo PoC
      router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          statistic(window.location.pathname);
        }
      });
      this.authService.isUserAuthenticated();
    } catch (e) {
      LogUtils.error('An error occurred during the process of authentication. Please contact your administrator', e);
      LogUtils.throw('Aborting...');
    }
  }

  ngOnInit() {
    this.authService.isUserLoggedInEventEmitter.subscribe(value => {
      if (value === true && !this.timerHasRunOut) {
        this.idleTimeoutService.startTimer();
      } else {
        this.idleTimeoutService.stopTimer();
      }
    });

    this.idleTimeoutService.timeoutExpired.subscribe(() => {
      const tokenExpirationDate = this.jwtToken.getTokenExpirationDate(this.dataStorage.getToken());
      const currentDate = new Date();
      const diff = tokenExpirationDate.getTime() - currentDate.getTime();

      if (diff > AppComponent.MS_BEFORE_SHOWING_ALERT) {
        this.timerHasRunOut = false;
        this.idleTimeoutService.resetTimer();
      } else {
        this.fixedExpirationMessage = `Your session will expire at ${tokenExpirationDate.toLocaleTimeString()}`;
        this.timerHasRunOut = true;
        this.idleTimeoutService.stopTimer();
        // TODO Because of the variable substitution needed in this modal,
        //      the message cannot be added to the modal component. We might
        //      to design a generic component for messages in the UI or so
        this.dialogSvc.confirm('Session Expiration',
          this.fixedExpirationMessage + '. Please save your work.'
          , true
          , true);

        // Reset the flag that displays the session expiration message
        setTimeout(() => this.timerHasRunOut = false, diff);

        // Start timer again for if other tabs are open and untouched
        //   Will show the alert modal if a session has been re-opened in a different tab
        //   Will NOT show anything if there is no active session
        setTimeout(() => {
            if (this.authService.isUserLoggedIn()) {
              this.idleTimeoutService.startTimer();
            }
          },
          AppComponent.TOKEN_VALIDITY - AppComponent.MS_BEFORE_SHOWING_ALERT
        );
      }
    });
  }
}
