import {FormGroup, FormBuilder, FormArray, AbstractControl, FormControl, Validators} from '@angular/forms';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Field} from '../models/field';
import {FormUtils} from '../../../../core/utils/form/form-utils';
import {DataUtils} from '../../../../core/utils/data/data-utils';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {RegexUtils} from '../../../../core/utils/regex-utils';
import {ConditionalValidator} from '../../../../shared/components/form/validators/conditional.validator';
import {PaxNameMatchingValidator} from '../../../../shared/components/form/validators/pax-name-matching.validator';
import {MatchingFullNameValidator} from '../../../../shared/components/form/validators/matching-full-name.validator';
import {pairwise, startWith} from 'rxjs/operators';
import {ArrayValidators} from '../../../../shared/components/form/validators/array.validators';

@Component({
  selector: 'app-pax-name-matching',
  templateUrl: './pax-name-matching.component.html',
  styles: [
    'ul { list-style-type: none; list-style-position: inside; }',
    'span.pax-name-matching-method-prefix { min-width: 40px; text-align: right; }',
    '.relative-control-message { top: 100%; white-space: nowrap; }'
  ]
})

export class PaxNameMatchingComponent implements OnInit {

  static readonly matchingTypeText = 'MatchingType';
  static readonly FORM_DEFINITION = {
    paxNameMatching: {
      inclusive: {
        type: 'boolean',
        default: true
      },
      nameType: {
        default: 'firstName',
        options: {
          firstName: 'first name',
          lastName: 'last name',
          firstOrLast: 'first name or last name',
          fullName: 'full name'
        }
      },
      matchingMethod: {
        default: 'exact',
        options: {
          exact: 'matches exactly',
          contain: 'contains'
        }
      },
      firstNameExactMatchingType: {
        default: 'empty',
        options: {
          empty: 'empty',
          lastName: 'last name',
          salutation: 'salutation only',
          allLettersRepeated: 'all letters repeated at least 3 times',
          partialLettersRepeated: 'any letter repeated at least 3 times but not all',
          allConsonantsSingleName: 'all consonants',
          allVowelsSingleName: 'all vowels',
          list: 'predefined names',
          customPattern: 'custom pattern'
        }
      },
      lastNameExactMatchingType: {
        default: 'empty',
        options: {
          empty: 'empty',
          firstName: 'first name',
          salutation: 'salutation only',
          firstNameSalutation: 'first name with salutation',
          allLettersRepeated: 'all letters repeated at least 3 times',
          partialLettersRepeated: 'any letter repeated at least 3 times but not all',
          allConsonantsSingleName: 'all consonants',
          allVowelsSingleName: 'all vowels',
          list: 'predefined names',
          customPattern: 'custom pattern'
        }
      },
      firstNameContainMatchingType: {
        default: 'list',
        options: {
          lastName: 'last name',
          list: 'predefined names',
          customPattern: 'custom pattern'
        }
      },
      lastNameContainMatchingType: {
        default: 'list',
        options: {
          firstName: 'first name',
          firstNameSalutation: 'first name with salutation',
          list: 'predefined names',
          customPattern: 'custom pattern'
        }
      },
      firstOrLastExactMatchingType: {
        default: 'list',
        options: {
          allLettersRepeated: 'all letters repeated at least 3 times',
          partialLettersRepeated: 'any letter repeated at least 3 times but not all',
          allConsonantsSingleName: 'all consonants',
          allVowelsSingleName: 'all vowels',
          list: 'predefined names',
          customPattern: 'custom pattern'
        }
      },
      firstOrLastContainMatchingType: {
        default: 'list',
        options: {
          list: 'predefined names',
          customPattern: 'custom pattern'
        }
      },
      fullNameExactMatchingType: {
        default: 'list',
        options: {
          allSame: 'all same characters',
          allLettersRepeated: 'all letters repeated at least 3 times',
          partialLettersRepeated: 'any letter repeated at least 3 times but not all',
          allConsonantsFullName: 'all consonants',
          allVowelsFullName: 'all vowels',
          list: 'predefined names',
          customPattern: 'custom pattern'
        }
      },
      fullNameContainMatchingType: {
        default: 'customPattern',
        options: {
          customPattern: 'custom pattern'
        }
      },
      firstNameIsEmpty: {
        default: false,
        type: 'boolean',
        options: ['is empty', 'is not empty']
      },
      lastNameIsEmpty: {
        default: false,
        type: 'boolean',
        options: ['is empty', 'is not empty']
      },
      atLeast: {
        default: true,
        type: 'boolean',
        options: ['at least', 'exactly']
      },
      numberOfInstances: {
        default: 1,
        type: 'integer',
        min: 1,
        max: 10
      },
      instanceOptions: {
        default: 'any character',
        options: [
          'any character',
          'string',
          'all consonants',
          'all vowels'
        ]
      }
    }
  };

  @Input() group: FormGroup;
  @Input() field: Field;
  @Input() state: string;
  @Output() invalid = new EventEmitter<any>();

  disabled = true;
  inclusiveLimitReached = false;
  exclusiveLimitReached = false;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.disabled = this.state === 'info';
    this.initPaxNameMatching();
    this.updateMethodsLimit();
  }

  initPaxNameMatching() {
    const value = this.group.get(this.field.name).value;
    this.group.setControl(this.field.name, this.fb.array([], [
      PaxNameMatchingValidator,
      ArrayValidators.maxLength(ArrayValidators.NAME_MATCHING_METHODS_LIMIT, this.filterInclusive, {inclusive: true}),
      ArrayValidators.maxLength(ArrayValidators.NAME_MATCHING_METHODS_LIMIT, this.filterExclusive, {inclusive: false})
    ]));

    if (this.disabled) {
      this.group.disable();
    }

    if (value && value.length > 0) {
      value.forEach((pnm, index) => {
        this.addPaxNameMatching(index, pnm['inclusive'], pnm);
      });
    } else {
      this.addPaxNameMatching(0);
    }
  }

  addPaxNameMatching(index: number, inclusive = true, data?: {}) {
    let formGroup: FormGroup;
    const isNewMethod = !isNotNullOrUndefined(data) || Object.keys(data).length < 4;
    if (isNewMethod) {
      formGroup = this.buildNewFormGroup(inclusive);
    } else {
      formGroup = this.fb.group({
        inclusive: inclusive,
        nameType: data['nameType'],
        matchingMethod: data['matchingMethod'],
        matchingPattern: data['matchingPattern']
      });
    }
    formGroup.setValidators([MatchingFullNameValidator]);

    formGroup.get('nameType').setValidators([Validators.required]);
    formGroup.get('matchingMethod').setValidators([Validators.required]);

    if (formGroup.get('matchingPattern').value === 'list') {
      formGroup.addControl('list', this.fb.array([]));
    }

    if (formGroup.get('matchingPattern').value === 'customPattern') {
      if (formGroup.get('nameType').value === 'fullName') {
        formGroup.addControl('firstNameIsEmpty', this.fb.control(isNewMethod ? this._formConfigDefault('firstNameIsEmpty') : data['firstNameIsEmpty'] === true));
        formGroup.addControl('lastNameIsEmpty', this.fb.control(isNewMethod ? this._formConfigDefault('lastNameIsEmpty') : data['lastNameIsEmpty'] === true));

        formGroup.addControl('firstNamePattern', this.fb.array([]));
        formGroup.addControl('lastNamePattern', this.fb.array([]));

        formGroup.get('lastNameIsEmpty').valueChanges.pipe(pairwise()).subscribe(([prev, next]) => {
          if (next === true) {
            formGroup.removeControl('lastNamePattern');
          } else {
            formGroup.addControl('lastNamePattern', this.fb.array([]));
            this.addCustomPattern(index, 0, 'lastNamePattern');
          }
        });

        formGroup.get('firstNameIsEmpty').valueChanges.pipe(pairwise()).subscribe(([prev, next]) => {
          if (next === true) {
            formGroup.removeControl('firstNamePattern');
          } else {
            formGroup.addControl('firstNamePattern', this.fb.array([]));
            this.addCustomPattern(index, 0, 'firstNamePattern');
          }
        });
      } else {
        formGroup.addControl('singleNamePattern', this.fb.array([]));
      }
    }

    formGroup.get('nameType').valueChanges.pipe(pairwise()).subscribe(([prev, next]) => {
      if (!isNotNullOrUndefined(prev) || prev !== next) {
        const method = DataUtils.ucFirst(formGroup.get('matchingMethod').value);
        formGroup.get('matchingPattern').setValue(this._formConfigDefault(next + method + PaxNameMatchingComponent.matchingTypeText));
      }
    });

    formGroup.get('matchingMethod').valueChanges.pipe(pairwise()).subscribe(([prev, next]) => {
      if (!isNotNullOrUndefined(prev) || prev !== next) {
        const nameType = formGroup.get('nameType').value;
        const method = DataUtils.ucFirst(next);
        formGroup.get('matchingPattern').setValue(this._formConfigDefault(nameType + method + PaxNameMatchingComponent.matchingTypeText));
      }
    });

    formGroup.get('matchingPattern').valueChanges.pipe(pairwise()).subscribe(([prev, next]) => {
      formGroup.removeControl('list');
      formGroup.removeControl('firstNameIsEmpty');
      formGroup.removeControl('lastNameIsEmpty');
      formGroup.removeControl('firstNamePattern');
      formGroup.removeControl('lastNamePattern');
      formGroup.removeControl('singleNamePattern');

      switch (next) {
        case 'list':
          formGroup.addControl('list', this.fb.array([]));
          this.addPredefinedName(index, 0, 'list');
          break;
        case 'customPattern':
          if (formGroup.get('nameType').value === 'fullName') {
            formGroup.addControl('firstNameIsEmpty', this.fb.control(this._formConfigDefault('firstNameIsEmpty')));
            formGroup.addControl('lastNameIsEmpty', this.fb.control(this._formConfigDefault('lastNameIsEmpty')));

            formGroup.addControl('firstNamePattern', this.fb.array([]));
            this.addCustomPattern(index, 0, 'firstNamePattern');

            formGroup.addControl('lastNamePattern', this.fb.array([]));
            this.addCustomPattern(index, 0, 'lastNamePattern');

            formGroup.removeControl('singleNamePattern');

            formGroup.get('lastNameIsEmpty').valueChanges.subscribe(value => {
              if (value === true) {
                formGroup.removeControl('lastNamePattern');
              } else {
                formGroup.addControl('lastNamePattern', this.fb.array([]));
                this.addCustomPattern(index, 0, 'lastNamePattern');
              }
            });

            formGroup.get('firstNameIsEmpty').valueChanges.subscribe(value => {
              if (value === true) {
                formGroup.removeControl('firstNamePattern');
              } else {
                formGroup.addControl('firstNamePattern', this.fb.array([]));
                this.addCustomPattern(index, 0, 'firstNamePattern');
              }
            });
          } else {
            formGroup.addControl('singleNamePattern', this.fb.array([]));
            this.addCustomPattern(index, 0, 'singleNamePattern');
          }
          break;
      }
    });

    this.inputArray.push(formGroup);

    if (formGroup.get('matchingPattern').value === 'list') {
      if (!isNewMethod && data.hasOwnProperty('list')) {
        data['list'].forEach((list, jdx) => {
          this.addPredefinedName(index, jdx, 'list', list);
        });
      } else {
        this.addPredefinedName(index, 0, 'list');
      }
    }

    if (formGroup.get('matchingPattern').value === 'customPattern') {
      if (formGroup.get('nameType').value === 'fullName') {
        if (!isNewMethod && data.hasOwnProperty('firstNamePattern')) {
          data['firstNamePattern'].forEach((fnp, jdx) => {
            this.addCustomPattern(index, jdx, 'firstNamePattern', fnp);
          });
        } else {
          this.addCustomPattern(index, 0, 'firstNamePattern');
        }

        if (!isNewMethod && data.hasOwnProperty('lastNamePattern')) {
          data['lastNamePattern'].forEach((fnp, jdx) => {
            this.addCustomPattern(index, jdx, 'lastNamePattern', fnp);
          });
        } else {
          this.addCustomPattern(index, 0, 'lastNamePattern');
        }
      } else {
        if (!isNewMethod && data.hasOwnProperty('singleNamePattern')) {
          data['singleNamePattern'].forEach((fnp, jdx) => {
            this.addCustomPattern(index, jdx, 'singleNamePattern', fnp);
          });
        } else {
          this.addCustomPattern(index, 0, 'singleNamePattern');
        }
      }
    }

    FormUtils.validateAllFormFields(formGroup);
    this.updateMethodsLimit();
  }

  addCustomPattern(methodIndex: number, patternIndex: number, customPatternType: string, data?: {}) {
    const isNewPattern = !isNotNullOrUndefined(data) || Object.keys(data).length < 3;
    const formGroup = this.fb.group({
      atLeast: [isNewPattern ? true : data['atLeast']],
      numberOfInstances: [isNewPattern ? 1 : data['numberOfInstances'], [
        Validators.required,
        Validators.min(this._formConfig('numberOfInstances').min),
        Validators.max(this._formConfig('numberOfInstances').max)]
      ],
      instanceOptions: [isNewPattern ? 'any character' : this.getInstanceOption(data)],
      text: [isNewPattern || !data.hasOwnProperty('text') ? '?' : data['text'],
        [Validators.required, Validators.pattern(RegexUtils.VALIDATOR_STRING_CUSTOM_PATTERN)]]
    });

    formGroup.get('text').valueChanges.subscribe(value => FormUtils.formControlUpperCase(formGroup.get('text'), value));

    formGroup.get('instanceOptions').valueChanges
      .pipe(startWith(isNewPattern ? 'any character' : this.getInstanceOption(data)), pairwise())
      .subscribe(([prev, next]) => {
        if (prev !== next) {
          switch (next) {
            case 'any character':
              if (formGroup.contains('text')) {
                formGroup.get('text').setValue('?');
              } else {
                formGroup.addControl('text', this.fb.control('?',
                  [Validators.required, Validators.pattern(RegexUtils.VALIDATOR_STRING_CUSTOM_PATTERN)]));
                formGroup.get('text').valueChanges.subscribe(textValue => FormUtils.formControlUpperCase(formGroup.get('text'), textValue));
              }
              formGroup.removeControl('allConsonants');
              formGroup.removeControl('allVowels');
              break;
            case 'all consonants':
              if (formGroup.contains('allConsonants')) {
                formGroup.get('allConsonants').setValue(true);
              } else {
                formGroup.addControl('allConsonants', this.fb.control(true));
              }
              formGroup.removeControl('allVowels');
              formGroup.removeControl('text');
              break;
            case 'all vowels':
              if (formGroup.contains('allVowels')) {
                formGroup.get('allVowels').setValue(true);
              } else {
                formGroup.addControl('allVowels', this.fb.control(true));
              }
              formGroup.removeControl('allConsonants');
              formGroup.removeControl('text');
              break;
            case 'string':
              if (formGroup.contains('text')) {
                formGroup.get('text').setValue('');
              } else {
                formGroup.addControl('text', this.fb.control('',
                  [Validators.required, Validators.pattern(RegexUtils.VALIDATOR_STRING_CUSTOM_PATTERN)]));
                formGroup.get('text').valueChanges.subscribe(textValue => FormUtils.formControlUpperCase(formGroup.get('text'), textValue));
              }
              formGroup.removeControl('allConsonants');
              formGroup.removeControl('allVowels');
              break;
          }
        }
      });

    if (!isNewPattern) {
      if (data.hasOwnProperty('allConsonants') && data['allConsonants'] === true) {
        formGroup.get('instanceOptions').setValue('all consonants');
        if (formGroup.contains('allConsonants')) {
          formGroup.get('allConsonants').setValue(true);
        } else {
          formGroup.addControl('allConsonants', this.fb.control(true));
        }
        formGroup.removeControl('text');
      }

      if (data.hasOwnProperty('allVowels') && data['allVowels'] === true) {
        formGroup.get('instanceOptions').setValue('all vowels');
        if (formGroup.contains('allVowels')) {
          formGroup.get('allVowels').setValue(true);
        } else {
          formGroup.addControl('allVowels', this.fb.control(true));
        }
        formGroup.removeControl('text');
      }

      if (data.hasOwnProperty('text') && data['text'] !== '?') {
        formGroup.get('instanceOptions').setValue('string');
        formGroup.get('text').setValue(data['text']);
      }

      formGroup.get('numberOfInstances').setValue(data['numberOfInstances']);
      formGroup.get('atLeast').setValue(data['atLeast']);
    }

    if (this.inputArray.controls[methodIndex].get(customPatternType)) {
      this.getAsFormArray(this.inputArray.controls[methodIndex].get(customPatternType))
        .setControl(patternIndex, formGroup);
    }
  }

  deletePaxNameMatching(methodIndex: number) {
    this.inputArray.removeAt(methodIndex);
    this.updateMethodsLimit();
  }

  deleteCustomPattern(methodIndex: number, patternIndex: number, customPatternType: string) {
    this.getAsFormArray(
      this.inputArray.controls[methodIndex].get(customPatternType)
    ).removeAt(patternIndex);
  }

  addPredefinedName(methodIndex: number, patternIndex: number, customPatternType: string, data?: string) {
    const nameType = this.inputArray.controls[methodIndex].get('nameType').value;
    const isNewPattern = !isNotNullOrUndefined(data) || data.length < 1;
    const formControl = this.fb.control(isNewPattern ? '' : data, [
      Validators.required,
      ConditionalValidator(() => nameType === 'fullName', Validators.pattern(RegexUtils.VALIDATOR_FULL_NAME)),
      ConditionalValidator(() => nameType !== 'fullName', Validators.pattern(RegexUtils.VALIDATOR_SINGLE_NAME))
    ]);
    formControl.valueChanges.subscribe(value => FormUtils.formControlUpperCase(formControl, value));

    if (this.inputArray.controls[methodIndex].get(customPatternType)) {
      this.getAsFormArray(this.inputArray.controls[methodIndex].get(customPatternType))
        .setControl(patternIndex, formControl);
    }
  }

  deletePredefinedName(methodIndex: number, patternIndex: number, customPatternType: string) {
    this.getAsFormArray(
      this.inputArray.controls[methodIndex].get(customPatternType)
    ).removeAt(patternIndex);
  }

  get inputArray(): FormArray {
    return this.group.get(this.field.name) as FormArray;
  }

  getAsGroup(control: AbstractControl): FormGroup {
    return control as FormGroup;
  }

  _formConfig(name: string) {
    return FormUtils.accessPath(PaxNameMatchingComponent.FORM_DEFINITION, ['paxNameMatching', name], {});
  }

  _formConfigOptions(name: string, keys = false) {
    const formConfig = this._formConfig(name);

    if (keys === true) {
      return Object.keys(formConfig.hasOwnProperty('options') ? formConfig['options'] : []);
    }

    return formConfig.hasOwnProperty('options') ? formConfig['options'] : {};
  }

  _formConfigOptionKeys(name: string) {
    return this._formConfigOptions(name, true);
  }

  _formConfigDefault(name: string) {
    return this._formConfig(name).default;
  }

  getMatchingPatternKeys(type: string, method: string) {
    return this._formConfigOptionKeys(type + DataUtils.ucFirst(method) + PaxNameMatchingComponent.matchingTypeText);
  }

  getMatchingPatternOptions(type: string, method: string) {
    return this._formConfigOptions(type + DataUtils.ucFirst(method) + PaxNameMatchingComponent.matchingTypeText);
  }

  private findUnusedMethod(method: string, inclusive: boolean) {
    const usedMethods = this.inputArray ? this.inputArray.controls
      .map(c => c.value)
      .filter(c => c.inclusive === inclusive && c.nameType + DataUtils.ucFirst(c.matchingMethod) + PaxNameMatchingComponent.matchingTypeText === method)
      .map(c => c.matchingPattern) : [];

    const firstAvailableMethod = this._formConfigOptionKeys(method)
      .filter(unusedMethod => usedMethods.indexOf(unusedMethod) < 0)
      .shift();

    if (isNotNullOrUndefined(firstAvailableMethod)) {
      return firstAvailableMethod;
    }

    return null;
  }

  private buildNewFormGroup(inclusive = true) {
    let unusedMethod = this.findUnusedMethod('firstNameExactMatchingType', inclusive);
    if (isNotNullOrUndefined(unusedMethod)) {
      return this.fb.group({
        inclusive: inclusive,
        nameType: 'firstName',
        matchingMethod: 'exact',
        matchingPattern: unusedMethod
      });
    }

    unusedMethod = this.findUnusedMethod('firstNameContainMatchingType', inclusive);
    if (isNotNullOrUndefined(unusedMethod)) {
      return this.fb.group({
        inclusive: inclusive,
        nameType: 'firstName',
        matchingMethod: 'contain',
        matchingPattern: unusedMethod
      });
    }

    unusedMethod = this.findUnusedMethod('lastNameExactMatchingType', inclusive);
    if (isNotNullOrUndefined(unusedMethod)) {
      return this.fb.group({
        inclusive: inclusive,
        nameType: 'lastName',
        matchingMethod: 'exact',
        matchingPattern: unusedMethod
      });
    }

    unusedMethod = this.findUnusedMethod('lastNameContainMatchingType', inclusive);
    if (isNotNullOrUndefined(unusedMethod)) {
      return this.fb.group({
        inclusive: inclusive,
        nameType: 'lastName',
        matchingMethod: 'contain',
        matchingPattern: unusedMethod
      });
    }

    unusedMethod = this.findUnusedMethod('firstOrLastExactMatchingType', inclusive);
    if (isNotNullOrUndefined(unusedMethod)) {
      return this.fb.group({
        inclusive: inclusive,
        nameType: 'firstOrLast',
        matchingMethod: 'exact',
        matchingPattern: unusedMethod
      });
    }

    unusedMethod = this.findUnusedMethod('firstOrLastContainMatchingType', inclusive);
    if (isNotNullOrUndefined(unusedMethod)) {
      return this.fb.group({
        inclusive: inclusive,
        nameType: 'firstOrLast',
        matchingMethod: 'contain',
        matchingPattern: unusedMethod
      });
    }

    unusedMethod = this.findUnusedMethod('fullNameExactMatchingType', inclusive);
    if (isNotNullOrUndefined(unusedMethod)) {
      return this.fb.group({
        inclusive: inclusive,
        nameType: 'fullName',
        matchingMethod: 'exact',
        matchingPattern: unusedMethod
      });
    }

    unusedMethod = this.findUnusedMethod('fullNameContainMatchingType', inclusive);
    if (isNotNullOrUndefined(unusedMethod)) {
      return this.fb.group({
        inclusive: inclusive,
        nameType: 'fullName',
        matchingMethod: 'contain',
        matchingPattern: unusedMethod
      });
    }

    return this.fb.group({
      inclusive: inclusive,
      nameType: 'firstName',
      matchingMethod: 'exact',
      matchingPattern: 'customPattern'
    });
  }

  getAsFormArray(control: AbstractControl): FormArray {
    return control as FormArray;
  }

  getAsFormControl(control: AbstractControl): FormControl {
    return control as FormControl;
  }

  filterInclusive(control: AbstractControl): boolean {
    return control.get('inclusive') && control.get('inclusive').value === true;
  }

  filterExclusive(control: AbstractControl): boolean {
    return control.get('inclusive') && control.get('inclusive').value === false;
  }

  getFirstControlIndex(inclusive = true): number {
    let firstIndex = -1;
    for (let i = 0; i < this.inputArray.controls.length; i++) {
      if (firstIndex < 0 && this.inputArray.controls[i].get('inclusive').value === inclusive) {
        firstIndex = i;
        break;
      }
    }

    return firstIndex;
  }

  getLastControlIndex(inclusive = true): number {
    let lastIndex = -1;
    for (let i = 0; i < this.inputArray.controls.length; i++) {
      if (this.inputArray.controls[i].get('inclusive').value === inclusive) {
        lastIndex = i;
      }
    }

    return lastIndex;
  }

  countPaxNameMatchingMethods(inclusive: boolean): number {
    if (inclusive) {
      return this.inputArray.controls.filter(control => this.filterInclusive(control)).length;
    } else {
      return this.inputArray.controls.filter(control => this.filterExclusive(control)).length;
    }
  }

  hasExceptions() {
    return this.countPaxNameMatchingMethods(false) > 0;
  }

  methodIsDuplicated(methodIndex: number) {
    return this.inputArray.hasError('paxNameMatching')
      && this.inputArray.errors['paxNameMatching'].index.indexOf(methodIndex) > -1;
  }

  overrideMaxArrayLengthError() {
    return 'You cannot define more than ' + ArrayValidators.NAME_MATCHING_METHODS_LIMIT + ' name matching method(s)';
  }

  updateMethodsLimit() {
    this.inclusiveLimitReached = this.countPaxNameMatchingMethods(true) >= ArrayValidators.NAME_MATCHING_METHODS_LIMIT;
    this.exclusiveLimitReached = this.countPaxNameMatchingMethods(false) >= ArrayValidators.NAME_MATCHING_METHODS_LIMIT;
  }

  getInstanceOption(data: {}) {
    if (data.hasOwnProperty('allConsonants') && data['allConsonants'] === true) {
      return 'all consonants';
    }

    if (data.hasOwnProperty('allVowels') && data['allVowels'] === true) {
      return 'all vowels';
    }

    if (data.hasOwnProperty('text') && data['text'] !== '?') {
      return 'string';
    } else {
      return 'any character';
    }
  }
}
