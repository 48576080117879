import { MockPermissions } from '../models/mock-permissions';
import { AuthUtils } from '../utils/auth.utils';
import { AutomationServicesCodeEnum } from '../utils/data/automation-services-code-enum';
import { AutomationServicesNameEnum } from '../utils/data/automation-services-name-enum';
import { ProductCodeEnum } from '../utils/data/product-code-enum';
import { ProductNameEnum } from '../utils/data/product-name-enum';

export const MOCK_PERMISSIONS: MockPermissions[] = [
  {
    title: 'Rule Editor',
    role: AuthUtils.LSS_APP_PREFIX + AuthUtils.COMMON,
    type: AuthUtils.RULE_EDITOR,
    permissions: [AuthUtils.READ, AuthUtils.CREATE, AuthUtils.UPDATE, AuthUtils.DELETE]
  },
  {
    title: 'Product Configuration',
    role: AuthUtils.LSS_APP_PREFIX + AuthUtils.COMMON,
    type: AuthUtils.PRODUCT_CONFIG,
    permissions: [AuthUtils.READ, AuthUtils.CREATE, AuthUtils.UPDATE, AuthUtils.DELETE]
  },
  {
    title: 'Automation Services',
    role: AuthUtils.LSS_APP_PREFIX + AuthUtils.COMMON,
    type: AuthUtils.AUTOMATION_SERVICES,
    permissions: [AuthUtils.READ, AuthUtils.CREATE, AuthUtils.UPDATE, AuthUtils.DELETE]
  },
  {
    title: 'Recalculation',
    role: AuthUtils.LSS_APP_PREFIX + AuthUtils.COMMON,
    type: AuthUtils.RECALCULATION,
    permissions: [AuthUtils.READ, AuthUtils.CREATE, AuthUtils.UPDATE, AuthUtils.DELETE]
  },
  {
    title: 'Compliance',
    role: AuthUtils.LSS_APP_PREFIX + AuthUtils.COMMON,
    type: AuthUtils.COMPLIANCE,
    permissions: [AuthUtils.READ, AuthUtils.CREATE, AuthUtils.UPDATE, AuthUtils.DELETE]
  },
  {
    title: 'Shared Configuration',
    role: AuthUtils.LSS_APP_PREFIX + AuthUtils.COMMON,
    type: AuthUtils.SHARED_CONFIG,
    permissions: [AuthUtils.READ, AuthUtils.CREATE, AuthUtils.UPDATE, AuthUtils.DELETE]
  },
  {
    title: 'Business Data',
    role: AuthUtils.LSS_APP_PREFIX + AuthUtils.COMMON,
    type: AuthUtils.BUSINESS_DATA,
    permissions: [AuthUtils.READ, AuthUtils.CREATE, AuthUtils.UPDATE, AuthUtils.DELETE]
  },
  {
    title: 'Reports',
    role: AuthUtils.LSS_APP_PREFIX + AuthUtils.REPORT,
    type: '',
    permissions: [AuthUtils.ACTIVE + AuthUtils.SEPARATOR + AuthUtils.REPORT, AuthUtils.READ_ONLY + AuthUtils.SEPARATOR + AuthUtils.REPORT]
  },
  {
    title: 'Search',
    role: AuthUtils.LSS_APP_PREFIX + AuthUtils.SEARCH,
    type: '',
    permissions: [AuthUtils.ACTIVE + AuthUtils.SEPARATOR + AuthUtils.SEARCH, AuthUtils.READ_ONLY + AuthUtils.SEPARATOR + AuthUtils.SEARCH]
  },
  {
    title: ProductNameEnum.ATL,
    role: AuthUtils.LSS_APP_PREFIX + ProductCodeEnum.ATL,
    type: '',
    permissions: [AuthUtils.ACTIVE + AuthUtils.SEPARATOR + ProductCodeEnum.ATL, AuthUtils.READ_ONLY + AuthUtils.SEPARATOR + ProductCodeEnum.ATL]
  },
  {
    title: ProductNameEnum.DPP,
    role: AuthUtils.LSS_APP_PREFIX + ProductCodeEnum.DPP,
    type: '',
    permissions: [AuthUtils.ACTIVE + AuthUtils.SEPARATOR + ProductCodeEnum.DPP, AuthUtils.READ_ONLY + AuthUtils.SEPARATOR + ProductCodeEnum.DPP]
  },
  {
    title: ProductNameEnum.DPS,
    role: AuthUtils.LSS_APP_PREFIX + ProductCodeEnum.DPS,
    type: '',
    permissions: [AuthUtils.ACTIVE + AuthUtils.SEPARATOR + ProductCodeEnum.DPS, AuthUtils.READ_ONLY + AuthUtils.SEPARATOR + ProductCodeEnum.DPS]
  },
  {
    title: AutomationServicesNameEnum.FMC,
    role: AuthUtils.LSS_APP_PREFIX + AutomationServicesCodeEnum.FMC,
    type: '',
    permissions: [AuthUtils.ACTIVE + AuthUtils.SEPARATOR + AutomationServicesCodeEnum.FMC, AuthUtils.READ_ONLY + AuthUtils.SEPARATOR + AutomationServicesCodeEnum.FMC]
  },
  {
    title: ProductNameEnum.FSN,
    role: AuthUtils.LSS_APP_PREFIX + ProductCodeEnum.FSN,
    type: '',
    permissions: [AuthUtils.ACTIVE + AuthUtils.SEPARATOR + ProductCodeEnum.FSN, AuthUtils.READ_ONLY + AuthUtils.SEPARATOR + ProductCodeEnum.FSN]
  },
  {
    title: ProductNameEnum.GTL,
    role: AuthUtils.LSS_APP_PREFIX + ProductCodeEnum.GTL,
    type: '',
    permissions: [AuthUtils.ACTIVE + AuthUtils.SEPARATOR + ProductCodeEnum.GTL, AuthUtils.READ_ONLY + AuthUtils.SEPARATOR + ProductCodeEnum.GTL]
  },
  {
    title: ProductNameEnum.HDG,
    role: AuthUtils.LSS_APP_PREFIX + ProductCodeEnum.HDG,
    type: '',
    permissions: [AuthUtils.ACTIVE + AuthUtils.SEPARATOR + ProductCodeEnum.HDG, AuthUtils.READ_ONLY + AuthUtils.SEPARATOR + ProductCodeEnum.HDG]
  },
  {
    title: ProductNameEnum.NSM,
    role: AuthUtils.LSS_APP_PREFIX + ProductCodeEnum.NSM,
    type: '',
    permissions: [AuthUtils.ACTIVE + AuthUtils.SEPARATOR + ProductCodeEnum.NSM, AuthUtils.READ_ONLY + AuthUtils.SEPARATOR + ProductCodeEnum.NSM]
  },
  {
    title: AutomationServicesNameEnum.PYC,
    role: AuthUtils.LSS_APP_PREFIX + AutomationServicesCodeEnum.PYC,
    type: '',
    permissions: [AuthUtils.ACTIVE + AuthUtils.SEPARATOR + AutomationServicesCodeEnum.PYC, AuthUtils.READ_ONLY + AuthUtils.SEPARATOR + AutomationServicesCodeEnum.PYC]
  },
  {
    title: ProductNameEnum.TTT,
    role: AuthUtils.LSS_APP_PREFIX + ProductCodeEnum.TTT,
    type: '',
    permissions: [AuthUtils.ACTIVE + AuthUtils.SEPARATOR + ProductCodeEnum.TTT, AuthUtils.READ_ONLY + AuthUtils.SEPARATOR + ProductCodeEnum.TTT]
  },
  {
    title: ProductNameEnum.WLT,
    role: AuthUtils.LSS_APP_PREFIX + ProductCodeEnum.WLT,
    type: '',
    permissions: [AuthUtils.ACTIVE + AuthUtils.SEPARATOR + ProductCodeEnum.WLT, AuthUtils.READ_ONLY + AuthUtils.SEPARATOR + ProductCodeEnum.WLT]
  },
  {
    title: ProductNameEnum.CHR,
    role:  AuthUtils.LSS_APP_PREFIX + ProductCodeEnum.CHR,
    type: '',
    permissions: [AuthUtils.ACTIVE + AuthUtils.SEPARATOR + ProductCodeEnum.CHR, AuthUtils.READ_ONLY + AuthUtils.SEPARATOR + ProductCodeEnum.CHR]
  }
];
