import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-footer',
  template: `
    <div></div>
  `,
  styles: [],
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
