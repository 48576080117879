import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { Field } from './../models/field';
import { Component, OnInit, Input, EventEmitter, Output, AfterViewChecked } from '@angular/core';

@Component({
  selector: 'app-object',
  template: `
  <div [formGroup]="group" class="inline">
    <div class="inline pr-1 align-top" *ngFor="let field of flow;let i = index;">
      <ng-container class="inline"
          appDynamicField
          [field]="field"
          [group]="group"
          [state]="state"
          (invalid)="parseValidation($event)" >
      </ng-container>
    </div>
    <div class="inline">
      <div [ngClass]="{'inline': i === 0}" *ngFor="let group of businessType.controls;let i = index;">
        <ng-container class="inline padding_right_5"
            *ngFor="let field of fields;"
            appDynamicField
            [field]="field"
            [group]="group"
            [state]="state"
            [showFullSize]="showFullSize"
            (invalid)="parseValidation($event)" >
        </ng-container>
        <span [hidden]="i === businessType.controls.length - 1" class="info-green">&nbsp;or&nbsp;</span>
        <a [hidden]="this.state === 'info' || i === 0" class="menu-criteria padding_right_5" href="javascript:void(0)" (click)="this.deleteObject(i)">
          <i class="fa fa-trash" title="Delete this condition"></i>
        </a>
        <a [hidden]="this.state === 'info' || i !== businessType.controls.length - 1" class="menu-criteria padding_right_5"  href="javascript:void(0)" (click)="this.addObject()">
            <i class="fa fa-plus-square"></i>
        </a>
      </div>
    </div>
  </div>
  `,
  styles: []
})
export class ObjectComponent implements OnInit, AfterViewChecked {

  @Input() name: string;
  @Input() fields: Field[];
  @Input() group: FormGroup;
  @Input() state: string;
  @Input() value: any[];
  @Input() showFullSize: boolean;
  @Output() invalid = new EventEmitter<any>();
  flow: Field[] = [];

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.initObject();
    if (!this.value || this.value.length === 0) {
      this.addObject();
    } else {
      this.value.forEach(v => {
        const object = this.getObjectGroup();
        object.patchValue(v);
        this.businessType.push(object);
      });
    }
  }

  ngAfterViewChecked() {
    /* if (this.name === 'ptdRange') {
      this.checkPtdRange();
    }*/
  }

  initObject() {
    const index = this.fields.filter(f => f.type === 'flow').findIndex(fl => fl.label.indexOf('|') > -1);
    const field_flow = this.fields.splice(0, (index + 1));
    field_flow.map(f => f.label).forEach(f => {
        const t = f.split('|');
        this.flow.push({ label: t[0], type: 'flow'});
        if (t.length > 1 || t[1] !== '') {
          this.fields.unshift({ label: t[1], type: 'flow'});
        }
      });
  }

  get businessType() {
    return this.group.get(this.name) as FormArray;
  }

  addObject() {
    this.businessType.push(this.getObjectGroup());
  }

  getObjectGroup() {
    const object = this.fb.group({});
    this.fields.forEach((f) => {
      if (f.type !== 'flow') {
        object.addControl(f.name, this.fb.control(f.value));
      }
    });
    return object;
  }

  deleteObject(index: number) {
    this.businessType.removeAt(index);
  }

  parseValidation(error: any) {
    this.invalid.emit(error);
  }
}
