import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticUser } from '../../../core/models/authentic-user';
import { Carrier } from '../../../core/models/carrier';
import { CarrierService } from '../../../core/services/carrier.service';
import { LogUtils } from '../../../core/utils/logging/log-utils';
import { Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { MOCK_PERMISSIONS } from '../../../core/configuration/permissions';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SET_UI_CONFIGURATION } from '../../../core/configuration/set';
import { AuthService } from '../../../core/services/auth.service';
import { AuthUtils } from '../../../core/utils/auth.utils';
import { MockPermissions } from '../../../core/models/mock-permissions';
import { modalsConfiguration } from '../../../core/configuration/modal';
import { ModalCodeEnum } from '../../../core/utils/data/modal-code-enum';
import { ConfirmationDialogService } from '../modal/service/confirm-modal.service';
import {AppConfigService} from '../../../app-config.service';

@Component({
  selector: 'app-header',
  template: `
      <header id="topnav">
          <div class="topbar-main">
              <div class="container-fluid d-flex">

                  <!-- Logo container-->
                  <div class="logo mr-auto p-2">
                      <h1 class="no-display">Welcome to roadrunner-ui!</h1>
                      <!-- Image Logo -->
                      <a routerLink="/home"
                         class="logo">
                          <img src="/assets/image/logo/logo.png"
                               alt=""
                               height="26"
                               class="logo-large"/>
                          <img src="/assets/image/logo/app_name.png"
                               alt=""
                               height="20"
                               class="logo-large"/>
                      </a>
                  </div>
                  <!-- End Logo container-->

                  <button *ngIf="canMockPermissions()" type="button"
                          [ngClass]="{
                      'btn-success': authService.hasMockUserPermissions(),
                      'btn-outline-primary': !authService.hasMockUserPermissions()
                  }"
                          class="btn p-2 align-self-center" data-toggle="modal" data-target="#mockUserModal" data-backdrop="static">
                      {{ authService.hasMockUserPermissions() ? 'Mocked User' : 'Mock User' }}
                  </button>

                  <div *ngIf="aUserIsLoggedIn" class="menu-extras topbar-custom p-2">
                      <ul class="list-unstyled topbar-right-menu float-right mb-0">
                          <li class="menu-item">
                              <!-- Mobile menu toggle-->
                              <a class="navbar-toggle nav-link">
                                  <div class="lines">
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                  </div>
                              </a>
                              <!-- End mobile menu toggle-->
                          </li>

                          <li class="dropdown notification-list hide-phone"></li>
                          <li class="dropdown notification-list">
                              <a class="nav-link waves-effect nav-user"
                                 data-toggle="dropdown"
                                 href="#"
                                 role="button"
                                 aria-haspopup="false"
                                 aria-expanded="false">
                  <span class="ml-1 pro-user-name"><span class="pro-user-name-decoration">{{authenticUser.getUsername()}}
                      (</span>{{authService.getActiveCarrierCode()}}<span class="pro-user-name-decoration">)</span> <i class="mdi mdi-chevron-down"></i></span>
                              </a>
                              <div *ngIf="authenticUser" class="dropdown-menu dropdown-menu-right">
                                  <!-- item-->
                                  <a href="javascript:;" (click)="logout()"
                                     class="dropdown-item notify-item">
                                      <i class="fi-power text-danger"></i>&nbsp;<span>Logout</span>
                                  </a>

                                  <div *ngIf="carrierList && carrierList.length > 1 && authenticUser.getOrganization() === AMADEUS_CARRIER_CODE" class="dropdown-divider"></div>

                                  <ng-template ngFor let-carrier [ngForOf]="carrierList" let-i="index">
                                      <a *ngIf="carrier.carrierCode != authService.getActiveCarrierCode() && authenticUser.getOrganization() === AMADEUS_CARRIER_CODE"
                                         href="javascript:;" (click)="switchCarrier(carrier.carrierCode)"
                                         class="dropdown-item notify-item">
                                          <span>{{carrier.name}} ({{carrier.carrierCode}})</span>
                                      </a>
                                  </ng-template>

                              </div>
                          </li>
                      </ul>
                  </div>
                  <div class="clearfix"></div>
              </div> <!-- end container -->
          </div>
          <!-- end topbar-main -->

          <!-- Navbar-custom -->
          <div *ngIf="aUserIsLoggedIn" class="navbar-custom">
              <div class="container-fluid">
                  <div id="navigation">
                      <!-- Navigation Menu-->
                      <ul class="navigation-menu">

                          <li class="has-submenu">
                              <a routerLink="/home"><i class="icon-home"></i>Home</a>
                          </li>

                          <li class="has-submenu" *ngIf="canAccessProducts()">
                              <a routerLink="/products"><i class="icon-plane"></i>My Products</a>
                          </li>

                          <li class="has-submenu" *ngIf="canAccessReports()">
                              <a routerLink="/report"><i class=" icon-chart"></i>Analytics</a>
                          </li>

                          <li class="has-submenu" *ngIf="canAccessSearch()">
                              <a routerLink="/search"><i class="icon-magnifier"></i>Search Engine</a>
                          </li>

                          <li class="has-submenu" *ngIf="canAccessShared()">
                              <a routerLink="/general-settings"><i class=" icon-settings"></i>General Settings</a>
                          </li>

                          <li class="has-submenu" *ngIf="canAccessAutomationServices()">
                            <a routerLink="/automation-services"><i class=" icon-wrench"></i>Automation Services</a>
                          </li>

                      </ul>
                      <!-- End Navigation Menu-->
                  </div>
              </div>
          </div>
          <!-- end navbar-custom -->
          <!-- Mock User Modal -->
          <div *ngIf="authenticUser && authenticUser.getOrganization() === AMADEUS_CARRIER_CODE" class="modal fade" id="mockUserModal" tabindex="-1" role="dialog" aria-labelledby="mockUserModalLabel" aria-hidden="true">
              <div class="modal-dialog mock-modal-dialog">
                  <div class="modal-content">
                      <div class="modal-header">
                          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                          <h4 class="modal-title text-primary"><img src="/assets/favicon/favicon-32x32.png" alt="" height="26">Mock User Permissions</h4>
                      </div>
                      <form [formGroup]="mockPermissionsForm" (ngSubmit)="onSubmit()">
                          <div class="modal-body">
                              <ul class="list-group list-group-flush">
                                  <li *ngFor="let mockPermission of mockPermissions; index as i; trackBy: trackByFn" formArrayName="rolesPermissions" class="list-group-item">
                                      <span class="form-check-label mock-form-title">{{ mockPermission.title }}</span>
                                      <ng-template ngFor let-permission [ngForOf]="mockPermission.permissions" let-j="index" [ngForTrackBy]="trackByFn">
                                          <input class="form-check-input"
                                                 type="checkbox"
                                                 value="{{ mockPermission.role + '_' + authService.buildMockPermissionText(mockPermission.type, permission) }}"
                                                 id="{{ mockPermission.role + '_' + authService.buildMockPermissionText(mockPermission.type, permission) }}"
                                                 [formControlName]="buildFormControlName(i, j, mockPermissions)"/>
                                          <label class="form-check-label " style="padding-right: 2rem"
                                                 for="{{ mockPermission.role + '_' + authService.buildMockPermissionText(mockPermission.type, permission) }}">{{ permission }}</label>
                                      </ng-template>
                                  </li>
                              </ul>
                          </div>
                          <div class="modal-footer">
                            <a href="javascript:;" (click)="selectAll()">select all</a> / <a href="javascript:;" (click)="unselectAll()">unselect all</a>
                            <button type="button" class="btn btn-light waves-effect" data-dismiss="modal">Cancel</button>
                            <button type="submit" class="btn btn-primary waves-effect waves-light">Confirm</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </header>
  `,
  styles: []
})
export class HeaderComponent implements OnInit, OnDestroy {
  private _destroyed$ = new Subject();

  public readonly modal = modalsConfiguration.filter(m => m.code === ModalCodeEnum.SWICODE).shift();
  public readonly mockPermissions = MOCK_PERMISSIONS;
  public readonly AMADEUS_CARRIER_CODE = AuthUtils.AMADEUS_CARRIER_CODE;
  public mockPermissionsForm: FormGroup;

  public carrierList: Carrier[];
  public aUserIsLoggedIn: boolean;
  public authenticUser: AuthenticUser;
  public counter = 0;

  constructor(public authService: AuthService,
    private carrierService: CarrierService,
    private dialogSvc: ConfirmationDialogService,
    private appConfigService: AppConfigService,
    private fb: FormBuilder) {

    this.authService.isUserLoggedInEventEmitter.subscribe(aUserIsLoggedIn => {
      this.aUserIsLoggedIn = aUserIsLoggedIn;
    });
    this.authService.authenticUserLoggedInEventEmitter.subscribe(authenticUser => {
      this.authenticUser = authenticUser;
    });

    this.mockPermissionsForm = this.fb.group({
      rolesPermissions: new FormArray([])
    });
  }

  ngOnInit() {
    if (this.authService.isUserLoggedIn()) {
      if (this.authService.getAuthenticatedUser().getOrganization() === AuthUtils.AMADEUS_CARRIER_CODE) {
        if (!this.carrierService.hasCarriers()) {
          this.carrierService.findCarriers()
            .pipe(map(value => value), take(1), takeUntil(this._destroyed$))
            .subscribe(response => {
              LogUtils.info(['Carriers', response.returnObject]);
              this.carrierService.setCarriers(response.returnObject);
              this.carrierList = response.returnObject;
            });
        } else {
          this.carrierList = this.carrierService.getCarriers();
        }

        const selectedPermissions = this.authService.getMockUserPermissions().split(',');
        this.mockPermissions.map((rp, i) => {
          rp.permissions.map((p, j) => {
            const control = new FormControl(selectedPermissions.indexOf(rp.role + '_' + this.authService.buildMockPermissionText(rp.type, p)) > -1);
            (this.mockPermissionsForm.controls.rolesPermissions as FormArray).push(control);
          });
        });
      }
    } else {
      this.authService.logoutUser();
      location.href = window.location.protocol + '//' + window.location.host;
    }
  }

  get rolesPermissionsControl() {
    return this.mockPermissionsForm.get('rolesPermissions') as FormArray;
  }

  logout(): void {
    this.authService.logoutUser(true);
  }

  switchCarrier(carrierCode: string): void {
    this.dialogSvc
      .confirm(this.modal.componentType, this.modal.displayMessage)
      .then(confirmed => {
        if (confirmed) {
          this.authService.setActiveCarrierCode(carrierCode);
          if (this.authService.isAmadeusOrganization()) {
            let uiToken = this.appConfigService.config.environment + '_' + 'roadrunner-ui-token';
            var uiTokenValue = sessionStorage.getItem(uiToken);
            sessionStorage.clear();
            sessionStorage.setItem(uiToken,uiTokenValue);
          }
          window.location.reload();
        }
      }).catch();
  }

  onSubmit() {
    const controls = (this.rolesPermissionsControl as FormArray).controls;
    const selectedPermissions = [];
    const that = this;

    this.mockPermissions.forEach((rp, i) => {
      rp.permissions
        .filter((p, j) => controls[that.buildFormControlName(i, j, this.mockPermissions)].value === true)
        .reduce((arr, cur, idx) => {
          arr.push(rp.role + '_' + that.authService.buildMockPermissionText(rp.type, cur));
          return arr;
        }, selectedPermissions);
    });

    LogUtils.info(['MockPermissions', selectedPermissions.join(',')]);
    if (selectedPermissions.length <= 0) {
      this.authService.deleteMockUserPermissions();
    } else {
      this.authService.setMockUserPermissions(selectedPermissions.join(','));
    }

    this.switchCarrier(this.authService.getActiveCarrierCode());
  }

  canAccessProducts(): boolean {
    return this.authService.canActivate(AuthUtils.ALL_PRODUCT_ROLES, AuthUtils.PRODUCTS_PERMISSIONS_TYPES, AuthUtils.ALL);
  }

  canAccessReports(): boolean {
    return this.authService.canActivate([AuthUtils.REPORT]);
  }

  canAccessSearch(): boolean {
    return this.authService.canActivate([AuthUtils.SEARCH]);
  }

  canAccessShared(): boolean {
    return this.authService.canActivate([AuthUtils.COMMON], AuthUtils.SHARED_PERMISSIONS_TYPES, AuthUtils.ALL);
  }

  canAccessAutomationServices(): boolean {
    return this.authService.canActivate(AuthUtils.ALL_AUTOMATION_SERVICES_ROLES, AuthUtils.ALL_AUTOMATION_SERVICES_TYPES, AuthUtils.ALL);
  }

  canMockPermissions(): boolean {
    return this.authenticUser
      && this.authenticUser.getOrganization() === AuthUtils.AMADEUS_CARRIER_CODE
      && (this.authService.hasMockUserPermissions()
        || SET_UI_CONFIGURATION.some(set =>
          this.authService.canCreate(set.setUI)
          || this.authService.canUpdate(set.setUI)
          || this.authService.canDelete(set.setUI)
        )
      );
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  /**
   * Generate the form control name as the nth permission found in the MOCK_PERMISSIONS constant.
   * Each MockPermissions element can have one or more permissions appended to it. For example,
   * the group of permissions `Rule Editor` has 4 specific permissions READ, CREATE, UPDATE and
   * DELETE and it will generate the following FormControls:
   *      [0]: COMMON_RE_READ
   *      [1]: COMMON_RE_CREATE
   *      [2]: COMMON_RE_UPDATE
   *      [3]: COMMON_RE_DELETE
   *
   * Then the second element `Product Configuration` will be:
   *      [4]: COMMON_PC_READ
   *      [5]: COMMON_PC_CREATE
   *      [6]: COMMON_PC_UPDATE
   *      [7]: COMMON_PC_DELETE
   *
   * and so on...
   *
   * @param i               Index of the current {@link MOCK_PERMISSIONS} element
   * @param j               Index of the current permissions property of
   *                        the current {@link MOCK_PERMISSIONS} element
   * @param mockPermissions {@link MOCK_PERMISSIONS}
   *
   * @returns the index of the current permission item within the mockPermissionsForm {@link FormGroup}
   */
  buildFormControlName(i: number, j: number, mockPermissions: MockPermissions[]): number {
    let currentControl = 0;
    for (let idx = i - 1; idx >= 0; idx--) {
      currentControl += mockPermissions[idx].permissions.length;
    }

    return currentControl + j;
  }

  trackByFn(index, item) {
    return index;
  }

  selectAll() {
    const controls = (this.rolesPermissionsControl as FormArray).controls;
    controls.forEach(control => control.setValue(true));
  }

  unselectAll() {
    const controls = (this.rolesPermissionsControl as FormArray).controls;
    controls.forEach(control => control.setValue(false));
  }

}
