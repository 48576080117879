import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], callback: (item: any) => boolean): any[] {
    if (!items || !callback) { return items; }

    return items.filter(it => callback(it));
  }
}
