import {Observable} from 'rxjs';
import {ApiResponseObject} from '../../shared/model/api-response-object';
import {AppConfigService} from '../../app-config.service';
import {HttpClient} from '@angular/common/http';
import {ApiLog} from '../../shared/model/api-log';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiLogService {

  constructor(private environment: AppConfigService,
              private httpClient: HttpClient) {
  }

  public postApiLog(apiLog: ApiLog): Observable<ApiResponseObject> {
    return this.httpClient.post<ApiResponseObject>(
      this.environment.config.apiBaseEndpoint + '/' + apiLog.carrierCode + '/log', apiLog
    );
  }

}
