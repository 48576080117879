import {AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors} from '@angular/forms';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {RegexUtils} from '../regex-utils';

export class FormUtils {

  static setFormData(form: FormGroup, formData: any, settingsToInit: string[] = []): void {
    if (!formData) {
      return;
    }
    Object.keys(form.controls).forEach(formControl => {
      FormUtils.setFormControlData(form, form.get(formControl), formData[formControl], settingsToInit);
    });
  }

  static setFormControlData(form: FormGroup, formControl: any, formControlData: any, settingsToInit: string[]): void {
    if (formControl instanceof FormGroup) {
      let thereIsFormControlData;
      Object.keys(formControl.controls).forEach(formControlKey => {
        thereIsFormControlData = formControlData && isNotNullOrUndefined(formControlData[formControlKey]);
        if (thereIsFormControlData) {
          this.setFormControlData(form, formControl.get(formControlKey), formControlData[formControlKey], settingsToInit);
        } else {
          settingsToInit.push(formControlKey);
        }
      });
    } else if (formControl instanceof FormControl) {
      formControl.setValue(formControlData);
    }
  }

  static gatherValidationErrors(form: FormGroup | FormArray): any[] {
    let errors = [];
    Object.keys(form.controls).forEach(key => {
      const control = form.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        errors = errors.concat(FormUtils.gatherValidationErrors(control));
      }
      const controlErrors: ValidationErrors = control.errors;
      if (isNotNullOrUndefined(controlErrors)) {
        errors.push(key);
      }
    });

    return errors;
  }

  static gatherControlList(form: FormGroup): string[] {
    let controls: string[] = [];
    Object.keys(form.controls).forEach(key => {
      const control = form.controls[key];
      if (control instanceof FormGroup) {
        controls = controls.concat(FormUtils.gatherControlList(control).map(c => key + '.' + c));
      } else if (control instanceof FormControl) {
        controls.push(key);
      }
    });

    return controls;
  }

  static validateAllFormFields(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.updateValueAndValidity({onlySelf: true, emitEvent: true});
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        FormUtils.validateAllFormFields(control);
      }
    });
  }

  static compareForm(prevForm: string, saveForm: FormGroup): boolean {
    return isNotNullOrUndefined(prevForm)
      && saveForm !== new FormGroup({})
      && prevForm !== JSON.stringify(saveForm.getRawValue())
      && prevForm !== JSON.stringify(saveForm.value);
  }

  static isInvalid(form: FormGroup): boolean {
    return !form.valid;
  }

  static isValidAndUntouched(form: FormGroup): boolean {
    return form.valid && form.pristine && form.untouched;
  }

  static accessPath(jsonObject: {}, path: string[], defaultValue = null) {
    let ret = defaultValue;
    if (path.length > 0) {
      const p = path.shift();
      if (isNotNullOrUndefined(jsonObject) && jsonObject.hasOwnProperty(p)) {
        if (path.length > 0) {
          ret = FormUtils.accessPath(jsonObject[p], path);
        } else {
          ret = jsonObject[p];
        }
      }
    }

    return ret;
  }

  static accessControlPath(control: AbstractControl, path: string[]): AbstractControl {
    let ret = null;
    if (path.length > 0) {
      const p = path.shift();
      if (isNotNullOrUndefined(control) && isNotNullOrUndefined(control.get(p))) {
        if (path.length > 0) {
          ret = FormUtils.accessControlPath(control.get(p), path);
        } else {
          ret = control.get(p);
        }
      }
    }

    return ret;
  }

  static formControlMultiCleanUpperCase(formControl: AbstractControl, value: any) {
    if (typeof value === 'string') {
      const newValue = value.trim().replace(/( *, *)+/g, ',');
      if (/[a-z]/.test(value) || value !== newValue) {
        formControl.setValue(newValue.toUpperCase());
      }
    }
  }

  static formControlMultiCleanLowerCase(formControl: AbstractControl, value: any) {
    if (typeof value === 'string') {
      const newValue = value.trim().replace(/( *, *)+/g, ',');
      if (/[A-Z]/.test(value) || value !== newValue) {
        formControl.setValue(newValue.toLowerCase());
      }
    }
  }

  static formControlUpperCase(formControl: AbstractControl, value: any) {
    if (typeof value === 'string' && /[a-z]|^ +$/.test(value)) {
      const newValue = value.replace(/^ +$/, '');
      formControl.setValue(newValue.toUpperCase());
    }
  }

  static formControlLowerCase(formControl: AbstractControl, value: any) {
    if (typeof value === 'string' && /[A-Z]|^ +$/.test(value)) {
      const newValue = value.replace(/^ +$/, '');
      formControl.setValue(newValue.toLowerCase());
    }
  }

  static formControlTime(formControl: AbstractControl, value: any) {
    if (typeof value === 'string' &&
      RegexUtils.VALIDATOR_SHORT_TIME.test(value) &&
      !RegexUtils.VALIDATOR_LONG_TIME.test(value)) {
      formControl.setValue(value + ':00');
    }
  }
}
