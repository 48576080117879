import {FieldService} from './services/field.service';
import {DynamicFieldDirective} from './directives/dynamic-field.directive';
import {FlowComponent} from './components/flow.component';
import {DateRangeComponent} from './components/daterange.component';
import {LocationComponent} from './components/location.component';
import {DateComponent} from './components/date.component';
import {TextareaComponent} from './components/textarea.component';
import {SelectComponent} from './components/select.component';
import {InputComponent } from './components/input.component';
import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../../shared/shared.module';
import {ObjectComponent} from './components/object.component';
import {ComplexObjectComponent} from './components/complexObject.component';
import {BusinessTypeComponent} from './pages/business-type.component';
import {BusinessTypeService} from './services/business-type.service';
import {PtdRangeComponent} from './components/ptd-range.component';
import {PaxNamePercentageComponent} from './components/paxNamePercentage.component';
import {PaxNameMatchingComponent} from './components/pax-name-matching.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicFieldDirective,
    InputComponent,
    SelectComponent,
    TextareaComponent,
    DateComponent,
    LocationComponent,
    DateRangeComponent,
    FlowComponent,
    ObjectComponent,
    ComplexObjectComponent,
    BusinessTypeComponent,
    PaxNameMatchingComponent,
    PtdRangeComponent,
    PaxNamePercentageComponent
  ],
  declarations: [
    DynamicFieldDirective,
    InputComponent,
    SelectComponent,
    TextareaComponent,
    DateComponent,
    LocationComponent,
    DateRangeComponent,
    FlowComponent,
    ObjectComponent,
    ComplexObjectComponent,
    BusinessTypeComponent,
    PaxNameMatchingComponent,
    PtdRangeComponent,
    PaxNamePercentageComponent
  ],
  entryComponents: [
    InputComponent,
    SelectComponent,
    TextareaComponent,
    DateComponent,
    LocationComponent,
    DateRangeComponent,
    FlowComponent,
    PaxNameMatchingComponent,
    PtdRangeComponent,
    PaxNamePercentageComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FieldManagerModule {

  static forRoot(): ModuleWithProviders<FieldManagerModule> {
    return {
        ngModule: FieldManagerModule,
        providers: [FieldService, BusinessTypeService]
    };
}
 }
