import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {LoaderService} from '../../../core/services/loader.service';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

  loading = false;
  loadingSubscription: Subscription;

  constructor(private loaderService: LoaderService) {
  }

  ngOnInit() {
    this.loadingSubscription = this.loaderService.loadingStatus.pipe(
      debounceTime(300)
    ).subscribe((value) => {
      this.loading = (<boolean>value);
    });
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }
}
