export class ApiLog {
  errorCode: number;
  tokenExpirationTime: string;
  currentDateTime: string;
  browserName: string;
  browserVersion: string;
  engineName: string;
  engineVersion: string;
  operatingSystemName: string;
  operatingSystemVersion: string;
  deviceVendor: string;
  deviceModel: string;
  deviceType: string;
  carrierCode: string;


  constructor(errorCode: number,
              tokenExpirationTime: string,
              currentDateTime: string,
              browserName: string,
              browserVersion: string,
              engineName: string,
              engineVersion: string,
              operatingSystemName: string,
              operatingSystemVersion: string,
              deviceVendor: string,
              deviceModel: string,
              deviceType: string,
              carrierCode: string) {
    this.errorCode = errorCode;
    this.tokenExpirationTime = tokenExpirationTime;
    this.currentDateTime = currentDateTime;
    this.browserName = browserName;
    this.browserVersion = browserVersion;
    this.engineName = engineName;
    this.engineVersion = engineVersion;
    this.operatingSystemName = operatingSystemName;
    this.operatingSystemVersion = operatingSystemVersion;
    this.deviceVendor = deviceVendor;
    this.deviceModel = deviceModel;
    this.deviceType = deviceType;
    this.carrierCode = carrierCode;
  }
}
