import { Field } from './../models/field';
import {FormGroup, FormArray, FormBuilder, Validators, AbstractControl, FormControl, Form} from '@angular/forms';
import {Component, OnInit, EventEmitter, Output, Input} from '@angular/core';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';

@Component({
  selector: 'app-pax-name-percentage',
  template: `
    <div [formGroup]="group" class="inline">
      <div class="inline padding_right_5" [formGroup]="percentageGroup">
        <ng-container *ngIf="this.state === 'info'; else editTemplate">
          <span class="info">{{ percentageControl.value }}</span>
        </ng-container>
        <ng-template #editTemplate>
          <input class="input-text-custom number"
                 type="number"
                 [id]="field.name"
                 (focusout)="checkRange()"
                 [min]="minPercentage"
                 [max]="maxPercentage"
                 autocomplete="off"
                 [formControlName]="fromJsonKey"
                 [placeholder]="field.label"
                 [class.required]="(this.isRequired === true)"
                 [class.isInvalid]="(this.isInvalid === true)"/>
        </ng-template>
      </div>
    </div>
  `,
  styles: []
})
export class PaxNamePercentageComponent implements OnInit {
  @Input() field: Field;
  @Input() group: FormGroup;
  @Input() state: string;

  public readonly fromJsonKey = 'from';
  private minPercentage = 50;
  private maxPercentage = 100;
  isInvalid = false;
  isRequired = false;
  messageRequired = '';

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.initPaxNamePercentage();

    const index = this.field.validations.findIndex(fv => fv.name === 'required');

    if (index > -1 ) {
      this.isRequired = true;
      this.messageRequired = this.field.validations[index].message;
    }
    if (isNotNullOrUndefined(this.field)) {
      this.minPercentage = this.field.min;
      this.maxPercentage = this.field.max;
    }
  }

  initPaxNamePercentage() {
    if (isNotNullOrUndefined(this.percentageControl) && this.percentageControl.value > 0) {
      this.setValue(this.percentageControl.value);
    } else {
      this.percentageGroup.addControl(this.fromJsonKey, this.fb.control(this.field.value));
    }
  }

  get percentageGroup(): FormGroup {
    return this.group.get(this.field.name) as FormGroup;
  }

  get percentageControl(): FormControl {
    return this.percentageGroup.get(this.fromJsonKey) as FormControl;
  }

  setValue(value: any) {
    this.field.value = value;
    this.percentageControl.setValue(value);
  }

  checkRange() {
    let value = this.percentageControl.value;

    if (value === undefined) {
      if (this.minPercentage !== undefined) {
        value = this.minPercentage;
      } else if (this.maxPercentage !== undefined) {
        value = this.maxPercentage;
      }
    } else {
      if ((this.minPercentage !== undefined && value < this.minPercentage)) {
        value = this.minPercentage;
      }
      if ((this.maxPercentage !== undefined && value > this.maxPercentage)) {
        value = this.maxPercentage;
      }
    }
    this.setValue(value);
  }
}
