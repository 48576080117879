import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private _loading = false;
  loadingStatus = new Subject();

  get loading(): boolean {
    return this._loading;
  }

  set loading(value: boolean) {
    this._loading = value;
    this.loadingStatus.next(value);
  }

  startLoading() {
    $('.loader-wrapper').show();
    this.loading = true;
  }

  stopLoading() {
    this.loading = false;
    $('.loader-wrapper').hide();
  }
}
