import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef, AfterViewChecked, AfterViewInit} from '@angular/core';
import { FieldService } from './../services/field.service';
import { Field } from './../models/field';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-location',
  template: `
  <div [formGroup]="group" style="display: inline;">
    <div [hidden]="this.hideAnywhere === true" *ngFor="let locgroup of this.location.controls;let i = index;" class="inline padding_right_5">
      <ng-container *ngIf="selected_inclusive[i]" class="">
        <span class="info-green" [hidden]="getHidden(i,linclusive)"> or </span>
        <span class="info-green"> in </span>
        <span class="info">{{ this.getText(i)}} </span>
        <ng-container class="inline" appDynamicField
          [field]="this.fields[i]"
          [group]="locgroup"
          [state]="state"
          [showFullSize]="showFullSize"
          (invalid)="parseValidation($event)">
        </ng-container>
        <a [hidden]="this.state === 'info'" class="menu-criteria p-l-1" href="javascript:void(0)" (click)="this.deleteLocation(i)">
          <i class="fa fa-trash" title="Delete this condition"></i>
        </a>
      </ng-container>
    </div>
    <div class="inline" [hidden]="hideSelect === true">
      <select id="{{ locationInId }}" class="select-custom" #selectOption (change)="addLocation(selectOption, true)" >
        <option value="">{{this.empty}}</option>
        <option *ngFor="let item of field.options" [value]="item.value">{{or + 'in ' + item.text}}</option>
      </select>
    </div>
    <span class="info" [hidden]="!(this.state === 'info' && linclusive === 0)">anywhere</span>
    <span class="">{{ this.with }}</span>
    <div [hidden]="this.hideException === true" *ngFor="let locgroup of this.location.controls;let i = index;" class="inline padding_right_5">
      <ng-container *ngIf="!selected_inclusive[i]">
        <span class="info-red" [hidden]="getHidden(i,lexclusive)"> or </span>
        <span class="info-red"> not in </span>
        <span class="info">{{ this.getText(i)}} </span>
        <ng-container class="inline" appDynamicField
          [field]="this.fields[i]"
          [group]="locgroup"
          [state]="state"
          [showFullSize]="showFullSize"
          (invalid)="parseValidation($event)">
        </ng-container>
        <a [hidden]="this.state === 'info'" class="menu-criteria p-l-1" href="javascript:void(0)" (click)="this.deleteLocation(i)">
          <i class="fa fa-trash" title="Delete this condition"></i>
        </a>
      </ng-container>
    </div>
    <div class="inline padding_right_5" [hidden]="hideSelect_not === true">
      <select id="{{ locationNotId }}" class="select-custom" #selectOptionNo (change)="addLocation(selectOptionNo, false)">
        <option value="">{{this.nempty}}</option>
        <option *ngFor="let item of field.options" [value]="item.value">{{nor + ' not in ' + item.text}}</option>
      </select>
    </div>
    <span class="info" [hidden]="!(this.state === 'info' && lexclusive === 0)">no exception</span>
  </div>
  `,
  styles: []
})
export class LocationComponent implements OnInit, AfterViewChecked, AfterViewInit {

  field: Field;
  group: FormGroup;
  state: string;
  fields: Field[] = [];
  selected: string[] = [];
  selected_inclusive: boolean[] = [];
  hideSelect = false;
  hideSelect_not = false;
  hideException = false;
  hideAnywhere = false;
  linclusive = 0;
  lexclusive = 0;
  deleted_name = '';
  empty = 'anywhere';
  or = '';
  nempty = 'no exception';
  nor = '';
  with = ' with ';
  value: any[];
  text = '';
  locationInId = 'locationIn' + uuid();
  locationNotId = 'locationNot' + uuid();
  @Input() showFullSize: boolean;
  @Output() invalid = new EventEmitter<any>();

  constructor(private fb: FormBuilder,
              private fieldService: FieldService,
              private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
    this.initLocation();
  }

  ngAfterViewInit() {
    this.value.forEach(loc => {
      let include = true;
      if (Object.keys(loc).indexOf('inclusive') > -1) {
        include = loc['inclusive'];
      }
      const option = Object.keys(loc).filter(k => k !== 'inclusive')[0];
      if (include) {
        $('#' + this.locationInId + ' option[value="' + option + '"]').wrap('<span>');
      } else {
        $('#' + this.locationNotId + ' option[value="' + option + '"]').wrap('<span>');
      }
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
    if (this.deleted_name) {
      this.parseValidation({field: this.deleted_name, message: ''});
      this.deleted_name = '';
    }
  }

  initLocation() {
    this.value = this.group.get(this.field.name).value;
    this.group.setControl(this.field.name, this.fb.array([]));
    this.value.forEach(loc => {
      let include = true;
      if (Object.keys(loc).indexOf('inclusive') > -1) {
        include = loc['inclusive'];
      }
      const option = Object.keys(loc).filter(k => k !== 'inclusive')[0];
      this.addLocation(option, include, loc[option]);
    });

    if (this.state === 'info') {
      this.hideSelect = true;
      this.hideSelect_not = true;
    }
  }

  get location() {
    return this.group.get(this.field.name) as FormArray;
  }

  addLocation(selectOption: any, include: boolean, value: any[] = []) {
    let option = '';
    if (typeof selectOption === 'string') {
      option = selectOption;
    } else {
      option = selectOption.value;
      selectOption.value = '';
    }
    this.selected.push(option);
    this.selected_inclusive.push(include);
    this.fields.push(this.getDatatype(option));
    this.location.push(this.fb.group({[option]: this.fb.control(value), ['inclusive']: this.fb.control(include)}));
    if (include === true) {
      this.or = ' or ';
      this.empty = 'or ...';
      this.linclusive = this.selected_inclusive.filter(s => s === include).length;
      this.hideSelect = ( this.linclusive === this.field.options.length);
      $('#' + this.locationInId + ' option[value="' + option + '"]').wrap('<span>');
    } else {
      this.nor = ' or ';
      this.nempty = 'or not ...';
      this.with = ' but ';
      this.lexclusive = this.selected_inclusive.filter(s => s === include).length;
      this.hideSelect_not = (this.lexclusive === this.field.options.length);
      $('#' + this.locationNotId + ' option[value="' + option + '"]').wrap('<span>');
    }
  }

  deleteLocation(index: number) {
    const include = this.selected_inclusive[index];
    const option = this.selected[index];
    this.selected.splice(index, 1);
    this.fields.splice(index, 1);
    this.selected_inclusive.splice(index, 1);
    this.location.removeAt(index);
    this.deleted_name = option + '_' + (include ? 'inclusive' : 'exclusive');
    if (include === true) {
      this.linclusive = this.selected_inclusive.filter(s => s === include).length;
      this.or = this.linclusive > 0 ? ' and ' : '';
      this.empty = this.linclusive === 0 ? 'anywhere' : 'or ...';
      this.hideSelect = false;
      $('#' + this.locationInId + ' option[value="' + option + '"]').unwrap();
    } else {
      this.lexclusive = this.selected_inclusive.filter(s => s === include).length;
      this.nor = this.lexclusive > 0 ? ' and ' : '';
      this.nempty = this.lexclusive === 0 ? 'no exception' : 'or not ...';
      this.with = this.lexclusive === 0 ? ' with ' : ' but ';
      this.hideSelect_not = false;
      $('#' + this.locationNotId + ' option[value="' + option + '"]').unwrap();
    }
  }

  getHidden(index: number, length: number) {
    const include = this.selected_inclusive[index];
    const arr = Object.assign([], this.selected_inclusive);
    const count = arr.splice(index).filter(s => s === include).length;
    if (length === count) {
      return true;
    }
    return false;
  }

  getDatatype(option: string) {
    let field: Field;
    this.fieldService.getField(option)
        .subscribe(data => field = data);
    return field;
  }

  getText(index: number) {
    return this.field.options.filter(o => o.value === this.selected[index])[0].text;
  }

  parseValidation(error: any) {
    this.invalid.emit(error);
  }

}
