import {FormGroup, FormArray, FormBuilder, FormControl} from '@angular/forms';
import { Field } from './../models/field';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-complex-object',
  template: `
  <div [formGroup]="formGroup" class="inline">
    <div class="inline pr-1 align-top" *ngFor="let field of flow;let i = index;">
      <ng-container class="inline"
          appDynamicField
          [field]="field"
          [group]="group"
          [state]="state"
          (invalid)="parseValidation($event)">
      </ng-container>
    </div>
    <div class="inline" formArrayName="values">
      <div *ngFor="let value of formGroup['controls'].values['controls'];let i = index;">
        <ng-container class="inline pr-1 align-top"
            *ngFor="let field of fields;"
            appDynamicField
            [field]="field"
            [group]="value"
            [state]="state"
            [showFullSize]="showFullSize"
            (invalid)="parseValidation($event)">
        </ng-container>
        <span [hidden]="i === formGroup['controls'].values['controls'].length - 1" class="info-green"> or </span>
        <a [hidden]="this.state === 'info' || i === 0" class="menu-criteria pr-1" href="javascript:void(0)" (click)="this.deleteArrayObject(i)">
          <i class="fa fa-trash" title="Delete this condition"></i>
        </a>
        <a [hidden]="this.state === 'info' || i !== formGroup['controls'].values['controls'].length - 1"
           class="menu-criteria pr-1"
           href="javascript:void(0)"
           (click)="this.addArrayObject()">
           <i class="fa fa-plus-square"></i>
        </a>
      </div>
    </div>
    <div class="inline pr-1">
      <ng-container *ngFor="let field of multiPnrFields;"
          appDynamicField
          [field]="field"
          [group]="group.get(this.name)"
          [state]="state"
          [showFullSize]="showFullSize"
          (invalid)="parseValidation($event)">
      </ng-container>
    </div>
  </div>
  `,
  styles: []
})
export class ComplexObjectComponent implements OnInit {

  @Input() name: string;
  @Input() fields: Field[];
  @Input() multiPnrFields: Field[];
  @Input() group: FormGroup;
  @Input() state: string;
  @Input() value: any[];
  @Input() showFullSize: boolean;
  @Output() invalid = new EventEmitter<any>();
  flow: Field[] = [];
  flag: number;
  values: FormArray;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.initObject();
    if (!this.value || this.value.length === 0) {
      this.mainObjectType.addControl('values', this.fb.array([this.createArrayObject()]));
      this.mainObjectType.addControl('multiPnrMode', this.fb.control(this.createMultiPnrObject()));
      this.addArrayObject();

    } else {
      Object.keys(this.value).forEach(v => {
        if ('multiPnrMode' === v) {
          this.mainObjectType.setControl('multiPnrMode', this.fb.control(this.value[v]));
        } else if ('values' === v) {
          for (let i = 0; i < this.value[v].length; i++) {
            const arrayObject = this.createArrayObject();
            arrayObject.patchValue(this.value[v][i]);
            this.businessType.push(arrayObject);
          }
        }
      });
    }
  }

  initObject() {
    const index = this.fields.filter(f => f.type === 'flow').findIndex(fl => fl.label.indexOf('|') > -1);
    const field_flow = this.fields.splice(0, (index + 1));
    field_flow.map(f => f.label).forEach(f => {
        const t = f.split('|');
        this.flow.push({ label: t[0], type: 'flow'});
        if (t.length > 1 || t[1] !== '') {
          this.fields.unshift({ label: t[1], type: 'flow'});
        }
      });
  }

  get mainObjectType() {
    return this.group.get(this.name) as FormGroup;
  }

  get businessType() {
    return this.group.controls[this.name]['controls']['values'] as FormArray;
  }

  get formGroup() {
    return this.group.get(this.name) as FormGroup;
  }

  createMultiPnrObject() {
    let val = '';
    this.multiPnrFields.forEach((f) => {
      if (f.type !== 'flow') {
        val = f.value;
        if (Array.isArray(val)) {
          val = val[0];
        }
      }
    });
    return val;
  }

  createArrayObject() {
    const arrayObject = this.fb.group({});
    this.fields.forEach((f) => {
      if (f.type !== 'flow') {
        arrayObject.addControl(f.name, this.fb.control(f.value));
      }
    });
    return arrayObject;
  }

  addArrayObject() {
    this.businessType.push(this.createArrayObject());
  }

  deleteArrayObject(index: number) {
    this.businessType.removeAt(index);
  }

  parseValidation(error: any) {
    this.invalid.emit(error);
  }
}
