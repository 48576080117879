import {AfterViewInit, Component, ElementRef, forwardRef, Input, OnDestroy, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AriInputDirective} from '../../ari-input.directive';
import {DataUtils} from '../../../../../../core/utils/data/data-utils';


@Component({
  selector: 'app-ari-checkbox-group-input',
  templateUrl: './checkbox-group-input.component.html',
  styleUrls: ['./checkbox-group-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxGroupInputComponent),
      multi: true
    }
  ]
})
export class CheckboxGroupInputComponent extends AriInputDirective implements AfterViewInit {

  @Input()
  public label: string;

  @Input()
  public options: string[];

  @Input()
  public text: string[];

  @Input()
  public disabled: string | boolean;

  @Input()
  public align: boolean;

  @ViewChild('selection')
  public viewSelection: ElementRef;

  constructor() {
    super();
  }

  ngAfterViewInit(): void {
    if (typeof this.disabled !== 'boolean') {
      this.disabled = this.disabled === 'disabled';
    }

    const inputElements = <HTMLCollection> this.viewSelection.nativeElement.getElementsByTagName('input');
    let optionValueID: string;
    let checkboxElement: Element;
    for (const optionValue of this.value) {
      optionValueID = this.getOptionId(optionValue);
      checkboxElement = inputElements.namedItem(optionValueID);
      if (checkboxElement) {
        (<HTMLInputElement>checkboxElement).checked = true;
      }
    }
  }

  public writeValue(value: string[]) {
    if (value !== undefined && value !== null) {
      this.value = value.filter(v => this.options.indexOf(v) > -1);
    } else {
      this.value = [];
    }
  }

  public toggleOptionValue(option: string): void {
    const optionIsAlreadySelected = (<string[]>this.value).indexOf(option) > -1;
    if (optionIsAlreadySelected) {
      this.deleteOptionFromValue(option);
    } else {
      this.addOptionToValue(option);
    }
    this.updateParentComponentValue(this.value);
  }

  public getOptionId(option: string): string {
    return DataUtils.removeSpaceFromString(this.name + option);
  }

  private isArray(obj: any) {
    return Array.isArray(obj);
  }

  private deleteOptionFromValue(option: string): void {
    const optionIndex = (<string[]>this.value).indexOf(option);
    (<string[]>this.value).splice(optionIndex, 1);
  }

  private addOptionToValue(option: string): void {
    this.value.push(option);
  }
}
