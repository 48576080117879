import { Field } from './../models/field';
import { Component, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-date',
  template: `
    <ng-container *ngIf="this.state === 'info'; else infoTemplate">
      <span class="info">{{this.text}}</span>
    </ng-container>
    <ng-template #infoTemplate>
      <div class="inline" [formGroup]="group">
        <input class="input-text-custom date" type="text"
        #date
        id="{{field.name}}"
        [formControlName]="field.name"
        [placeholder]="field.label"
        (change)="parseValidation(date.value)"
        (focusout)="parseValidation(date.value)"
        (focus)="onFocus(date)"
        [class.required]="this.isRequired === true"
        [class.isInvalid]="this.isInvalid === true"
        autocomplete="off" />
      </div>
    </ng-template>
  `,
  styles: []
})
export class DateComponent implements OnInit, AfterViewInit {

  field: Field;
  group: FormGroup;
  state: string;
  prop: string;
  isArray = false;
  isRequired = false;
  messageRequired = '';
  isInvalid = false;
  text: string;
  @Output() invalid = new EventEmitter<any>();

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    const index = this.field.validations.findIndex(fv => fv.name === 'required');
    if (index > -1 ) {
      this.isRequired = true;
      this.messageRequired = this.field.validations[index].message;
    }
    this.initDate();
  }

  ngAfterViewInit() {
    const date = <any>$('#' + this.field.name);
    date.daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      minDate: 1901
    });
  }

  initDate() {
    this.text = this.group.get(this.field.name).value;
  }

  parseValidation(value: string) {
    this.isInvalid = false;
    const error = { field: this.field.name , message : '' };
    if (value.length === 0 || value === '') {
      this.isInvalid = true;
      error.message = this.messageRequired;
    }
    this.invalid.emit(error);
  }

  onFocus(input: HTMLInputElement) {
    input.classList.remove('isInvalid');
    this.isInvalid = false;
    this.invalid.emit({ field: this.field.name , message : '' });
  }

}
