import {Field} from '../models/field';
import {Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef, AfterViewChecked} from '@angular/core';
import {FormGroup, FormArray, FormControl} from '@angular/forms';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';

@Component({
  selector: 'app-input',
  template: `
    <ng-container *ngIf="this.state === 'info'; else editTemplate">
      <span class="info">{{this.text}}</span>
    </ng-container>
    <ng-template #editTemplate>
      <div class="inline" [formGroup]="group">
        <ng-container *ngIf="this.field.multiple === true; else elseTemplate">
          <input class="input-text-custom"
              #inputArray
              [placeholder]="field.label"
              [type]="field.inputType"
              [value]="this.text"
              (input)="this.parseValue(inputArray.value, true)"
              (focusout)="this.parseValue(inputArray.value, true)"
              (focus)="onFocus(inputArray)"
              [class.required]="this.isRequired === true"
              [class.isInvalid]="this.isInvalid === true"
              [class.textarea2]="this.showFullSize === true"
              [attr.disabled]="(this.isDisabled ?'': null)"
          />
        </ng-container>
        <ng-template #elseTemplate>
          <ng-container *ngIf="this.field.inputType === 'number'; else singleInputTemplate">
            <input class="input-text-custom number"
              #inputNumber
              [formControlName]="field.name"
              [placeholder]="field.label"
              [type]="field.inputType"
              [value]="this.field.value"
              (focusout)="parseValidationNumber()"
              (focus)="onFocus(inputNumber)"
              [class.required]="(this.isRequired === true)"
              [class.isInvalid]="(this.isInvalid === true)"
              [class.textarea2]="(this.showFullSize === true)"
              [attr.disabled]="(this.isDisabled ?'': null)"
              [attr.min]="(this.field.min ? this.field.min : null)"
              [attr.max]="(this.field.max ? this.field.max : null)"
            />
          </ng-container>
          <ng-template #singleInputTemplate>
              <input class="input-text-custom"
                #singleInput
                [formControlName]="field.name"
                [placeholder]="field.label"
                [type]="field.inputType"
                [value]="this.text"
                (input)="parseSingleValue(singleInput.value, true)"
                (focusout)="parseSingleValue(singleInput.value, true)"
                (focus)="onFocus(singleInput)"
                [class.required]="(this.isRequired === true)"
                [class.isInvalid]="(this.isInvalid === true)"
                [attr.disabled]="(this.isDisabled ?'': null)"
                [class.textarea2]="(this.showFullSize === true)"
              />
          </ng-template>
        </ng-template>
        <span *ngIf="this.isRequired === true" class="text-danger asterix">*</span>
      </div>
    </ng-template>
  `,
  styles: [
    'input[type="text"]{ text-transform: uppercase;}'
  ]
})
export class InputComponent implements OnInit, AfterViewChecked {
  
  field: Field;
  group: FormGroup;
  state: string;
  value: any[] = [];
  text: string;
  isRequired = false;
  isDisabled = false;
  messageRequired = '';
  isInvalid = false;
  isPassengerName = false;
  isLocation = false;
  otherPassengerNameOption = '';
  LOCATION_OPTIONS = ['port', 'city', 'region', 'country'];
  @Input() showFullSize: boolean;
  @Output() invalid = new EventEmitter<any>();

  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
    this.initInput();
    const index = this.field.validations.findIndex(fv => fv.name === 'required');
    if (index > -1) {
      this.isRequired = true;
      this.messageRequired = this.field.validations[index].message;
    }
    if (this.field.label === 'Passenger Name' && ['inclusions', 'exclusions'].indexOf(this.field.name) > -1) {
      this.isPassengerName = true;
      this.otherPassengerNameOption = this.field.name === 'inclusions' ? 'exclusions' : 'inclusions';
    }
    if(this.field.label === 'Deposit EMD')
    {
      this.isDisabled = true;  
    }
    if (this.LOCATION_OPTIONS.indexOf(this.field.name) > -1) {
      this.isLocation = true;
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
    if (this.isPassengerName && (this.group.get(this.otherPassengerNameOption).value.length > 0 || this.value.length > 0 )) {
      this.parseValidation();
    }else if(this.field.name === "dppNameMatchScore" ){
      let errorMessage;
      if(this.group.get(this.field.name).value < 85 || this.group.get(this.field.name).value > 100){
        errorMessage = "Range should be 85-100";
      }
      this.parseValidationRange(errorMessage);

      // restricting value to 2 decimal points
      let numString = this.group.get(this.field.name).value.toString();
      if(numString.indexOf('.') >= 0){
        let value = numString.slice(0, numString.indexOf(".") + 3);
        if(value.charAt(value.length - 1 ) === '0'){
          if(value.charAt(value.length - 2 ) === '0'){
            value = parseFloat(value).toFixed(2);
          }else{
            value = parseFloat(value).toFixed(1);
          }
          this.group.get(this.field.name).setValue(value);
        }
        else{          
          this.group.get(this.field.name).setValue(parseFloat(value));
        }
      }
    }
  }

  parseValidationRange(errorMessage?: string) {
    const name = this.determineName();
    const error = {field: name, message: ''};
    if (isNotNullOrUndefined(errorMessage)) {
      this.isInvalid = true;
      error.message = errorMessage;
    }else{
      this.isInvalid = false;
    }
      this.invalid.emit(error);
  }


  initInput() {
    let value = this.group.get(this.field.name).value;
    this.text = value.toString().replace('[', '').replace(']', '').replace(/"/g, '').replace(/\\/g, '').trim();
    if (this.field.name === 'flightNumber' || this.field.name === 'groupSize') {
      value = this.getRangeData(value);
      this.parseRangeData(value);
    } else if (this.field.multiple === true) {
      this.parseValue(this.text);
    } else {
      this.parseSingleValue(this.text);
    }
  }

  getRangeData(value: any) {
    let range = '';
    if (value) {
      value.forEach(o => {
        range += range !== '' ? ',' : '';
        range += o['from'];
        range += o['from'] !== o['to'] ? '-' + o['to'] : '';
      });
    }
    return range;
  }

  parseSingleValue(input: any, validate: boolean = false) {
    const value = input.trim().toUpperCase();
    this.group.get(this.field.name).setValue(value);
    this.text = value;
    if (validate) {
      this.parseValidation();
    }
  }

  parseValue(input: any, validate: boolean = false) {
    if (this.field.name === 'flightNumber' || this.field.name === 'groupSize') {
      this.parseRangeData(input, validate);
    } else {
      this.value = input.split(',');
      const valueArray = [];
      for (let x = 0; x < this.value.length; x++) {
        const value = this.value[x].trim().toUpperCase();
        if (value && value !== '' && valueArray.indexOf(value) < 0) {
          valueArray.push(value);
        }
      }
      this.value = valueArray;
      this.group.get(this.field.name).setValue(this.value);
      this.text = this.value.join(', ');
      if (validate) {
        this.parseValidation();
      }
    }
  }

  parseRangeData(input: string, validate: boolean = false) {
    const text = [];
    const rangeValueArray = [];
    let errorMessage;
    this.value = input.split(',');
    for (let x = 0; x < this.value.length; x++) {
      const splitRangeValue = this.value[x].split('-');
      const from = this.parseNAN(splitRangeValue[0].trim());
      const to = this.parseNAN(splitRangeValue.length > 1 ?
        splitRangeValue[1].trim() : splitRangeValue[0].trim());
      if (from > to) {
        errorMessage = 'First element in the range should be less than the second element';
      }
      const value = { 'from': from, 'to': to };
      rangeValueArray.push(value);
      text.push((splitRangeValue.length > 1 ? from + '-' + to : from));
    }
    this.value = rangeValueArray;
    this.group.get(this.field.name).setValue(this.value);
    this.text = text.join(', ');
    if (validate) {
      this.parseValidation(errorMessage);
    }
  }

  parseValidation(errorMessage?: string) {
    const name = this.determineName();
    const error = {field: name, message: ''};
    if (isNotNullOrUndefined(errorMessage)) {
      this.isInvalid = true;
      error.message = errorMessage;
    } else {
      this.isInvalid = false;
      const trim = this.text.replace(',', '').replace(/ +/g, '').trim();
      const value = this.field.multiple === true ? this.text.split(',') : [this.text];
      if (value.length > 0 && trim !== '') {
        const pattern = this.field.validations.find(val => val.name === 'pattern');
        let isInvalid = false;
        if (pattern) {
          value.forEach(v => {
            if (!v.trim().match(pattern.validator)) {
              isInvalid = true;
            }
          });
        }
        if (isInvalid) {
          error.message = pattern.message;
          error.message += this.field.multiple === true && !/separated by( a)? commas?/.test(error.message) ? ', separated by commas' : '';
          this.isInvalid = true;
        } else if (this.isPassengerName) {
          const otherPax: string[] = this.group.get(this.otherPassengerNameOption).value.toString().replace(' ', '').split(',');
          if (value.some((r: string) => otherPax.indexOf(r.trim()) > -1)) {
            this.isInvalid = true;
            error.message = 'The same lastName/firstName cannot be included and excluded at the same time';
          }
        }
      } else if (this.isPassengerName && this.group.get(this.otherPassengerNameOption).value.length <= 0) {
        this.isInvalid = true;
        this.isRequired = true;
        error.field = error.field + 'Required';
        error.message = 'Please enter a lastName/firstName in one of these fields';
      } else if (this.isPassengerName && this.group.get(this.otherPassengerNameOption).value.length > 0) {
        error.field = error.field + 'Required';
        this.isRequired = false;
      } else {
        this.isInvalid = true;
        error.message = this.messageRequired;
        if (this.isPassengerName) {
          error.field = error.field + 'Required';
          this.isRequired = true;
        }
      }
    }
    this.invalid.emit(error);
  }

  parseValidationNumber() {
    let value = +this.inputControl.value;

    if ((this.field.min && value < this.field.min) || (!value && this.field.min)) {
      value = this.field.min;
    }
    if ((this.field.max && value > this.field.max) || (!value && this.field.max)) {
      value = this.field.max;
    }

    this.inputControl.setValue(value);
  }

  determineName() {
    if (this.isPassengerName) {
      return 'paxName';
    } else if (this.isLocation) {
      const inclusive = this.group.get('inclusive').value;
      return this.field.name + '_' + (inclusive ? 'inclusive' : 'exclusive');
    } else {
      return this.field.name;
    }
  }

  onFocus(input: HTMLInputElement) {
    input.classList.remove('isInvalid');
    this.isInvalid = false;
    this.invalid.emit({ field: this.field.name , message : '' });
  }

  get inputArray() {
    return this.group.get(this.field.name) as FormArray;
  }

  get inputControl() {
    return this.group.get(this.field.name) as FormControl;
  }

  private parseNAN(value: any) {
    return isNaN(value) ? value : Number(value);
  }
}
