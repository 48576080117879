import {AppConfigService} from '../../app-config.service';
import {Product} from '../models/product';
import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ProductUI} from '../models/product.ui';
import {productsConfiguration} from '../configuration/product';
import {LogUtils} from '../utils/logging/log-utils';
import {CarrierService} from './carrier.service';
import {AuthService} from './auth.service';
import {AuthUtils} from '../utils/auth.utils';
import {ProductCodeEnum} from '../utils/data/product-code-enum';
import {ApiResponseObject} from '../../shared/model/api-response-object';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private products: Product[] = [];
  private productUis: ProductUI[] = [];
  private productCarriers: {};

  public productsEventEmitter = new EventEmitter<ProductUI[]>();
  public excludeCarriers = [];

  constructor(private environment: AppConfigService,
              private httpClient: HttpClient,
              private authService: AuthService,
              private carrierService: CarrierService) {
  }

  public activateProduct(productUi: ProductUI, global = false): Observable<ApiResponseObject> {
    const product = this.buildCurrentProduct(productUi);
    product.active = true;
    return isNotNullOrUndefined(productUi.productId) && productUi.productId > 0
      ? this.updateProduct(product, global)
      : this.initializeProduct(product, global);
  }

  public deactivateProduct(productUi: ProductUI, global = false): Observable<ApiResponseObject> {
    const product = this.buildCurrentProduct(productUi);
    product.active = false;
    product.deployActive = false;
    product.shadowMode = true;

    return this.updateProduct(product, global);
  }

  public activateProductDeploy(productUi: ProductUI, global = false): Observable<ApiResponseObject> {
    const product = this.buildCurrentProduct(productUi);
    product.deployActive = true;

    return this.updateProduct(product, global);
  }

  public deactivateProductDeploy(productUi: ProductUI, global = false): Observable<ApiResponseObject> {
    const product = this.buildCurrentProduct(productUi);
    product.deployActive = false;

    return this.updateProduct(product, global);
  }

  public findProducts(): Observable<ApiResponseObject> {
    return this.httpClient.get<ApiResponseObject>(
      this.environment.config.apiBaseEndpoint + '/' + this.authService.getActiveCarrierCode() + '/products'
    );
  }

  public getProducts(): Product[] {
    return this.products;
  }

  public getProductUis(): ProductUI[] {
    return this.productUis;
  }

  public setProductUis(productUis: ProductUI[]) {
    this.productUis = productUis;
  }

  public getProduct(code: ProductCodeEnum): Observable<ApiResponseObject> {
    return this.httpClient.get<ApiResponseObject>(
      this.environment.config.apiBaseEndpoint + '/' + this.authService.getActiveCarrierCode() + '/products/' + code
    );
  }

  public setProductCarriers(productCounts: {}) {
    this.productCarriers = productCounts;
  }

  public hasProducts(): number {
    return this.products.length;
  }

  public mergeProducts(products: Product[]): ProductUI[] {
    const productByCode = products.reduce((obj, item) => {
      if (item.carrierCode === this.authService.getActiveCarrierCode()) {
        obj[item.code] = item;
      }
      return obj;
    }, {});

    const availableProducts = productsConfiguration.map(obj =>
      productByCode.hasOwnProperty(obj.code) ?
        Object.assign(obj, {
          productId: productByCode[obj.code].productId,
          code: productByCode[obj.code].code,
          active: productByCode[obj.code].active,
          deployActive: productByCode[obj.code].deployActive,
          shadowMode: productByCode[obj.code].shadowMode
        }) : obj);

    LogUtils.info(['ProductsByCode', productByCode]);
    LogUtils.info(['Products', availableProducts]);

    this.setProductUis(availableProducts);
    return availableProducts;
  }

  public mergeProductCarriers(products: Product[]): {} {
    const productCarriers = productsConfiguration.reduce((obj, ep) => { obj[ep.code] = []; return obj; }, {});

    products.filter(product =>
      productCarriers.hasOwnProperty(product.code)
      && this.excludeCarriers.indexOf(product.carrierCode) < 0
      && this.carrierService.getCarriers().filter(c => c.carrierCode === product.carrierCode).length > 0
    ).forEach(product => productCarriers[product.code].push(product.carrierCode));

    LogUtils.info(['ProductCarriers', productCarriers]);
    this.setProductCarriers(productCarriers);
    return productCarriers;
  }

  private buildCurrentProduct(productUi: ProductUI): Product {
    const product = new Product();
    product.code = productUi.code;
    product.name = productUi.name;
    product.active = productUi.active;
    product.deployActive = productUi.deployActive;
    product.carrierCode = this.authService.getActiveCarrierCode();

    return product;
  }

  private updateProduct(product: Product, global = false): Observable<ApiResponseObject> {
    return this.httpClient.put<ApiResponseObject>(
      this.environment.config.apiBaseEndpoint + '/' + (global ? AuthUtils.AMADEUS_CARRIER_CODE : this.authService.getActiveCarrierCode()) + '/products/' + product.code,
      product
    );
  }

  private initializeProduct(product: Product, global = false): Observable<ApiResponseObject> {
    return this.httpClient.post<ApiResponseObject>(
      this.environment.config.apiBaseEndpoint + '/' + (global ? AuthUtils.AMADEUS_CARRIER_CODE : this.authService.getActiveCarrierCode()) + '/products',
      product
    );
  }
}
