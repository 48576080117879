import {ProductCodeEnum} from '../utils/data/product-code-enum';
import {ProductNameEnum} from '../utils/data/product-name-enum';

export class Product {
  productId: number;
  code: ProductCodeEnum;
  name: ProductNameEnum;
  active = false;
  deployActive = false;
  shadowMode = false;
  carrierCode: string;
}
