import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppConfigService} from '../../app-config.service';
import {Observable} from 'rxjs';
import {Carrier} from '../models/carrier';
import {AuthService} from './auth.service';
import {ApiResponseObject} from '../../shared/model/api-response-object';

@Injectable({
  providedIn: 'root'
})
export class CarrierService {
  private carriers: Carrier[] = [];

  constructor(private environment: AppConfigService,
              private httpClient: HttpClient,
              private authService: AuthService) {
  }

  public findCarriers(): Observable<ApiResponseObject> {
    return this.httpClient.get<ApiResponseObject>(
      this.environment.config.apiBaseEndpoint + '/' + this.authService.getAuthenticatedUser().getOrganization() + '/carriers'
    );
  }

  public getCarriers(): Carrier[] {
    return this.carriers;
  }

  public setCarriers(carriers: Carrier[]) {
    this.carriers = carriers;
  }

  public hasCarriers(): number {
    return this.carriers.length;
  }
}
