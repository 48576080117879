import {Injectable} from '@angular/core';
import {Observable, Subject, Subscription, timer} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdleTimeoutService {
  private count = 0;
  private serviceId: string = 'idleTimeoutSvc-' + Math.floor(Math.random() * 10000);
  private timeoutSeconds = 5;
  private timerSubscription: Subscription;
  private timer: Observable<number>;
  private resetOnTrigger = false;
  public timeoutExpired: Subject<number> = new Subject<number>();

  constructor() {
  }

  public startTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    this.timer = timer(this.timeoutSeconds * 1000);
    this.timerSubscription = this.timer.subscribe(n => {
      this.timerComplete(n);
    });
  }

  public stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  public resetTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    this.timer = timer(this.timeoutSeconds * 1000);
    this.timerSubscription = this.timer.subscribe(n => {
      this.timerComplete(n);
    });
  }

  private timerComplete(n: number) {
    this.timeoutExpired.next(++this.count);

    if (this.resetOnTrigger) {
      this.startTimer();
    }
  }
}
