import { Input, Directive } from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';

@Directive()
export abstract class AriInputDirective implements ControlValueAccessor {

  @Input()
  public name: string;

  @Input()
  public label: string;

  public value: any;

  // Incoming data wire
  public writeValue(value: any) {
    this.value = value;
  }

  public registerOnTouched(fn: any) {
    // No implementation
  }

  // Outgoing data wire
  public registerOnChange(fn: any) {
    this.setValue = fn;
  }

  public updateParentComponentValue(valueFromView: any) {
    this.value = valueFromView;
    this.setValue(this.value);
  }

  public getValue(): any {
    return this.value;
  }

  /*
  * Reference variable for Angular's value setter for value changes.
  * Angular's value setter sets the ngModel of the user/parent component ngModel
  */
  private setValue = (newValue: any) => {
  }
}
