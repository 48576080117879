import {isDevMode} from '@angular/core';

export class LogUtils {

  static info(message: any) {
    if (isDevMode()) {
      console.log(message);
    } else if (window.location.search.match(/[?]DEBUG=1/)) {
      /** TODO
       * Identify the information needed to be logged
       * in non development environment
       */
      console.log(message);
    }
  }

  static error(message: any, exception?: any) {
    if (isDevMode()) {
      console.error(new Error(exception.toString().trim().length > 0 ? exception : message));
    } else {
      /** TODO
       * Identify the error information needed to be logged
       * in non development environment.
       */
      console.error(message);
    }
  }

  static throw(message: string) {
    if (isDevMode()) {
      throw new Error(message);
    } else {
      /** TODO
       * Identify specific information that are can be appropriately thrown
       * in non development environment.
       */
      throw message;
    }
  }
}
