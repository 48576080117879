export enum ModalCodeEnum {
  GTLCOMP = 'GTLCOMP',
  GTLINTC = 'GTLINTC',
  GTLCONF = 'GTLCONF',
  GTLRECL = 'GTLRECL',
  ATLCOMP = 'ATLCOMP',
  ATLINTC = 'ATLINTC',
  ATLCONF = 'ATLCONF',
  ATLRECL = 'ATLRECL',
  FSNCONF = 'FSNCONF',
  FSNINTC = 'FSNINTC',
  DPPCONF = 'DPPCONF',
  DPPINTC = 'DPPINTC',
  DPSCONF = 'DPSCONF',
  DPSINTC = 'DPSINTC',
  NSMCONF = 'NSMCONF',
  NSMPCNF = 'NSMPCNF',
  NSMECNF = 'NSMECNF',
  RULEDIT = 'RULEDIT',
  BUSDTLS = 'BUSDTLS',
  UNSVDCS = 'UNSVDCS',
  GTLAGGR = 'GTLAGGR',
  GTLNAME = 'GTLNAME',
  GTLDEPO = 'GTLDEPO',
  GTLFPAY = 'GTLFPAY',
  GTLTKTG = 'GTLTKTG',
  SDWMODE = 'SDWMODE',
  GNRLSTS = 'GNRLSTS',
  SWICODE = 'SWICODE',
  TTTCONF = 'TTTCONF',
  TTTINTC = 'TTTINTC',
  HDGCONF = 'HDGCONF',
  HDGINTC = 'HDGINTC',
  WLTCONF = 'WLTCONF',
  CHRCONF = 'CHRCONF'
}
