import { CrudEnum } from './data/crud.enum';
import { ProductCodeEnum } from './data/product-code-enum';
import { AutomationServicesCodeEnum } from './data/automation-services-code-enum';
import { Permissions } from '../models/permissions';
import { isDevMode } from '@angular/core';

export class AuthUtils {

  public static readonly AMADEUS_CARRIER_CODE = '1A';

  public static readonly C = [CrudEnum.C];
  public static readonly R = [CrudEnum.R];
  public static readonly U = [CrudEnum.U];
  public static readonly D = [CrudEnum.D];
  public static readonly MANAGE = [CrudEnum.C, CrudEnum.U, CrudEnum.D];
  public static readonly ALL = [CrudEnum.C, CrudEnum.R, CrudEnum.U, CrudEnum.D];

  public static readonly RULE_EDITOR = 'RE';
  public static readonly BUSINESS_DATA = 'DL';
  public static readonly PRODUCT_CONFIG = 'PC';
  public static readonly SHARED_CONFIG = 'SC';
  public static readonly RECALCULATION = 'RC';
  public static readonly COMPLIANCE = 'CC';
  public static readonly AUTOMATION_SERVICES = 'AS';

  public static readonly SHARED_PERMISSIONS_TYPES = [
    AuthUtils.RULE_EDITOR,
    AuthUtils.SHARED_CONFIG,
    AuthUtils.BUSINESS_DATA
  ];
  public static readonly PRODUCTS_PERMISSIONS_TYPES = [
    AuthUtils.RULE_EDITOR,
    AuthUtils.PRODUCT_CONFIG,
    AuthUtils.COMPLIANCE,
    AuthUtils.RECALCULATION
  ];
  public static readonly ALL_PERMISSIONS_TYPES = [
    AuthUtils.RULE_EDITOR,
    AuthUtils.SHARED_CONFIG,
    AuthUtils.PRODUCT_CONFIG,
    AuthUtils.COMPLIANCE,
    AuthUtils.RECALCULATION,
    AuthUtils.BUSINESS_DATA
  ];
  public static readonly ALL_AUTOMATION_SERVICES_TYPES = [
    AuthUtils.AUTOMATION_SERVICES
  ];

  public static readonly SEPARATOR = '_';
  public static readonly ROLE_PREFIX = 'ROLE' + AuthUtils.SEPARATOR;

  public static readonly COMMON = 'COMMON';
  public static readonly REPORT = 'REPORT';
  public static readonly SEARCH = 'SEARCH';

  public static readonly READ = 'READ';
  public static readonly CREATE = 'CREATE';
  public static readonly UPDATE = 'UPDATE';
  public static readonly DELETE = 'DELETE';

  public static readonly ACTIVE = 'ACTIVATE';
  public static readonly READ_ONLY = 'READ_ONLY';

  public static readonly LSS_APP_PREFIX = 'RR' + AuthUtils.SEPARATOR;

  public static readonly ALL_PRODUCT_ROLES = [
    ProductCodeEnum.ATL,
    ProductCodeEnum.DPP,
    ProductCodeEnum.DPS,
    ProductCodeEnum.FSN,
    ProductCodeEnum.GTL,
    ProductCodeEnum.HDG,
    ProductCodeEnum.NSM,
    ProductCodeEnum.TTT,
    ProductCodeEnum.WLT,
    ProductCodeEnum.CHR
  ];

  public static readonly ALL_AUTOMATION_SERVICES_ROLES = [
    AutomationServicesCodeEnum.FMC,
    AutomationServicesCodeEnum.PYC
  ];

  /*
    ARIPRO-2129 - WARNING:
    It looks like we could re-use the above array and only add the COMMON item to it,
    but in reality, it'll introduce a very sneaky bug only visible in --prod mode which
    will prevent users to access the RuleEditor.
   */
  public static readonly ALL_RULE_EDITOR_ROLES = [
    ProductCodeEnum.ATL,
    ProductCodeEnum.DPP,
    ProductCodeEnum.DPS,
    ProductCodeEnum.FSN,
    ProductCodeEnum.GTL,
    ProductCodeEnum.HDG,
    ProductCodeEnum.NSM,
    ProductCodeEnum.TTT,
    ProductCodeEnum.WLT,
    AuthUtils.COMMON
  ];

  public static computeCrudPermissions(permissions: Permissions): string[] {
    const computedPermissions = [];
    if (permissions.hasCrud(CrudEnum.C)) {
      computedPermissions.push(permissions.getRole() + AuthUtils.SEPARATOR + permissions.getPrefix() + AuthUtils.SEPARATOR + AuthUtils.CREATE);
    }
    if (permissions.hasCrud(CrudEnum.R)) {
      computedPermissions.push(permissions.getRole() + AuthUtils.SEPARATOR + permissions.getPrefix() + AuthUtils.SEPARATOR + AuthUtils.READ);
    }
    if (permissions.hasCrud(CrudEnum.U)) {
      computedPermissions.push(permissions.getRole() + AuthUtils.SEPARATOR + permissions.getPrefix() + AuthUtils.SEPARATOR + AuthUtils.UPDATE);
    }
    if (permissions.hasCrud(CrudEnum.D)) {
      computedPermissions.push(permissions.getRole() + AuthUtils.SEPARATOR + permissions.getPrefix() + AuthUtils.SEPARATOR + AuthUtils.DELETE);
    }

    return computedPermissions;
  }

  public static isTestOrDev(): boolean {
    return isDevMode() && /^http:$/.test(location.protocol);
  }

  public static getDevToken(): string {
    return 'eyJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImFyaS1kZXZlbG9wbWVudC11c2VyQGFtYWRldXMuY29tIiwibG9naW4iOiJkZXZlbG9wb' +
      'WVudFVzZXIiLCJvcmdhbml6YXRpb24iOiIxQSIsInBlcm1pc3Npb25zX3YyIjp7fSwiaWF0IjoxMjM0NTY3ODkwLCJleHAiOjQzMjEwOTg3' +
      'NjV9.wHrW5UnuL_ig9uZMxk_tqPwoh4dypzLYwxtCEo7pj9Q';
  }
}
