import { Option } from './../models/option';
import { FieldService } from './../services/field.service';
import { Field } from './../models/field';
import { Component, OnInit, AfterViewInit, Output, EventEmitter, Input, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { v4 as uuid } from 'uuid';


@Component({
  selector: 'app-select',
  template: `
    <div [formGroup]="group" class="inline">
      <ng-container *ngIf="this.state === 'info'; else editTemplate">
        <ng-container *ngIf="field.name === 'allowMixedClasses'; else infoClasseTemplate">
          <span class="info">{{ text_mixed }}</span>
        </ng-container>
        <ng-template #infoClasseTemplate>
          <span class="info">{{ text }}</span>
          <div class="inline" *ngIf="text_coupon !== ''">
            <span>.</span>
            <span class="pl-2" [innerHTML]="FHEoption"></span>
            <span class="info">{{ text_coupon }}</span>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #editTemplate>
        <ng-container *ngIf="field.multiple === false; else elseTemplate">
          <ng-container *ngIf="field.name !== 'allowMixedClasses'; else classesTemplate">
            <select class="select-custom" #select (focus)="onFocus(select)" (change)="this.setValue(select.value)"
                    [class.required]="isRequired === true"
                    [formControlName]="field.name">
              <option *ngFor="let item of field.options" [value]="this.getValue(item)">{{item.text}}</option>
            </select>
            <span *ngIf="isRequired === true" class="text-danger asterix">*</span>
          </ng-container>
          <ng-template #classesTemplate>
            <div class="inline padding_right_5">
              <select class="select-custom" #selectMixed (change)="this.changeMixedClasses(selectMixed.value)">
                  <option *ngFor="let item of field.options" [value]="this.getValue(item)" [selected]="this.selected === this.getValue(item)">{{item.text}}</option>
              </select>
            </div>
            <div class="inline" [hidden]="hideMixedClasses === true">
              <ng-container class="inline" *ngIf="!hideMixedClasses"
                  appDynamicField
                  [field]="fieldMixed"
                  [group]="group"
                  [state]="state"
                  [showFullSize]="showFullSize"
                  (invalid)="parseValidation($event)">
              </ng-container>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #elseTemplate>
          <select class="select-custom-multiple inline" id="{{ selectMultipleId }}" #selectMultiple data-style="btn-light" multiple="multiple"
                    [formControlName]="field.name"
                    [class.required]="isRequired === true"
                    (focus)="onFocus(selectMultiple)"
                    (focusout)="checkValidation(selectMultiple)"
                    (change)="checkValidation(selectMultiple)" >
            <option *ngFor="let item of field.options" [value]="this.getValue(item)">{{item.text}}</option>
          </select>
          <span class="text-danger asterix">*</span>
          <ng-container *ngIf="this.isTicketElem === true && checkFHE === true">
            <div class="inline pl-2">
              <span [innerHTML]="FHEoption"></span>
              <select class="select-custom" id="selectFHE" #selectFHE (change)="isRestricted(selectFHE.value)" >
                <option *ngFor="let item of fieldFHE.options" [selected]="selectedFHE" [value]="this.getValue(item)">{{item.text}}</option>
              </select>
              <ng-container *ngIf="allowCoupon === true">
                <select class="select-custom-multiple inline" multiple="multiple" #selectCoupon id="{{ selectCouponId }}" style="max-height:23px;"
                          data-style="btn-light"
                          [formControlName]="fieldCoupon.name"
                          [class.required]="isRequired === true"
                          (focus)="onFocus(selectCoupon)"
                          (focusout)="checkValidation(selectCoupon)"
                          (change)="checkValidation(selectCoupon)">
                  <option *ngFor="let item of fieldCoupon.options" [value]="this.getValue(item)">{{item.text}}</option>
                </select>
                <span class="text-danger asterix">*</span>
              </ng-container>
            </div>
          </ng-container>
        </ng-template>
      </ng-template>
    </div>
  `,
  styles: []
})
export class SelectComponent implements OnInit, AfterViewInit, AfterViewChecked {

  field: Field;
  group: FormGroup;
  state: string;
  value: any[];
  text = '';
  text_mixed = '';
  text_coupon = '';
  hideMixedClasses = true;
  fieldMixed: Field;
  fieldFHE: Field;
  fieldCoupon: Field;
  FHEoption = 'For the FHE, the <b>Coupon Matching Status</b>';
  selected: string;
  isBoolean = false;
  isArray = false;
  isRequired = false;
  isTicketElem = false;
  checkFHE = false;
  selectedFHE = false;
  allowCoupon = false;
  isInvalid = false;
  selectMultipleId = 'selectMultiple' + uuid();
  selectCouponId = 'selectCoupon' + uuid();
  @Input() showFullSize: boolean;
  @Output() invalid = new EventEmitter<any>();

  constructor(private fb: FormBuilder,
    private fieldService: FieldService,
    private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
    this.isRequired = this.hasRequiredValidator() || this.field.multiple;

    const parent = Object.keys(this.group.parent.controls)[0];
    if (this.field.name === 'ticketElements' && parent === 'ticketElementCouponMatchingStatus') {
      this.isTicketElem = true;
      this.getField('restrictMatchingStatus', 'fieldFHE');
      this.getField('couponMatchingStatus', 'fieldCoupon');
    }

    if (this.formControl.value != null) {
      this.initValue();
    }

    this.checkMultiSelectValue();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  initValue() {
    let value = this.formControl.value + '';
    if ((<string>this.formControl.value).toString().match('^true|false$')) {
      this.isBoolean = true;
      this.value = [this.formControl.value];
    } else if (this.field.multiple === true || Array.isArray(this.field.value)) {
      this.isArray = true;
      value = value.toString().replace('[', '').replace(']', '').replace(/"/g, '').replace(/\\/g, '').trim();
      this.value = value.split(',');
    } else {
      this.value = value.split(',');
    }
    this.setValue(this.value);

    if (this.field.name === 'allowMixedClasses') {
      this.getField('classes', 'fieldMixed');
      this.changeMixedClasses(value, this.value);
      this.selected = value;
      if (value !== 'All' && value !== 'isNotAllowed') {
        this.selected = 'isSubsetAllowed';
      }
    }

    if (this.group.contains('couponMatchingStatus')) {
      this.selectedFHE = true;
      this.isRestricted(true);
    }

    this.renderText();
  }

  get formControl() {
    return this.group.get(this.field.name) as FormControl;
  }

  get formCoupon() {
    return this.group.get(this.fieldCoupon.name) as FormControl;
  }

  renderText() {
    this.value.forEach(v => {
      const opt = this.field.options.find(f => f.value === v || f.text === v);
      if (typeof(opt) !== 'undefined')  {
        const prev = this.text === '' ? '' : this.text + ',';
        this.text =  prev + opt.text;
      }
    });
    if (this.text !== '' && this.field.name === 'allowMixedClasses' ) {
      this.text_mixed = this.text;
    } else if (this.selected === 'isSubsetAllowed') {
      this.text_mixed = this.field.options.filter(o => o.value === this.selected)[0].text;
      this.text_mixed += ' ' + this.value.join(',');
    }

    if (this.group.contains('couponMatchingStatus')) {
      this.text_coupon += ' ' + this.fieldFHE.options.filter(o => o.value === true)[0].text;
      this.formCoupon.value.forEach((v, i) => {
        const text = this.fieldCoupon.options.filter(op => op.value === v)[0].text;
        this.text_coupon += i > 0 ? ', ' + text : ' ' + text;
      });
    }
  }

  setValue(value: any) {
    if (this.isBoolean) {
      this.value = [JSON.parse(value)];
      this.formControl.setValue(this.value[0]);
    } else if (this.field.name === 'multiPnrMode' && Array.isArray(value)) {
      this.value = value;
      this.formControl.setValue(this.value[0]);
    } else if (this.isArray && !Array.isArray(value) && this.field.name !== 'multiPnrMode') {
      this.value = [value];
      this.formControl.setValue(this.value);
    } else if (!this.isArray && Array.isArray(value) && this.field.name === 'noShowSegType') {
      this.value = value;
      this.formControl.setValue(this.value[0]);
    } else {
      this.value = value;
      this.formControl.setValue(this.value);
    }
  }

  getValue(item: Option) {
    if (typeof(item.value) !== 'undefined') {
      return item.value;
    }
    return item.text;
  }

  ngAfterViewInit() {
    if (this.field.multiple) {
      (<any>$('.select-custom-multiple')).selectpicker({tickIcon: ''});
    }
  }

  changeMixedClasses(option: string, value: any[] = []) {
    if (option === 'All' || option === 'isNotAllowed') {
      this.value = [option];
      this.formControl.setValue([option]);
      this.hideMixedClasses = true;
    } else {
      this.formControl.setValue(value);
      this.value = value;
      this.hideMixedClasses = false;
    }
  }

  getField(name: string, fieldname: string) {
    this.fieldService.getField(name)
        .subscribe(data => {
          const field = data;
          if (!this.isTicketElem) {
            field.name = this.field.name;
            field.value = [];
          }
          this[fieldname] = field;
        });
  }

  checkValidation(input: HTMLInputElement) {
    const fieldname = input.id.indexOf('selectMultiple') > -1 ? 'field' : 'fieldCoupon';
    const value = input.id.indexOf('selectMultiple') > -1 ? this.formControl.value : this.formCoupon.value;
    const error = { field: this[fieldname].name , message : '' };
    if (this.isRequired === true && value.length === 0) {
      this.isInvalid = true;
      error.message = this[fieldname].label + ' is required';
    } else {
      this.isInvalid = false;
    }

    const parent = input.parentElement;
    if (this.isInvalid === true) {
      parent.classList.add('isInvalid');
    } else {
      parent.classList.remove('isInvalid');
    }

    this.checkMultiSelectValue();

    this.invalid.emit(error);
  }

  onFocus(input: HTMLInputElement) {
    input.classList.remove('isInvalid');
    this.isInvalid = false;
    this.invalid.emit({ field: this.field.name , message : '' });
  }

  parseValidation(error: any) {
    this.invalid.emit(error);
  }

  isRestricted(value: any) {
    if (value === 'true' || value === true) {
      this.selectedFHE = true;
      if (!this.group.contains('couponMatchingStatus')) {
        this.group.addControl(this.fieldCoupon.name, this.fb.control([]));
      }
      this.allowCoupon = true;
      setTimeout(() => {
        (<any>$('#' + this.selectCouponId)).selectpicker({tickIcon: ''});
      }, 1);
    } else {
      this.selectedFHE = false;
      this.allowCoupon = false;
      if (this.group.contains('couponMatchingStatus')) {
        this.group.removeControl(this.fieldCoupon.name);
      }
      $('button[data-id="selectCoupon"]').parent().remove();
    }
  }

  checkMultiSelectValue() {
    if (this.isTicketElem === true && this.formControl.value.findIndex(s => s === 'FHE') > -1) {
      this.checkFHE = true;
    } else {
      this.checkFHE = false;
      this.isRestricted(false);
    }
  }

  private hasRequiredValidator(): boolean {
    if (this.field.validations === null || this.field.validations === undefined) {
      return false;
    } else {
      const requiredValidatorIdx = this.field.validations
        .findIndex(fv => fv.name === 'required');
      return requiredValidatorIdx > -1;
    }
  }

}
