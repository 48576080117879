import {ValidatorFn, AbstractControl, FormArray} from '@angular/forms';

export class ArrayValidators {
  static readonly NAME_MATCHING_METHODS_LIMIT = 4;

  public static maxLength(max: number, filter?, extraDetails?: {}): ValidatorFn | any {
    return (control: AbstractControl[]) => {
      if (!(control instanceof FormArray)) {
        return null;
      }

      let controls = control.controls;
      if (filter) {
        controls = control.controls.filter(subControl => filter(subControl));
      }

      const errorDetails = Object.assign(
        {requiredLength: max, actualLength: controls.length},
        extraDetails ? extraDetails : {}
      );

      return controls.length > max ? {maxArrayLength: errorDetails} : null;
    };
  }

  public static minLength(min: number, filter?, extraDetails?: {}): ValidatorFn | any {
    return (control: AbstractControl[]) => {
      if (!(control instanceof FormArray)) {
        return null;
      }

      let controls = control.controls;
      if (filter) {
        controls = control.controls.filter(subControl => filter(subControl));
      }

      const errorDetails = Object.assign(
        {requiredLength: min, actualLength: controls.length},
        extraDetails ? extraDetails : {}
      );

      return controls.length > min ? {minArrayLength: errorDetails} : null;
    };
  }

  public static betweenLength(min: number, max: number, filter?, extraDetails?: {}): ValidatorFn | any {
    return (control: AbstractControl[]) => {
      if (!(control instanceof FormArray)) {
        return null;
      }

      let controls = control.controls;
      if (filter) {
        controls = control.controls.filter(subControl => filter(subControl));
      }

      const errorDetails = Object.assign(
        {requiredMinLength: min, requiredMaxLength: max, actualLength: controls.length},
        extraDetails ? extraDetails : {}
      );

      return controls.length < min || controls.length > max ? {betweenArrayLength: errorDetails} : null;
    };
  }
}
