export enum ModalNameEnum {
  GTLCOMP = 'GroupTimeLimitComplianceModal',
  GTLINTC = 'GroupTimeLimitConfigurationModal',
  GTLCONF = 'GroupTimeLimitInternalControlModal',
  GTLRECL = 'GroupTimeLimitRecalculationModal',
  ATLCOMP = 'AmadeusTimeLimitComplianceModal',
  ATLINTC = 'AmadeusTimeLimitInternalControlModal',
  ATLCONF = 'AmadeusTimeLimitConfigurationModal',
  ATLRECL = 'AmadeusTimeLimitRecalculationModal',
  FSNCONF = 'FictitiousNamesConfigurationModal',
  FSNINTC = 'FictitiousNamesInternalControlModal',
  DPPCONF = 'DuplicatePnrConfigurationModal',
  DPSCONF = 'DuplicateSegmentConfigurationModal',
  DPSINTC = 'DuplicateSegmentInternalControlModal',
  NSMCONF = 'NoShowManagerConfigurationModal',
  NSMPCNF = 'NoShowManagerProductConfigurationModal',
  NSMECNF = 'NoShowManagerEticketConfigurationModal',
  RULEDIT = 'RuleEditConfiguration',
  BUSDTLS = 'BusinessDataListConfiguration',
  UNSVDCS = 'UnsavedChangesModal',
  GTLAGGR = 'GTLAgreementTimeLimitModal',
  GTLNAME = 'GTLNameTimeLimitModal',
  GTLDEPO = 'GTLDepositTimeLimitModal',
  GTLFPAY = 'GTLFinalPaymentTimeLimitModal',
  GTLTKTG = 'GTLTicketingTimeLimitModal',
  SDWMODE = 'ShadowModeModal',
  GNRLSTS = 'GeneralSettingModal',
  SWICODE = 'SwitchCarrierModal',
  TTTCONF = 'TimeToThinkConfigurationModal',
  TTTINTC = 'TimeToThinkInternalControlModal',
  HDGCONF = 'HiddenGroupsConfigurationModal',
  HDGINTC = 'HiddenGroupsInternalControlModal',
  WLTCONF = 'WaitlistConfigurationModal',
  CHRCONF = 'ChurningConfigurationModal',
}
