<div #selection class="inline">
  <span class="pl-0" *ngIf="label">{{label}}</span>
  <span *ngFor="let option of options; let i = index;">
    <br *ngIf="align" />
    <input type="checkbox"
           class="align-middle"
           [id]="getOptionId(option)"
           (click)="toggleOptionValue(option)"
           [disabled]="disabled"
           [checked]="isArray(value) && value.indexOf(option) > -1" />
    <ng-container *ngIf="text; else elseTemplate">
        <span class="info align-middle">{{ text[i] }}</span>
    </ng-container>
    <ng-template #elseTemplate>
        <span class="info align-middle">{{ option }}</span>
    </ng-template>
  </span>
</div>


