import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../confirm-modal.component';
import {ModalItems} from '../../../../core/models/modalitems';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {

  constructor(private modalService: NgbModal) { }

  public confirm(
    title: string,
    message: string,
    specializedText: boolean = false,
    isErrorText: boolean = false,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmModalComponent, {backdrop : 'static'});
    modalRef.componentInstance.componentType = title;
    modalRef.componentInstance.specializedText = specializedText;
    modalRef.componentInstance.isErrorText = isErrorText;
    modalRef.componentInstance.displayMessage = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    return modalRef.result;
  }

  public display(
    title: string,
    header: string[],
    items: ModalItems[],
    btnOkText: string = 'OK') {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.componentType = title;
    modalRef.componentInstance.headers = header;
    modalRef.componentInstance.items = items;
    modalRef.componentInstance.btnOkText = btnOkText;
  }

}
