import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router, RouterState, RouterStateSnapshot} from '@angular/router';
import {take, map, filter, pairwise} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AuthService} from '../../core/services/auth.service';
import {AppConfigService} from '../../app-config.service';
import * as uaParser from 'ua-parser-js';

@Component({
  selector: 'app-not-found',
  template: `
    <div class="d-flex justify-content-center mt-5 mb-5">
      <div class="page-container" *ngIf="state$ | async as errorState">
        <div class="page-title-box mb-3">
          <h4 class="page-title color-blue">{{ buildErrorTitle(errorState) }}</h4>
        </div>

        <div>
          <p>The requested URL <strong>{{ buildRequestedUrl(errorState) }}</strong> was not found on this server.</p>
        </div>

        <div class="d-flex justify-content-between mt-5">
          <div class="mb-3">
            <button type="button" class="btn btn-primary" [routerLink]="['/home']">Home</button>
          </div>
          <div class="mb-3">
            <button type="button"
                    class="btn btn-light"
                    data-toggle="collapse"
                    data-target="#collapseDetails"
                    aria-expanded="false"
                    aria-controls="collapseDetails"
            >Details</button>
          </div>
        </div>

        <div id="collapseDetails" class="collapse">
          <h5 class="color-blue-light mt-4">Session information</h5>

          <div class="row">
            <div class="col col-4 text-right details-header">
              <div>TIMESTAMP</div>
              <div>LSS LOGIN</div>
              <div>CARRIER CODE</div>
              <div>ENVIRONMENT</div>
            </div>

            <div class="col col-8">
              <div>{{ now | date:'yyyy-MM-ddTHH:mm:ssZZZZZ' }}</div>
              <div>{{ authService.getAuthenticatedUser().getUsername() }}</div>
              <div>{{ authService.getActiveCarrierCode() }}</div>
              <div>{{ appConfig.config.environment | uppercase }}</div>
            </div>
          </div>

          <h5 class="color-blue-light mt-4">Browser information</h5>

          <div class="row">
            <div class="col col-4 text-right details-header">
              <div>BROWSER</div>
              <div>ENGINE</div>
              <div>OS</div>
            </div>

            <div class="col col-8">
              <div>{{ userAgent.browser.name }} ({{ userAgent.browser.version }})</div>
              <div>{{ userAgent.engine.name }} ({{ userAgent.engine.version }})</div>
              <div>{{ userAgent.os.name }} {{ userAgent.os.version }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    '.page-container { width: 600px; }',
    '.details-header { color: #777; }',
    'ul { list-style-type: none; }'
  ]
})
export class NotFoundComponent implements OnInit {
  now: Date;
  state$: Observable<object>;
  userAgent = uaParser(window.navigator.userAgent);

  private state: RouterState;
  private snapshot: RouterStateSnapshot;
  private root: ActivatedRouteSnapshot;

  constructor(private router: Router,
              private activeRoute: ActivatedRoute,
              public appConfig: AppConfigService,
              public authService: AuthService) {
    this.state = router.routerState;
    this.snapshot = this.state.snapshot;
    this.root = this.snapshot.root;
  }

  ngOnInit() {
    this.now = new Date();
    this.state$ = this.activeRoute.paramMap.pipe(map(() => window.history.state));
  }

  buildErrorTitle(errorState: {}): string {
    let errorMessage = 'Page not found';
    const errorCode: number = errorState.hasOwnProperty('status') ? errorState['status'] : 404;

    if (errorState.hasOwnProperty('message')) {
      errorMessage = errorCode === 404 ? errorState['message'] : 'Unknown error';
    }

    return errorCode + ' - ' + errorMessage;
  }

  buildRequestedUrl(errorState: {}): string {
    return errorState.hasOwnProperty('url') ? errorState['url'] : (
      this.root.url.length > 0 ? `/${this.root.url.join('/')}` : this.snapshot.url
    );
  }
}
