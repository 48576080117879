import {Pipe, PipeTransform} from '@angular/core';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';

@Pipe({
  name: 'asFormGroup'
})
export class AsFormGroupPipe implements PipeTransform {
  transform(item: AbstractControl | FormControl): FormGroup {
    if (!item) { return null; }

    return item as FormGroup;
  }
}
