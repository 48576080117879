<div class="criterion section_config">
  <div *ngIf="description && description.length > 0" class="field-description">
    <i>{{description}}</i></div>
  <span>{{label}}</span>
  <ul class="list-group">
    <li class="list-group-item"
        *ngFor="let value of getValue(); index as i">
      <div class="container">
        <div class="row">
          <div class="col-1">
          <span class="font-weight-bold badge badge-info">
          {{i + 1}}
        </span>
          </div>
          <div class="col-6 border border-grey rounded">
            <div class="row">
              <div class="col-9">
                <span class="color-blue">{{getValueLabel(value)}}</span>
              </div>
              <div *ngIf="state !== 'info'"
                   class="col-3">
                <a
                  [class.visible]="i !== 0"
                  [class.invisible]="i === 0"
                  class="menu-criteria pr-1"
                  (click)="moveValueUp(value)"><i class="fa  fa-arrow-circle-up info"></i></a>
                <a
                  [class.visible]="i < getValueLength()"
                  [class.invisible]="i === (getValueLength() - 1)"
                  class="menu-criteria"
                  (click)="moveValueDown(value)"><i class="fa  fa-arrow-circle-down info"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
