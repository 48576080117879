export class RegexUtils {
  static readonly UUID_V4 = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
  static readonly WHITELISTED_DOMAINS = /.*(:8[0-9]{3}|ari(uat)?[.]lcl|tus[.]lcl|amadeus(ri)?[.](com|net))/;

  static readonly SPLIT_SEARCH_INPUT = / *, */ig;
  static readonly SPLIT_DATE_RANGE_INPUT = / *(?:[,]|[tT][oO]) */;

  static readonly VALIDATOR_DEFAULT_CURRENCY = /^[A-Z]{3}$/;
  static readonly VALIDATOR_RFICCODE_DEPOSIT = /^(?!.*?([A-Z]).*?\1)[A-Z](?:,[A-Z])*$/;
  static readonly VALIDATOR_RFISCCODE_DEPOSIT = /^[A-Z0-9]{3}(, *[A-Z0-9]{3})*$/;
  static readonly VALIDATOR_TOLERANCE_PERCENTAGE1 = /\d-.*\d/;
  static readonly VALIDATOR_TOLERANCE_PERCENTAGE2 = /\d-.*\d/;

  static readonly FLAG_SEGMENT_IS_ACKNOWLEDGED = /_?segmentIsAcknowledged$/;
  static readonly FLAG_FARE_FAMILY_AND_FARE_CALCULATION = /^(fareFamily|fareCalculation)$/;

  static readonly VALIDATOR_EMAIL = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
  static readonly VALIDATOR_EMAIL_MULTI = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}(, *[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,})*$/i;
  static readonly VALIDATOR_QUEUE = /^[A-Z0-9]{9}\/\d{1,3}(C\d{1,3})?(,[A-Z0-9]{9}\/\d{1,3}(C\d{1,3})?)*$/;
  static readonly VALIDATOR_ALT_QUEUE = /^\d{1,2}C\d{1,2}$/;
  static readonly VALIDATOR_SK_TYPE = /^[A-Z]{4}$/;
  static readonly VALIDATOR_SK_TYPE_MULTI = /^[A-Z]{4}(, *[A-Z]{4})*$/;
  static readonly VALIDATOR_PASSENGER_TITLE_MULTI = /^[A-Z]{2,}(, *[A-Z]{2,})*$/;
  static readonly VALIDATOR_SK_MESSAGE_TYPE = /^[A-Z]{2,4}$/;
  static readonly VALIDATOR_AIRLINE_ACCOUNTING_CODE_MULTI = /^([0-9]{3})(, *[0-9]{3})*$/;
  static readonly VALIDATOR_CID = /^[A-Z0-9]{3}(, *[A-Z0-9]{3})*$/;
  static readonly VALIDATOR_DP = /^[A-Z0-9]{9}\/\d{1,3}(C\d{1,3})?(,[A-Z0-9]{9}\/\d{1,3}(C\d{1,3})?)*$/;
  static readonly VALIDATOR_UPTO_3_OFFICES = /^[A-Z0-9*]{9}(,[A-Z0-9*]{9}){0,2}$/;
  static readonly VALIDATOR_CLASS_HIERARCHY = /^[A-Z]{1}(, ?[A-Z]{1})*$/;
  static readonly VALIDATOR_STRING_CUSTOM_PATTERN = /^[A-Z?]+$/;
  static readonly VALIDATOR_SINGLE_NAME = /^[a-zA-Z- ]+$/;
  static readonly VALIDATOR_FULL_NAME = /^[A-Za-z- ]+\/[A-Za-z- ]+$/;
  static readonly VALIDATOR_CARRIER_CODE = /^[A-Z0-9]{2}$/;
  static readonly VALIDATOR_CARRIER_CODE_MULTI = /^[A-Z0-9]{2}(, *[A-Z0-9]{2})*$/;
  static readonly VALIDATOR_COUNTRY_CODE_MULTI = /^[A-Z]{2}(, *[A-Z]{2})*$/;
  static readonly VALIDATOR_CITY_CODE_3 = /^[A-Z]{3}$/;
  static readonly VALIDATOR_LOG_LOCATION = /^(([\\]{2}|[A-Z]:[\\])[^\\\/]+([\\][^\\\/]+|[\\]$)*|\/[^\\\/]+(\/[^\\\/]+|\/$)*)$/;
  static readonly VALIDATOR_SHORT_TIME = /^[0-9]{2}:[0-9]{2}$/;
  static readonly VALIDATOR_LONG_TIME = /^[0-9]{2}:[0-9]{2}:[0-9]{2}$/;
  static readonly VALIDATOR_UPPERCASE_STRING = /[^a-z]/;
  static readonly VALIDATOR_FREE_TEXT = /^[A-Z0-9!?%*:_,=()\[\]\/ ]+$/;
  static readonly VALIDATOR_FREE_TEXT_MULTI = /^[A-Z0-9!?%*:_,=()\n\[\]\/ ]+$/;
  static readonly VALIDATOR_ADDITIONAL_SSR_MESSAGE = /^[A-Z0-9\s.\-/]+$/;
  static readonly VALIDATOR_WAITLIST_STATUS_CODE = /^(CX|HX|IX|NO|OX|PX|UC|UN|XL|XX|ID|IG|SA|GK|GL|GN|AG|CN|CQ|FF|FG|FR|GI|IN|NA|NN|NZ|PN|RG|SG|ST|TN|UM|XR|HN)(,\s*(CX|HX|IX|NO|OX|PX|UC|UN|XL|XX|ID|IG|SA|GK|GL|GN|AG|CN|CQ|FF|FG|FR|GI|IN|NA|NN|NZ|PN|RG|SG|ST|TN|UM|XR|HN))*$/;
  static readonly VALIDATOR_ALL_WAITLIST_STATUS_CODE = /^(AW|CL|CU|CW|DW|HL|IW|LF|LG|LL|PA|PB|PC|PD|PE|PG|TL|US|UU|CX|HX|IX|NO|OX|PX|UC|UN|XL|XX|ID|IG|SA|GK|GL|GN|AG|CN|CQ|FF|FG|FR|GI|IN|NA|NN|NZ|PN|RG|SG|ST|TN|UM|XR|HN)(,\s*(AW|CL|CU|CW|DW|HL|IW|LF|LG|LL|PA|PB|PC|PD|PE|PG|TL|US|UU|CX|HX|IX|NO|OX|PX|UC|UN|XL|XX|ID|IG|SA|GK|GL|GN|AG|CN|CQ|FF|FG|FR|GI|IN|NA|NN|NZ|PN|RG|SG|ST|TN|UM|XR|HN))*$/;

  static readonly VALIDATOR_GTL_COMPLIANCE_TIME_LIMIT_TYPE = /^(Agreement|Deposit|Final Payment|Name|Ticketing)$/;
  static readonly VALIDATOR_GTL_COMPLIANCE_TIME_LIMIT_CODE = /^(AGT|DEP|PAY|NME|TKT)$/;
  static readonly VALIDATOR_GTL_COMPLIANCE_WAIVER = /^[A-Z0-9]{2} (AGREEMENT|DEPOSIT|FINAL PAYMENT|NAME|TTL) WAIVED$/;
  static readonly VALIDATOR_GTL_COMPLIANCE_WAIVER_CODE = /^(AGREEMENT|DEPOSIT|FINAL PAYMENT|NAME|TTL)$/;
}
