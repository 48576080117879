import {FieldManagerModule} from './modules/product/field-manager/field-manager.module';
import {CoreModule} from './core/core.module';
import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AppConfigService} from './app-config.service';
import {JwtModule, JWT_OPTIONS} from '@auth0/angular-jwt';
import {HeaderComponent} from './shared/components/header/header.component';
import {FooterComponent} from './shared/components/footer/footer.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MockPermissionsInterceptor} from './core/interceptors/mock-permissions.interceptor';
import {LoaderInterceptor} from './core/interceptors/loader.interceptor';
import {LoaderComponent} from './shared/components/loader/loader.component';
import {RequestInterceptor} from './core/interceptors/request.interceptor';
import {ToastrModule} from 'ngx-toastr';
import {AuthService} from './core/services/auth.service';
import {AuthModule, TokenValidationService} from 'angular-auth-oidc-client';

import {IdleTimeoutService} from './core/services/idle-timeout.service';
import {ConfirmationDialogService} from './shared/components/modal/service/confirm-modal.service';
import {JwtOptionsFactory} from './core/factory/jwt-options.factory';
import {DataStorageService} from './core/services/data-storage.service';

const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    AppRoutingModule,
    AuthModule.forRoot(),
    BrowserAnimationsModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useClass: JwtOptionsFactory
      }
    }),
    FieldManagerModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
      countDuplicates: true
    })
  ],
  providers: [
    AuthService,
    DataStorageService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService]
    },
    TokenValidationService,
    {provide: HTTP_INTERCEPTORS, useClass: MockPermissionsInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true},
    IdleTimeoutService,
    ConfirmationDialogService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
