import {ModalUI} from '../models/modal.ui';
import {ModalNameEnum} from '../utils/data/modal-name-enum';
import {ModalCodeEnum} from '../utils/data/modal-code-enum';

export const modalsConfiguration: ModalUI[] = [
  {
    modalId: 1,
    name: ModalNameEnum.GTLCOMP,
    code: ModalCodeEnum.GTLCOMP,
    displayMessage: 'You are about to create/update the GTL compliance configuration!',
    componentType: 'Group Time Limits Configuration'
  },
  {
    modalId: 2,
    name: ModalNameEnum.GTLCONF,
    code: ModalCodeEnum.GTLCONF,
    displayMessage: 'You are about to create/update the GTL product configuration!',
    componentType: 'Group Time Limits Configuration'
  },
  {
    modalId: 3,
    name: ModalNameEnum.GTLINTC,
    code: ModalCodeEnum.GTLINTC,
    displayMessage: 'You are about to create/update the GTL internal control configuration!',
    componentType: 'Group Time Limits Configuration'
  },
  {
    modalId: 4,
    name: ModalNameEnum.GTLRECL,
    code: ModalCodeEnum.GTLRECL,
    displayMessage: 'You are about to create/update the GTL recalculation configuration!',
    componentType: 'Group Time Limits Configuration'
  },
  {
    modalId: 5,
    name: ModalNameEnum.ATLCOMP,
    code: ModalCodeEnum.ATLCOMP,
    displayMessage: 'You are about to create/update the ATL compliance configuration!',
    componentType: 'Amadeus Time Limits Configuration'
  },
  {
    modalId: 6,
    name: ModalNameEnum.ATLCONF,
    code: ModalCodeEnum.ATLCONF,
    displayMessage: 'You are about to create/update the ATL product configuration!',
    componentType: 'Amadeus Time Limits Configuration'
  },
  {
    modalId: 7,
    name: ModalNameEnum.ATLINTC,
    code: ModalCodeEnum.ATLINTC,
    displayMessage: 'You are about to create/update the ATL internal control configuration!',
    componentType: 'Amadeus Time Limits Configuration'
  },
  {
    modalId: 8,
    name: ModalNameEnum.ATLRECL,
    code: ModalCodeEnum.ATLRECL,
    displayMessage: 'You are about to create/update the ATL recalculation configuration!',
    componentType: 'Amadeus Time Limits Configuration'
  },
  {
    modalId: 9,
    name: ModalNameEnum.DPPCONF,
    code: ModalCodeEnum.DPPCONF,
    displayMessage: 'You are about to create/update the DPP product configuration!',
    componentType: 'Duplicate PNR Configuration'
  },
  {
    modalId: 10,
    name: ModalNameEnum.DPSCONF,
    code: ModalCodeEnum.DPSCONF,
    displayMessage: 'You are about to create/update the DPS product configuration!',
    componentType: 'Duplicate Segments Configuration'
  },
  {
    modalId: 11,
    name: ModalNameEnum.DPSINTC,
    code: ModalCodeEnum.DPSINTC,
    displayMessage: 'You are about to create/update the DPS internal control configuration!',
    componentType: 'Duplicate Segments Configuration'
  },
  {
    modalId: 12,
    name: ModalNameEnum.FSNCONF,
    code: ModalCodeEnum.FSNCONF,
    displayMessage: 'You are about to create/update the FSNP product control configuration!',
    componentType: 'Fictitious Names Configuration'
  },
  {
    modalId: 13,
    name: ModalNameEnum.FSNINTC,
    code: ModalCodeEnum.FSNINTC,
    displayMessage: 'You are about to create/update the FSNP internal control configuration!',
    componentType: 'Fictitious Names Configuration'
  },
  {
    modalId: 14,
    name: ModalNameEnum.NSMCONF,
    code: ModalCodeEnum.NSMCONF,
    displayMessage: 'You are about to create/update the NSM internal control configuration!',
    componentType: 'NoShow Manager Configuration'
  },
  {
    modalId: 15,
    name: ModalNameEnum.NSMECNF,
    code: ModalCodeEnum.NSMECNF,
    displayMessage: 'You are about to create/update the NSM internal control configuration!',
    componentType: 'NoShow Manager Configuration'
  },
  {
    modalId: 16,
    name: ModalNameEnum.NSMPCNF,
    code: ModalCodeEnum.NSMPCNF,
    displayMessage: 'You are about to create/update the NSM product control configuration!',
    componentType: 'NoShow Manager Configuration'
  },
  {
    modalId: 17,
    name: ModalNameEnum.BUSDTLS,
    code: ModalCodeEnum.BUSDTLS,
    displayMessage: 'You are about to create/update a business data criteria!',
    componentType: 'Business Data List'
  },
  {
    modalId: 18,
    name: ModalNameEnum.RULEDIT,
    code: ModalCodeEnum.RULEDIT,
    displayMessage: 'You are about to create/update a rule!',
    componentType: 'Rule Configuration'
  },
  {
    modalId: 19,
    name: ModalNameEnum.GTLAGGR,
    code: ModalCodeEnum.GTLAGGR,
    displayMessage: 'Are you sure you want to turn off this time limit type?',
    componentType: 'Turn off Agreement Limit Type for Group Time Limits'
  },
  {
    modalId: 20,
    name: ModalNameEnum.GTLNAME,
    code: ModalCodeEnum.GTLNAME,
    displayMessage: 'Are you sure you want to turn off this time limit type?',
    componentType: 'Turn off Name Limit Type for Group Time Limits'
  },
  {
    modalId: 21,
    name: ModalNameEnum.GTLDEPO,
    code: ModalCodeEnum.GTLDEPO,
    displayMessage: 'Are you sure you want to turn off this time limit type?',
    componentType: 'Turn off Deposit Limit Type for Group Time Limits'
  },
  {
    modalId: 22,
    name: ModalNameEnum.GTLFPAY,
    code: ModalCodeEnum.GTLFPAY,
    displayMessage: 'Are you sure you want to turn off this time limit type?',
    componentType: 'Turn off Final Payment Limit Type for Group Time Limits'
  },
  {
    modalId: 23,
    name: ModalNameEnum.GTLTKTG,
    code: ModalCodeEnum.GTLTKTG,
    displayMessage: 'Are you sure you want to turn off this time limit type?',
    componentType: 'Turn off Ticketing Limit Type for Group Time Limits'
  },
  {
    modalId: 24,
    name: ModalNameEnum.SDWMODE,
    code: ModalCodeEnum.SDWMODE,
    displayMessage: 'Are you sure you want to turn on shadow mode?',
    componentType: 'Turn On Shadow Mode'
  },
  {
    modalId: 25,
    name: ModalNameEnum.UNSVDCS,
    code: ModalCodeEnum.UNSVDCS,
    displayMessage: 'Are you sure you want to continue?',
    componentType: 'Unsaved Changes will be lost!'
  },
  {
    modalId: 26,
    name: ModalNameEnum.GNRLSTS,
    code: ModalCodeEnum.GNRLSTS,
    displayMessage: 'You are about to create/update a general settings!',
    componentType: 'General Settings'
  },
  {
    modalId: 26,
    name: ModalNameEnum.SWICODE,
    code: ModalCodeEnum.SWICODE,
    displayMessage: 'Any other Revenue Integrity Desktop tab or window' +
      ' will be reloaded and as a result, any unsaved work will be lost.',
    componentType: 'Switch Active Carrier'
  },
  {
    modalId: 27,
    name: ModalNameEnum.TTTCONF,
    code: ModalCodeEnum.TTTCONF,
    displayMessage: 'You are about to create/update the TTT product configuration!',
    componentType: 'Time to Think Configuration'
  },
  {
    modalId: 28,
    name: ModalNameEnum.TTTINTC,
    code: ModalCodeEnum.TTTINTC,
    displayMessage: 'You are about to create/update the TTT internal control configuration!',
    componentType: 'Time to Think Configuration'
  },
  {
    modalId: 29,
    name: ModalNameEnum.HDGCONF,
    code: ModalCodeEnum.HDGCONF,
    displayMessage: 'You are about to create/update the HDG product configuration!',
    componentType: 'Hidden Groups Configuration'
  },
  {
    modalId: 30,
    name: ModalNameEnum.HDGINTC,
    code: ModalCodeEnum.HDGINTC,
    displayMessage: 'You are about to create/update the HDG internal control configuration!',
    componentType: 'Hidden Groups Configuration'
  },
  {
    modalId: 31,
    name: ModalNameEnum.WLTCONF,
    code: ModalCodeEnum.HDGCONF,
    displayMessage: 'You are about to create/update the USM product configuration!',
    componentType: 'Waitlist Configuration'
  },
  {
    modalId: 32,
    name: ModalNameEnum.CHRCONF,
    code: ModalCodeEnum.CHRCONF,
    displayMessage: 'You are about to create/update the CHR product configuration!',
    componentType: 'Churning Configuration'
  }
];
