import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LoaderService} from '../services/loader.service';


@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  activeRequests = 0;

  /**
   * URLs for which the loading screen should not be enabled
   */
  skipUrls = [
    '/assets/app-config',
    '/home',
  ];

  constructor(private loaderService: LoaderService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let displayLoaderScreen = true;

    for (const skipUrl of this.skipUrls) {
      if (new RegExp(skipUrl).test(request.url)) {
        displayLoaderScreen = false;
        break;
      }
    }

    if (displayLoaderScreen) {
      if (this.activeRequests === 0) {
        this.loaderService.startLoading();
      }
      this.activeRequests++;

      return next.handle(request).pipe(
        finalize(() => {
          this.activeRequests--;
          if (this.activeRequests === 0) {
            this.loaderService.stopLoading();
          }
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
