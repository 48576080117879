import {CrudEnum} from '../utils/data/crud.enum';

export class Permissions {
  private role: string;
  private prefix: string;
  private crudSet: CrudEnum[];

  constructor(role: string, prefix: string, crudSet: CrudEnum[]) {
    this.role = role;
    this.prefix = prefix;
    this.crudSet = crudSet;
  }

  public getRole(): string {
    return this.role;
  }

  public getPrefix(): string {
    return this.prefix;
  }

  public getCrudSet(): CrudEnum[] {
    return this.crudSet;
  }

  public hasCrud(crud: CrudEnum): boolean {
    return Object.values(this.crudSet).indexOf(crud) > -1;
  }
}
