import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CarrierUtils} from '../../../../../core/utils/carrier.utils';
import {ProductCodeEnum} from '../../../../../core/utils/data/product-code-enum';
import {RegexUtils} from '../../../../../core/utils/regex-utils';
import {ConditionalValidator} from '../../../../../shared/components/form/validators/conditional.validator';
import {FormUtils} from '../../../../../core/utils/form/form-utils';
import {FeatureFlagService} from '../../../../../core/services/feature-flag.service';

export class NSMEticketConfigurationForm {

  private productCode: ProductCodeEnum;
  private currentCarrier = '';
  private isDisabled = true;
  private prevForm: {};
  private isImplemented = false;
  private settingsNotInitialized: string[] = [];

  private fb: FormBuilder = new FormBuilder();
  private formGroup: FormGroup = new FormGroup({});

  constructor(private featureFlagService: FeatureFlagService) { }

  public setCurrentCarrier(carrierCode: string) {
    this.currentCarrier = carrierCode;
  }

  public setProductCode(productCode: ProductCodeEnum) {
    this.productCode = productCode;
  }

  public setCurrentState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  public setPreviousForm(form: {}) {
    this.prevForm = form;
  }

  public isFormImplemented() {
    return this.isImplemented;
  }

  public setFormImplemented(isImplemented: boolean) {
    this.isImplemented = isImplemented;
  }

  public getSettingsNotInitialized() {
    return this.settingsNotInitialized;
  }

  public setSettingsNotInitialized(settingsNotInitialized: string[]) {
    this.settingsNotInitialized = settingsNotInitialized;
  }

  buildForm() {
    this.formGroup = this.fb.group({
      eticketConfigs: this.fb.group({
        eticketRevokeActive: [false],
        eTicketActions: ['', [
          ConditionalValidator(() => this.eticketRevokeActive.value === true, Validators.required)
        ]],
        specificTicketNumbers: ['', [
          ConditionalValidator(() =>
            this.eticketRevokeActive.value === true,
            Validators.compose([Validators.required, Validators.pattern(RegexUtils.VALIDATOR_AIRLINE_ACCOUNTING_CODE_MULTI)]))
        ]],
        workableTypes: [[], [
          ConditionalValidator(() => this.eticketRevokeActive.value === true, Validators.required)
        ]],
        processGroups: [false],
        groupAction: [''],
        groupsQueue: ['', [
          ConditionalValidator(() =>
            this.eticketRevokeActive.value === true && this.processGroups.value === true && 'Queue' === this.groupAction.value,
            Validators.compose([Validators.required, Validators.pattern(RegexUtils.VALIDATOR_QUEUE)]))
        ]],
        codeshareOption: ['', [
          ConditionalValidator(() => this.eticketRevokeActive.value === true, Validators.required)
        ]],
        tieBreakerSetting: ['', [
          ConditionalValidator(() => this.eticketRevokeActive.value === true, Validators.required)
        ]],
        tieBreakerQueue: ['', [
          ConditionalValidator(() =>
            this.eticketRevokeActive.value === true && 'Queue' === this.tieBreakerSetting.value,
            Validators.compose([Validators.required, Validators.pattern(RegexUtils.VALIDATOR_QUEUE)]))
        ]],
        allowOverrides: [false],
        overrideUsers: ['', [
          ConditionalValidator(() =>
            this.eticketRevokeActive.value === true && this.allowOverrides.value === true, Validators.required)
        ]],
        validOverrideQueue: ['', [
          ConditionalValidator(() =>
            this.eticketRevokeActive.value === true && this.allowOverrides.value === true, Validators.pattern(RegexUtils.VALIDATOR_QUEUE))
        ]],
        invalidOverrideQueue: ['', [
          ConditionalValidator(() =>
            this.eticketRevokeActive.value === true && this.allowOverrides.value === true, Validators.pattern(RegexUtils.VALIDATOR_QUEUE))
        ]],
        eTicketErrorQueue: ['', [
          ConditionalValidator(() => this.eticketRevokeActive.value === true, Validators.pattern(RegexUtils.VALIDATOR_QUEUE))
        ]]
      })
    });

    if (CarrierUtils.canAccessSetting(this.currentCarrier, 'tieBreakerSettingLevel') && this.eticketConfigs) {
      this.eticketConfigs.addControl('tieBreakerSettingLevel', this.fb.control(false));
    }

    if (this.isDisabled) {
      this.formGroup.disable();
    } else if (this.eticketConfigs) {
      this.eticketRevokeActive.valueChanges.subscribe(value => {
        if (value === false) {
          this.eTicketActions.reset('');
          this.specificTicketNumbers.reset('');
          this.workableTypes.reset([]);
          this.codeshareOption.reset('');
          this.tieBreakerSetting.reset('');
          this.tieBreakerQueue.reset('');
          this.allowOverrides.reset(false);
          this.overrideUsers.reset('');
          this.validOverrideQueue.reset('');
          this.invalidOverrideQueue.reset('');
          this.eTicketErrorQueue.reset('');

          if (this.processGroups) { this.processGroups.reset(false); }
          if (this.groupAction) { this.groupAction.reset(''); }
          if (this.groupsQueue) { this.groupsQueue.reset(''); }

          Object.keys(this.eticketConfigs.controls).forEach(key => {
            if (key !== 'eticketRevokeActive' && this.eticketConfigs.get(key) && this.eticketConfigs.get(key).enabled) {
              this.eticketConfigs.get(key).disable();
            }
          });
        } else {
          Object.keys(this.eticketConfigs.controls).forEach(key => {
            if (key !== 'eticketRevokeActive' && this.eticketConfigs.get(key) && this.eticketConfigs.get(key).disabled) {
              this.eticketConfigs.get(key).enable();
              this.eticketConfigs.get(key).updateValueAndValidity();
            }
          });
        }
      });

      this.tieBreakerSetting.valueChanges.subscribe(value => {
        if ('Queue' !== value) {
          this.tieBreakerQueue.reset('');
          this.tieBreakerQueue.disable();
        } else {
          this.tieBreakerQueue.enable();
          this.tieBreakerQueue.updateValueAndValidity();
        }
      });

      this.processGroups.valueChanges.subscribe(value => {
        if (value === false) {
          this.groupAction.reset('');
          this.groupAction.disable();
        } else {
          this.groupAction.enable();
          if ('Queue' === this.groupAction.value) {
            this.groupsQueue.enable();
          }
        }
      });

      this.groupAction.valueChanges.subscribe(value => {
        if ('Queue' !== value) {
          this.groupsQueue.reset('');
          this.groupsQueue.disable();
        } else {
          this.groupsQueue.enable();
        }
      });

      this.allowOverrides.valueChanges.subscribe(value => {
        if (value === false) {
          this.overrideUsers.reset('');
          this.overrideUsers.disable();

          this.validOverrideQueue.reset('');
          this.validOverrideQueue.disable();

          this.invalidOverrideQueue.reset('');
          this.invalidOverrideQueue.disable();
        } else {
          this.overrideUsers.enable();
          this.overrideUsers.enable();
          this.overrideUsers.enable();
          this.validOverrideQueue.enable();
          this.invalidOverrideQueue.enable();

          this.overrideUsers.updateValueAndValidity();
          this.validOverrideQueue.updateValueAndValidity();
          this.invalidOverrideQueue.updateValueAndValidity();
        }
      });

      this.specificTicketNumbers.valueChanges.subscribe(value => FormUtils.formControlUpperCase(this.specificTicketNumbers, value));
      this.tieBreakerQueue.valueChanges.subscribe(value => FormUtils.formControlUpperCase(this.tieBreakerQueue, value));
      this.eTicketErrorQueue.valueChanges.subscribe(value => FormUtils.formControlUpperCase(this.eTicketErrorQueue, value));
      this.validOverrideQueue.valueChanges.subscribe(value => FormUtils.formControlUpperCase(this.validOverrideQueue, value));
      this.invalidOverrideQueue.valueChanges.subscribe(value => FormUtils.formControlUpperCase(this.invalidOverrideQueue, value));
      this.specificTicketNumbers.valueChanges.subscribe(value => FormUtils.formControlUpperCase(this.specificTicketNumbers, value));
      this.groupsQueue.valueChanges.subscribe(value => FormUtils.formControlUpperCase(this.groupsQueue, value));
    }

    FormUtils.validateAllFormFields(this.formGroup);

    return this.formGroup;
  }

  get form() {
    return this.formGroup;
  }

  get eticketConfigs(): FormGroup {
    return this.formGroup.get('eticketConfigs') as FormGroup;
  }

  get eticketRevokeActive(): FormControl {
    return this.eticketConfigs.get('eticketRevokeActive') as FormControl;
  }

  get eTicketActions(): FormControl {
    return this.eticketConfigs.get('eTicketActions') as FormControl;
  }

  get specificTicketNumbers(): FormControl {
    return this.eticketConfigs.get('specificTicketNumbers') as FormControl;
  }

  get workableTypes(): FormControl {
    return this.eticketConfigs.get('workableTypes') as FormControl;
  }

  get processGroups(): FormControl {
    return this.eticketConfigs.get('processGroups') as FormControl;
  }

  get groupAction(): FormControl {
    return this.eticketConfigs.get('groupAction') as FormControl;
  }

  get groupsQueue(): FormControl {
    return this.eticketConfigs.get('groupsQueue') as FormControl;
  }

  get codeshareOption(): FormControl {
    return this.eticketConfigs.get('codeshareOption') as FormControl;
  }

  get tieBreakerSetting(): FormControl {
    return this.eticketConfigs.get('tieBreakerSetting') as FormControl;
  }

  get tieBreakerQueue(): FormControl {
    return this.eticketConfigs.get('tieBreakerQueue') as FormControl;
  }

  get allowOverrides(): FormControl {
    return this.eticketConfigs.get('allowOverrides') as FormControl;
  }

  get overrideUsers(): FormControl {
    return this.eticketConfigs.get('overrideUsers') as FormControl;
  }

  get validOverrideQueue(): FormControl {
    return this.eticketConfigs.get('validOverrideQueue') as FormControl;
  }

  get invalidOverrideQueue(): FormControl {
    return this.eticketConfigs.get('invalidOverrideQueue') as FormControl;
  }

  get eTicketErrorQueue(): FormControl {
    return this.eticketConfigs.get('eTicketErrorQueue') as FormControl;
  }
}
