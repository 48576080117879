import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppConfigService} from '../../app-config.service';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {FormUtils} from '../utils/form/form-utils';
import {LogUtils} from '../utils/logging/log-utils';
import {FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {ModalUI} from '../models/modal.ui';
import {ConfirmationDialogService} from '../../shared/components/modal/service/confirm-modal.service';
import {ApiResponseObject} from '../../shared/model/api-response-object';
import {ProductCodeEnum} from '../utils/data/product-code-enum';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {FormConfigurationPipe} from '../pipes/form-configuration.pipe';


@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  constructor(private router: Router,
              private environment: AppConfigService,
              private httpClient: HttpClient,
              private authService: AuthService,
              private confirmationDialogService: ConfirmationDialogService) {
  }

  get baseEndPoint() {
    return this.environment.config.apiBaseEndpoint + '/' +
           this.authService.getActiveCarrierCode() + '/sets/' as String;
  }

  getConfigurations(setUI: string): Observable<ApiResponseObject> {
    return this.httpClient.get<ApiResponseObject>(
      this.baseEndPoint + setUI + '/configurations');
  }

  putConfigurations(setUI: string, configurations: {}): Observable<ApiResponseObject> {
    return this.httpClient.put<ApiResponseObject>(
      this.baseEndPoint + setUI + '/configurations', configurations);
  }

  getAllConfigurationsHistory(setUI: string): Observable<ApiResponseObject> {
    return this.httpClient.get<ApiResponseObject>(
      this.baseEndPoint + setUI + '/configurations/history');
  }

  getConfigurationsHistory(setUI: string, version: number): Observable<ApiResponseObject> {
    return this.httpClient.get<ApiResponseObject>(
      this.baseEndPoint + setUI + '/configurations/history/' + version);
  }

  postConfigurationsVersion(setUI: string, version: number): Observable<ApiResponseObject> {
    return this.httpClient.post<ApiResponseObject>(
      this.baseEndPoint + setUI + '/configurations/history/' + version, {});
  }

  getCountVersion(setUI: string): Observable<ApiResponseObject> {
    return this.httpClient.get<ApiResponseObject>(
      this.baseEndPoint + setUI + '/configurations/history/count');
  }

  submitConfiguration(configurationTitle: string,
                      setUI: string,
                      prevForm: string,
                      form: FormGroup,
                      redirect: string,
                      modal?: ModalUI,
                      productCode?: ProductCodeEnum) {
    if (!FormUtils.isInvalid(form)) {
      if (FormUtils.compareForm(prevForm, form)) {
        if (modal) {
          this.confirmationDialogService
            .confirm(modal.componentType, modal.displayMessage)
            .then((confirmed) => {
              if (confirmed) {
                this.putConfigurations(setUI, form.getRawValue())
                  .subscribe(response => {
                    LogUtils.info([configurationTitle, response]);
                    this.redirectTo(redirect);
                  },
                  err => {
                    this.redirectTo(redirect);
                  });
              }
              else
              {
                const prevfrequentflyer = JSON.parse(prevForm);
                if(prevfrequentflyer && prevfrequentflyer?.dupePnrConfig && prevfrequentflyer?.dupePnrConfig.dupeDetermineConfig?.frequentFlyerElements  && form?.getRawValue()?.dupePnrConfig && form.getRawValue()?.dupePnrConfig?.dupeDetermineConfig?.frequentFlyerElements?.length > 1)
                {
                 form.patchValue({dupePnrConfig :{dupeDetermineConfig:{frequentFlyerElements:prevfrequentflyer?.dupePnrConfig.dupeDetermineConfig?.frequentFlyerElements}}});
                }
              }
            }).catch();
        } else {
          this.putConfigurations(setUI, form.getRawValue())
            .subscribe(response => {
              LogUtils.info([configurationTitle, response.returnObject]);
              this.redirectTo(redirect);
            });
        }
      } else {
        this.redirectTo(redirect);
      }
    } else {
      const errors = FormUtils.gatherValidationErrors(form);
      if (isNotNullOrUndefined(errors)) {
        this.confirmationDialogService.confirm('ERRORS FOUND ON:',
          errors.map(key => new FormConfigurationPipe().transform(key, productCode)).toString()
          , true
          , true);
      }
    }
  }

  public redirectTo(path: string): void {
    this.router.navigate([ path ]);
  }
}
