import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './core/services/auth-guard.service';
import { AuthUtils } from './core/utils/auth.utils';
import { NotFoundComponent } from './shared/components/not-found.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    canLoad: [AuthGuardService],
    canActivate: [AuthGuardService]
  },
  {
    path: 'products',
    loadChildren: () => import('./modules/product/product-list/product-list.module').then(m => m.ProductListModule),
    canLoad: [AuthGuardService],
    canActivate: [AuthGuardService],
    data: {
      roles: AuthUtils.ALL_PRODUCT_ROLES,
      types: AuthUtils.PRODUCTS_PERMISSIONS_TYPES
    }
  },
  {
    path: 'report',
    loadChildren: () => import('./modules/report/report.module').then(m => m.ReportModule),
    canLoad: [AuthGuardService],
    canActivate: [AuthGuardService],
    data: {
      roles: [AuthUtils.REPORT]
    }
  },
  {
    path: 'search',
    loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule),
    canLoad: [AuthGuardService],
    canActivate: [AuthGuardService],
    data: {
      roles: [AuthUtils.SEARCH]
    }
  },
  {
    path: 'general-settings',
    loadChildren: () => import('./modules/general-setting/general-setting.module').then(m => m.GeneralSettingModule),
    canLoad: [AuthGuardService],
    canActivate: [AuthGuardService],
    data: {
      roles: [AuthUtils.COMMON],
      types: AuthUtils.SHARED_PERMISSIONS_TYPES
    }
  },
  {
    path: 'automation-services',
    loadChildren: () => import('./modules/automation-services/product-list/automation-services-list.module').then(m => m.AutomationServicesListModule),
    canLoad: [AuthGuardService],
    canActivate: [AuthGuardService],
    data: {
      roles: AuthUtils.ALL_AUTOMATION_SERVICES_ROLES,
      types: AuthUtils.ALL_AUTOMATION_SERVICES_TYPES
    }
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

