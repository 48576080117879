import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {RegexUtils} from './regex-utils';

export class CarrierUtils {

  public static readonly CARRIERS = {
    '1A': '1A',
    '8X': '8X',
    AC: 'AC',
    J1: 'J1',
    J2: 'J2',
    J3: 'J3',
    JL: 'JL',
    KE: 'KE',
    SQ: 'SQ',
    EY: 'EY',
    SV: 'SV',
    VT: 'VT',
    WN: 'WN',
    AY: 'AY',
    '6X': '6X'
  };
  
  public static readonly TEST_CARRIERS = [
    CarrierUtils.CARRIERS['1A'],
    CarrierUtils.CARRIERS['8X'],
    CarrierUtils.CARRIERS['6X']
  ];

  public static readonly JL_CARRIERS = [
    CarrierUtils.CARRIERS['J1'],
    CarrierUtils.CARRIERS['J2'],
    CarrierUtils.CARRIERS['J3'],
    CarrierUtils.CARRIERS['JL']
  ];

  public static readonly WN_CARRIERS = [
    CarrierUtils.CARRIERS['WN']
  ];

  private static readonly PNR_IS_INTERNATIONAL = 'PnrIsInternational';
  private static readonly PNR_EMD_IS_ONE_OF = 'depositEMD';

  public static canAccessRuleSet(carrierCode: string, setUI: string): boolean {

    let carriers: string[];

    switch (setUI) {
      case 'nsm-eticket-refund':
      case 'nsm-eticket-refund-exclusion':
        carriers = CarrierUtils.TEST_CARRIERS.concat(CarrierUtils.CARRIERS['WN']);
        break;
    }

    return !isNotNullOrUndefined(carriers) || carriers.indexOf(carrierCode) > -1;
  }

  public static filterRuleSetCriteria(carrierCode: string, ruleset: string, criteria: string[], flagEnvironment: String): string[] {
    for (let i = 0; i < criteria.length; i++) {
      if (RegexUtils.FLAG_SEGMENT_IS_ACKNOWLEDGED.test(criteria[i])) {
        if (CarrierUtils.TEST_CARRIERS.concat(CarrierUtils.JL_CARRIERS).indexOf(carrierCode) < 0) {
          criteria.splice(i, 1);
          i--;
        }
      } else if (RegexUtils.FLAG_FARE_FAMILY_AND_FARE_CALCULATION.test(criteria[i])) {
        if (CarrierUtils.TEST_CARRIERS.concat(CarrierUtils.WN_CARRIERS).indexOf(carrierCode) < 0) {
          criteria.splice(i, 1);
          i--;
        }
      } else if (CarrierUtils.PNR_IS_INTERNATIONAL === criteria[i]) {
        if ((ruleset.startsWith('atl') || ruleset.startsWith('gtl')) &&
         CarrierUtils.TEST_CARRIERS.concat(CarrierUtils.JL_CARRIERS).indexOf(carrierCode) < 0) {
          criteria.splice(i, 1);
          i--;
        }
      }
      else if (CarrierUtils.PNR_EMD_IS_ONE_OF === criteria[i]) {
        if (ruleset.startsWith('gtl')) {
          console.log("removing");
          criteria.splice(i, 1);
          i--;
        }
      } 
    }
    return criteria;
  }

  public static canAccessSetting(carrierCode: string, settingKey: string): boolean {
    let allowedSettings: string[];

    switch (carrierCode) {
      case CarrierUtils.CARRIERS['1A']:
      case CarrierUtils.CARRIERS['8X']:
        allowedSettings = [
          'dynamicWaiverSettings',
          'tieBreakerSettingLevel',
          'alternateQueues',
          'agreementQueue',
          'ttlAdjustmentOrderDefinition',
          'queueToBoardPointOid',
          'agmNotificationFlow'
        ];
        break;
      case CarrierUtils.CARRIERS['J1']:
      case CarrierUtils.CARRIERS['J2']:
      case CarrierUtils.CARRIERS['J3']:
      case CarrierUtils.CARRIERS['JL']:
        allowedSettings = ['ttlAdjustmentOrderDefinition','tieBreakerSettingLevel'];
        break;
      case CarrierUtils.CARRIERS['WN']:
        allowedSettings = ['dynamicWaiverSettings', 'tieBreakerSettingLevel'];
        break;
      case CarrierUtils.CARRIERS['AY']:
      case CarrierUtils.CARRIERS['6X']:
        allowedSettings = ['randomDecisionPriority','tieBreakerSettingLevel'];
        break;
      case CarrierUtils.CARRIERS['SV']:
        allowedSettings = ['tieBreakerSettingLevel','tieBreakerSettingLevel'];
        break;
      case CarrierUtils.CARRIERS['AC']:
        allowedSettings = ['alternateQueues', 'agreementQueue','tieBreakerSettingLevel'];
        break;
      case CarrierUtils.CARRIERS['KE']:
        allowedSettings = ['queueToBoardPointOid','tieBreakerSettingLevel'];
        break;
      case CarrierUtils.CARRIERS['SQ']:
      case CarrierUtils.CARRIERS['VT']:
        allowedSettings = ['alternateQueues','tieBreakerSettingLevel'];
        break;
      default:
        allowedSettings = ['tieBreakerSettingLevel'];
    }

    return allowedSettings.indexOf(settingKey) > -1;
  }

  static overrideSetting(carrierCode: string, settingKey: string): any {
    switch (settingKey) {
      case 'maxTL':
        return CarrierUtils.TEST_CARRIERS.concat(CarrierUtils.JL_CARRIERS).indexOf(carrierCode) > -1 ? -23 : 0;
      case 'minTL':
        return CarrierUtils.TEST_CARRIERS.concat(CarrierUtils.JL_CARRIERS).indexOf(carrierCode) > -1 ? 0 : 1;
    }
  }

  static canAccessSettingOption(carrierCode: string, settingOption: string) {
    let carriers: string[];

    switch (settingOption) {
      case 'After acknowledgement':
        carriers = CarrierUtils.TEST_CARRIERS.concat(CarrierUtils.JL_CARRIERS);
        break;
    }

    return !isNotNullOrUndefined(carriers) || carriers.indexOf(carrierCode) > -1;
  }
}
