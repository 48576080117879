export class DataUtils {

  static readonly JAVASCRIPT_DECIMAL_RADIX_CODE = 10;

  static isObjectEmpty(object: any): boolean {
    let objectIsEmpty: boolean;
    if (object === null || object === undefined) {
      objectIsEmpty = true;
    } else {
      const objectHasNoProperties = Object.keys(object).length === 0;
      const objectHasAConstructor = object.constructor === Object;
      objectIsEmpty = objectHasNoProperties && objectHasAConstructor;
    }
    return objectIsEmpty;
  }

  static IsValidJSONString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  static convertStringToNumber(string: string): number {
    return parseInt(string, DataUtils.JAVASCRIPT_DECIMAL_RADIX_CODE);
  }

  static removeSpaceFromString(string: string): string {
    return string.replace(/\s/g, '');
  }

  static removeElementFromArrayByIndex(array: any[], elementIndex: number): void {
    array.splice(elementIndex, 1);
  }

  static insertElementInArrayByIndex(array: any[], index: number, element: any): void {
    array.splice(index, 0, element);
  }

  static moveElementInArray(array: any[], currentIndex: number, targetIndex: number, element: any) {
    DataUtils.removeElementFromArrayByIndex(array, currentIndex);
    DataUtils.insertElementInArrayByIndex(array, targetIndex, element);
  }

  static ucFirst(text: string = ''): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
}
