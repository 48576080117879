import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {LogUtils} from './core/utils/logging/log-utils';
import {IAppConfig} from './core/models/app-config.model';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private appConfig: IAppConfig;

  constructor (private injector: Injector) { }

  loadAppConfig() {
    const http = this.injector.get(HttpClient);

    return http.get('/assets/app-config.json')
      .toPromise()
      .then(data => {
        this.appConfig = data as IAppConfig;
        return data;
      })
      .catch(error => {
        console.error('Error loading app-config.json, contact your administrator');
        LogUtils.throw(error);
      });
  }

  get config() {
    return this.appConfig;
  }
  environmentCheck(): String{
    if(this.config.environment.toLowerCase() == "dev" || this.config.environment.toLowerCase() == "uat"){
      return "dev" || "uat";
    }
    return "prd";
  }
}
