import {ProductUI} from './../../../core/models/product.ui';
import {SetUI} from './../../../core/models/set.ui';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SetService} from '../../../core/services/set.service';
import {Set} from '../../../core/models/set';
import { BusinessDataService } from '../../../modules/product/list-manager/services/business-data.service';

@Component({
  selector: 'app-card-rule',
  template: `
    <div class="card-box padding_30 custom-shadow widget-flat no-border cursor-pointer"
         (click)="onClick()">
      <i class="{{ this.set.icon }} text-primary"></i>
      <h3 class="m-b-10 color-blue-light text-center">{{ count }}</h3>
      <p class="text-uppercase m-b-5 font-13 font-600 color-grey text-center">{{ this.set.title }}</p>
    </div>
  `,
  styles: []
})
export class CardRuleComponent implements OnInit {

  @Input() public product: ProductUI;
  @Input() public set: SetUI;
  @Output() public selectSet = new EventEmitter<SetUI>();

  public count = 0;

  constructor(private setService: SetService,
              private businessDataService: BusinessDataService) {
  }

  ngOnInit() {
    switch (this.set.setUI) {
      case 'business-data' :
              this.getBusinessDataCount();
              break;
      case 'invalid-ticket' :
              this.getInvalidTicketCount();
              break;
      default:
              this.getSets();
              break;
    }
  }

  public onClick(): void {
    this.selectSet.emit(this.set);
  }

  private getBusinessDataCount() {
    this.businessDataService.getCountBusinessDataList()
      .subscribe(response => this.count = response.returnObject);
  }

  private getInvalidTicketCount() {
    this.businessDataService.getBusinessDataListOfConfiguration(this.set.setUI, 'listOfInvalidTickets')
                            .subscribe(response => {
                              if (response.returnObject.value) {
                                const data: string[] = JSON.parse(response.returnObject.value);
                                this.count = data.length;
                              }
                            });
  }

  private getSets(): void {
    this.setService.getSets(this.set.setUI).subscribe(response => {
      this.setRuleCount(response.returnObject);
    });
  }

  private setRuleCount(sets: Set[]): void {
    let count = 0;
    sets.forEach(s => count += s.count);
    this.count = count;
  }

}
