import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {AuthUtils} from '../utils/auth.utils';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanLoad, CanActivate, CanActivateChild {

  constructor(private authService: AuthService) {}

  canLoad(route: Route): boolean {
    if (this.isAuthenticated()) {
      if (route.hasOwnProperty('data') && Object.keys(route.data).length > 0) {
        // Default required TYPES are ALL
        const roles = route.data.hasOwnProperty('roles') ? route.data.roles : [];
        const types = route.data.hasOwnProperty('types') ? route.data.types : AuthUtils.ALL_PERMISSIONS_TYPES;

        if (roles.length > 0) {
          return this.authService.canLoad(roles, types);
        }
      }

      return true;
    }

    return false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.isAuthenticated()) {
      if (route.hasOwnProperty('data')) {
        // Default required TYPES are ALL
        // Default required CRUD set is ALL
        const roles = route.data.hasOwnProperty('roles') ? route.data.roles : [];
        const types = route.data.hasOwnProperty('types') ? route.data.types : AuthUtils.ALL_PERMISSIONS_TYPES;
        const crudSet = route.data.hasOwnProperty('crudSet') ? route.data.crudSet : AuthUtils.ALL;

        if (roles.length > 0) {
          return this.authService.canActivate(roles, types, crudSet);
        }
      }

      return true;
    }

    return false;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }

  private isAuthenticated(): boolean {
    const url = window.location.pathname;
    this.authService.setRedirectUrl(url);

    if (this.authService.isUserLoggedIn()) {
      return true;
    }

    return this.authService.authenticateUser();
  }
}
