import { Injectable } from '@angular/core';

import {RegexUtils} from '../utils/regex-utils';
import {DataStorageService} from '../services/data-storage.service';

@Injectable()
export class JwtOptionsFactory {
  private dataStorage: DataStorageService;

  public readonly whitelistedDomains: string[] | RegExp[] = [RegexUtils.WHITELISTED_DOMAINS];
  public readonly blacklistedRoutes:  string[] | RegExp[] = ['/assets/app-config.json'];

  constructor(dataStorage: DataStorageService) {
    this.dataStorage = dataStorage;
  }

  public tokenGetter = () => this.dataStorage.getToken();
}
