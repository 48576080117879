import {
  ComponentFactoryResolver, Directive, Input, OnInit, ViewContainerRef, Output, EventEmitter, ComponentRef
} from '@angular/core';

import {Field} from '../models/field';
import {FlowComponent} from '../components/flow.component';
import {LocationComponent} from '../components/location.component';
import {DateRangeComponent} from '../components/daterange.component';
import {DateComponent} from '../components/date.component';
import {InputComponent} from '../components/input.component';
import {SelectComponent} from '../components/select.component';
import {TextareaComponent} from '../components/textarea.component';
import {FormGroup} from '@angular/forms';
import {PtdRangeComponent} from '../components/ptd-range.component';
import {PaxNameMatchingComponent} from '../components/pax-name-matching.component';
import {PaxNamePercentageComponent} from '../components/paxNamePercentage.component';

const componentMapper = {
  input: InputComponent,
  select: SelectComponent,
  date: DateComponent,
  daterange: DateRangeComponent,
  paxNameMatching: PaxNameMatchingComponent,
  textarea: TextareaComponent,
  location: LocationComponent,
  ptdRange: PtdRangeComponent,
  flow: FlowComponent,
  paxNamePercentage: PaxNamePercentageComponent
};

@Directive({
  selector: '[appDynamicField]'
})
export class DynamicFieldDirective implements OnInit {

  @Input() field: Field;
  @Input() group: FormGroup;
  @Input() state: string;
  @Input() showFullSize: boolean;
  @Output() invalid = new EventEmitter<any>();

  componentRef: ComponentRef<any>;

  constructor(private resolver: ComponentFactoryResolver, private container: ViewContainerRef) { }

  ngOnInit(): void {
    const factory = this.resolver.resolveComponentFactory(
      componentMapper[this.field.type]
    );
    this.componentRef = this.container.createComponent(factory);
    this.componentRef.instance.field = this.field;
    this.componentRef.instance.group = this.group;
    this.componentRef.instance.state = this.state;
    this.componentRef.instance.showFullSize = this.showFullSize;
    if (this.componentRef.instance.invalid) {
      this.componentRef.instance.invalid.subscribe((message) => {
        this.invalid.emit(message);
      });
    }
  }



}
