import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {AuthUtils} from '../utils/auth.utils';

@Injectable()
export class MockPermissionsInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const currentUser = this.authService.getAuthenticatedUser();
    if (currentUser && currentUser.getOrganization() === AuthUtils.AMADEUS_CARRIER_CODE && this.authService.hasMockUserPermissions()) {
      const mockedPermissions = this.authService.extractPermissions(this.authService.extractMockedPermissions());

      if (Object.keys(mockedPermissions).length > 0) {
        request = request.clone({
          setHeaders: {
            MockPermissions: mockedPermissions,
            MockCarrier: this.authService.getActiveCarrierCode()
          }
        });
      }
    }

    return next.handle(request);
  }
}
