export enum ProductNameEnum {
  ATL = 'Amadeus Time Limits',
  DPP = 'Duplicate PNR',
  DPS = 'Duplicate Segments',
  FSN = 'Fictitious Names',
  GTL = 'Group Time Limits',
  HDG = 'Hidden Groups',
  NSM = 'No-Show Manager',
  TTT = 'Time to Think',
  WLT = 'Unproductive Segment Manager',
  CHR = 'Booking Churn Manager'
}
