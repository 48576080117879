import {Pipe, PipeTransform} from '@angular/core';
import {FORM_CONFIGURATION} from '../configuration/form.configuration';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {ProductCodeEnum} from '../utils/data/product-code-enum';

/**
 * Get configuration of a given key from the JSON schema
 * schema if it exists. Return the key otherwise
 *
 * Usage:
 *   value | formConfiguration:productCode[:override key]
 * Examples:
 *   {{ 'fixedTimeOfDay' | formConfiguration:'ATL' }}
 *   returns: Time of Day for Expiration
 *   {{ 'fixedTimeOfDay' | formConfiguration:'ATL':'timeLimitTimezone' }}
 *   returns: Time Zone Option
*/
@Pipe({
  name: 'formConfiguration'
})
export class FormConfigurationPipe implements PipeTransform {
  private static COMMON = 'COMMON';

  transform(value: string, productCode?: ProductCodeEnum, overrideKey?: string): any {
    const key = isNotNullOrUndefined(overrideKey) ? overrideKey : value;
    const productKey = isNotNullOrUndefined(productCode) ? productCode : FormConfigurationPipe.COMMON;

    if (/[.]/.test(key) && isNotNullOrUndefined(FORM_CONFIGURATION[productKey])) {
      return this.recursiveTransform(key, FORM_CONFIGURATION[productKey], productKey);
    } else if (isNotNullOrUndefined(FORM_CONFIGURATION[productKey]) && isNotNullOrUndefined(FORM_CONFIGURATION[productKey][key])) {
      return FORM_CONFIGURATION[productKey][key];
    } else {
      return key;
    }

  }

  recursiveTransform(key: string, value: any, productCode: any): any {
    if (/[.]/.test(key)) {
      const keys = key.split('.');
      const firstKey = keys.shift();
      const lastKey = key.split('.').pop();

      if (isNotNullOrUndefined(value[firstKey])) {
        return this.recursiveTransform(keys.join('.'), value[firstKey], productCode);
      } else if (isNotNullOrUndefined(FORM_CONFIGURATION[productCode][lastKey])) {
        return FORM_CONFIGURATION[productCode][lastKey];
      } else {
        return isNotNullOrUndefined(FORM_CONFIGURATION[productCode][key]) ? FORM_CONFIGURATION[productCode][key] : key;
      }
    } else if (isNotNullOrUndefined(value[key])) {
      return value[key];
    } else {
      return isNotNullOrUndefined(FORM_CONFIGURATION[productCode][key]) ? FORM_CONFIGURATION[productCode][key] : key;
    }
  }
}
