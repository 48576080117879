
import { Injectable } from '@angular/core';
import { BusinessType } from '../models/business-type';
import businessTypes from '../configuration/business-type.json';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusinessTypeService {
  disable = false;
  constructor() {}

  getAllBusinessType(): Observable<BusinessType[]> {
    const businesstypes = Object.assign([], (<BusinessType[]> <any>businessTypes));
    return of(businesstypes);
  }

  getFirstBusinessType(): Observable<BusinessType> {
    const businesstypes = Object.assign([], (<BusinessType[]> <any>businessTypes));
    return of(businesstypes[0]);
  }

  getBusinessType(name: string): Observable<BusinessType> {
    const businessData = (<BusinessType[]> <any>businessTypes).filter(f => f.name === name)[0];
    return of(Object.assign([{}], businessData));
  }

  setDisable(disable) {
    this.disable = disable;
  }

  getDisable(): boolean {
    return this.disable ;
  }
}
